import React, { Component, useState } from "react";
import TabHeader from "../components/TabHeader";
class ObjectPanel extends Component {
  constructor(props) {
    super(props);

    // setup init state
    this.state = this.getInitialState();

    this.togglePanel = this.togglePanel.bind(this);
    this.limitObjList = this.limitObjList.bind(this);
  }

  // Settings
  getInitialState = () => ({
    isSpatialMapActive: false,
    panelActive: true,
    liteMode: false,
    tabState: 1,
    capacity: 0,
    assets: [],
    entities: [],
    searchedEntites: [],
    selectedEntity: {},
    spatial: "Select a Spatial Map",
    scenarioName: "",
    scenarioDesc: "",
    selected: "",
    objectSearch: ""
  });

  // Callbacks
  updateParent(data) {
    this.props.dataCallBack(data);
  }

  handleTab(tabIndex) {
    console.log(this.state.selectedEntity);
    this.setState({ tabState: tabIndex });
  }

  togglePanel(e) {
    this.setState({ panelActive: !this.state.panelActive });
  }

  limitObjList(e) {
    let regex = new RegExp(e.target.value, 'gi')
    let results = [];

    this.state.entities.forEach((entity) => {
      let found = entity.name.match(regex);
      if (found != null && found.length > 0) {
        results.push(entity);
      }
    });

    this.setState({ searchedEntites: results });
  }

  selectedUpadate(e, field, finalOverride=false) {

    let objectChanged = false;
    let final = false;

    if (finalOverride === true) {
      final = true;
    }
    
    if (field === "name" && e.target.value.length > 0) {
      this.state.selectedEntity.name = e.target.value;
    } else if (field === "classification") {
      final = true;
      this.state.selectedEntity.classification = e.target.value;
    } else if (field === "defaultAnimation") {
      final = true;
      this.state.selectedEntity.defaultAnimation = e.target.value;
    } else if (field === "displayText") {
      this.state.selectedEntity.displayText = e.target.value;
    } else {
      let newValue = e.target.value;

      if (newValue[newValue.length - 1] === ".") {
        newValue = newValue+"1";
      }

      if (!isNaN(newValue)) {
        objectChanged = true;

        final = true;

        if (field === "posX") {
          this.state.selectedEntity.position.x = newValue;
        } else if (field === "posY") {
          this.state.selectedEntity.position.y = newValue;
        } else if (field === "posZ") {
          this.state.selectedEntity.position.z = newValue;
        } else if (field === "scaleX") {
          this.state.selectedEntity.scale.x = newValue;
        } else if (field === "scaleY") {
          this.state.selectedEntity.scale.y = newValue;
        } else if (field === "scaleZ") {
          this.state.selectedEntity.scale.z = newValue;
        } else if (field === "rotX") {
          this.state.selectedEntity.rotation._x = newValue;
        } else if (field === "rotY") {
          this.state.selectedEntity.rotation._y = newValue;
        } else if (field === "rotZ") {
          this.state.selectedEntity.rotation._z = newValue;
        }

      }


    }
    
    console.log(this.state.selectedEntity);
    this.props.updateEntity(this.state.selectedEntity, objectChanged, final);
  }

  async componentDidMount() {
  }

  componentWillUnmount() {
  }

  static getDerivedStateFromProps(props, state) {

    if (props.assets && props.assets !== state.assets) {
      return {
        assets: props.assets
      };
    }

    if (props.entities && props.entities !== state.entities) {
      return {
        entities: props.entities,
        searchedEntites: props.entities
      };
    }

    if (props.capacity && props.capacity !== state.capacity) {
      return {
        capacity: props.capacity
      };
    }

    if (props.selected != state.selected) {
      return { 
        selected: props.selected
      };
    }

    if (props.scenarioName && props.scenarioName !== state.scenarioName) {
      return {
        scenarioName: props.scenarioName
      };
    }

    if (props.selectedEntity && props.selectedEntity !== state.selectedEntity) {
      return {
        selectedEntity: props.selectedEntity
      };
    }

    if (props.scenarioDesc && props.scenarioDesc !== state.scenarioDesc) {
      return {
        scenarioDesc: props.scenarioDesc
      };
    }

    if (props.isSpatialMapActive !== state.isSpatialMapActive) {
      return {
        isSpatialMapActive: props.isSpatialMapActive
      };
    }

    if (props.spatial && props.spatial !== state.spatial) {
      return {
        spatial: props.spatial
      };
    }

    if (props.liteMode && props.liteMode !== state.liteMode) {
      return {
        liteMode: props.liteMode
      };
    }
    return {}
  }

  render() {

    let animList = [];
    if (Object.keys(this.state.selectedEntity).length > 0 && Object.keys(this.state.selectedEntity.animations).length > 0) {
      animList = this.state.selectedEntity.animations;
    } else {
      animList = [];
    }


    return (
      <div className={`scene-panel ${this.props.modalFilesToggle && "with-mini-menu"} ${this.state.panelActive === false ? "hiddenObjPanel" : ""}`} id={this.state.liteMode === true ? "tabs_lite" : "tabs"}>
        <div class="mini-menu">
          <ul>
            <li><a onClick={this.props.modalFilesToggle} title="Object Library"><img src="/images/builder_icon1.png" alt="Object Library" /></a></li>
            <li><a onClick={this.props.logicToggle} title="Logic Library"><img src="/images/builder_icon2.png" alt="Logic Library" /></a></li>
            {this.state.liteMode === false ? (<>
              {
                <li><a onClick={this.props.modalThemeToggle} title="Scenario Properties"><img src="/images/builder_icon3.png" alt="" /></a></li>
              }
            </>) : (<></>)}
          </ul>
          <div class="bottom-toggle"><a onClick={this.togglePanel}><img src={this.state.panelActive === true ? "/images/toggle-menu-in.png" : "/images/toggle-menu-out.png"} alt="Toggle Menu" /></a></div>
        </div>

        <div class="left_sidebar_border left_sidebar_builderobject">
          <div class="sidebar_panel_titel">
            <TabHeader text={`${Object.keys(this.state.selectedEntity).length === 0 ? 'SCENARIO' : 'OBJECT'} PROPERTIES`} />
          </div>

          <div class="sidebar_pwa08run_padding flex-grow scrollable-y" style={{ display: Object.keys(this.state.selectedEntity).length === 0 ? 'block' : 'none' }}>
            <div class="sidebar_pwa08run_form">
              <h2>Scenario Name</h2>
              <input class="sidebar_pwa08run_forminput" value={this.state.scenarioName} onChange={evt => this.props.updateScenarioName(evt)} disabled={this.state.liteMode === true ? true : null} />
              {this.state.scenarioName}
            </div>
            <div class="sidebar_pwa08run_form">
              <h2>spatial map</h2>
              <div class="select_pwa03builder">
                <a class="select_pwa03builderbutton" onClick={this.props.handleSpatialMapToggle}>{this.state.spatial}</a>
                <div className={`select_pwa03builderdropdown ${this.state.isSpatialMapActive ? "highdropdown" : ""}`}>
                  {this.state.assets.filter(assetCheck => assetCheck.fileType === 3).map((asset, i) => (
                    <p key={i} class="pointer" onClick={() => this.props.addMesh(asset)}>{asset.name}</p>
                  ))}
                </div>

              </div>
            </div>
            <div class="sidebar_pwa08run_form">
              <h2>Scenario Description</h2>
              <textarea class="pwa03_buildertextarea" value={this.state.scenarioDesc} onChange={e => this.props.updateScenarioDesc(e)} disabled={this.state.liteMode === true ? true : null} />
            </div>
          </div>

          <div class="sidebar_pwa08run_padding" style={{ display: Object.keys(this.state.selectedEntity).length > 0 ? 'block' : 'none' }}>
            <div class="sidebar_pwa08run_form">
              <h2>NAME</h2>
              <input class="sidebar_pwa08run_forminput" value={(Object.keys(this.state.selectedEntity).length > 0 ? this.state.selectedEntity.name : "")} onChange={e => this.selectedUpadate(e, "name")} onBlur={e => this.selectedUpadate(e, "name", true)} disabled={this.state.liteMode === true ? true : null} />
            </div>

          </div>
          <div id="tabs1" class="tabs_builderobject" style={{ display: Object.keys(this.state.selectedEntity).length > 0 ? 'block' : 'none' }}>
            <ul class="tabs_builderobjectul ui-tabs-nav ui-corner-all ui-helper-reset ui-helper-clearfix ui-widget-header">
              <li className={`ui-tabs-tab ui-corner-top ui-state-default ui-tab ${this.state.tabState === 1 ? "ui-tabs-active ui-state-active" : ""}`}><a onClick={() => this.handleTab(1)}><span>general</span></a></li>
              <li className={`ui-tabs-tab ui-corner-top ui-state-default ui-tab ${this.state.tabState === 2 ? "ui-tabs-active ui-state-active" : ""}`}><a onClick={() => this.handleTab(2)}><span>behavior</span></a></li>
              <li className={`ui-tabs-tab ui-corner-top ui-state-default ui-tab ${this.state.tabState === 3 ? "ui-tabs-active ui-state-active" : ""}`}><a onClick={() => this.handleTab(3)}><span>Triggers</span></a></li>
              <li className={`ui-tabs-tab ui-corner-top ui-state-default ui-tab ${this.state.tabState === 4 ? "ui-tabs-active ui-state-active" : ""}`}><a onClick={() => this.handleTab(4)}><span>appearance</span></a></li>
              <li className={`ui-tabs-tab ui-corner-top ui-state-default ui-tab ${this.state.tabState === 5 ? "ui-tabs-active ui-state-active" : ""}`}><a onClick={() => this.handleTab(5)}><span>Transform</span></a></li>
            </ul>
            </div>
          <div class="flex-grow scrollable-y scrollable-hidden-x" style={{ display: Object.keys(this.state.selectedEntity).length > 0 ? 'block' : 'none' }}>
            <div id="tabs-1" className={`ui-tabs-panel ui-corner-bottom ui-widget-content ${this.state.tabState === 1 ? "" : "tabs_container"}`}>
              <div class="tabs_builderobject_content">
                <div class="tabs_builderobject_content1 sidebar_pwa08run_form">
                  <h2>Classification</h2>
                  <select class="sidebar_pwa08run_forminput" name="Clasification" value={(Object.keys(this.state.selectedEntity).length > 0 ? this.state.selectedEntity.classification : "")} onChange={e => this.selectedUpadate(e, "classification")} disabled={this.state.liteMode === true ? true : null}>
                    <option value="undefined">Undefined</option>
                    <option value="friendly">Friendly</option>
                    <option value="enemy">Enemy</option>
                    <option value="bystander">Bystander</option>
                    <option value="prop">Prop</option>
                    <option value="neutral">Neutral</option>
                  </select>
                </div>
                {this.state.selectedEntity && this.state.selectedEntity.class && this.state.selectedEntity.class === "sign" ? (<>
                  <div class="tabs_builderobject_content1">
                    <h4>Sign</h4>
                    <textarea class="pwa03_buildertextarea" value={this.state.selectedEntity.displayText} onChange={e => this.selectedUpadate(e, "displayText")} onBlur={e => this.selectedUpadate(e, "displayText", true)} disabled={this.state.liteMode === true ? true : null} />
                  </div>
                </>) : (<></>)}
              </div>
            </div>
            <div id="tabs-2" className={`ui-tabs-panel ui-corner-bottom ui-widget-content ${this.state.tabState === 2 ? "" : "tabs_container"}`}>
              <div class="tabs_builderobject_content">
                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley</p>
              </div>
            </div>
            <div id="tabs-3" className={`ui-tabs-panel ui-corner-bottom ui-widget-content ${this.state.tabState === 3 ? "" : "tabs_container"}`}>
              <div class="tabs_builderobject_content"> <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley </p></div>
            </div>
            <div id="tabs-4" className={`ui-tabs-panel ui-corner-bottom ui-widget-content ${this.state.tabState === 4 ? "" : "tabs_container"}`}>
              <div class="tabs_builderobject_content sidebar_pwa08run_form">
                <h2>Default Animation</h2>
                <select class="sidebar_pwa08run_forminput" name="defaultAnimation" value={(Object.keys(this.state.selectedEntity).length > 0 ? this.state.selectedEntity.defaultAnimation : "")} onChange={e => this.selectedUpadate(e, "defaultAnimation")} disabled={animList.length === 0 || this.state.liteMode === true ? true : null}>
                  <option value="">SELECT</option>
                  {animList.map((anim, i) => (
                    <option key={i} value={anim.fileName}>{anim.fileName}</option>
                  ))}
                </select>


              </div>
            </div>
            <div id="tabs-5" className={`ui-tabs-panel ui-corner-bottom ui-widget-content ${this.state.tabState === 5 ? "" : "tabs_container"}`}>
              <div class="tabs_builderobject_content sidebar_pwa08run_form">
                <h2>Position</h2>
                <div class="row pb-3">
                  <div class="col">
                    <div>X:<input class="sidebar_pwa08run_forminput" onChange={e => this.selectedUpadate(e, "posX")} value={(Object.keys(this.state.selectedEntity).length > 0 && Object.keys(this.state.selectedEntity.position).length > 0 ? this.state.selectedEntity.position.x : "")} /></div>
                  </div>
                  <div class="col">
                    <div>Y:</div>
                    <div><input class="sidebar_pwa08run_forminput" onChange={e => this.selectedUpadate(e, "posY")} value={(Object.keys(this.state.selectedEntity).length > 0 && Object.keys(this.state.selectedEntity.position).length > 0 ? this.state.selectedEntity.position.y : "")} /></div>
                  </div>
                  <div class="col">                    
                    <div>Z:</div>
                    <div><input class="sidebar_pwa08run_forminput" onChange={e => this.selectedUpadate(e, "posZ")} value={(Object.keys(this.state.selectedEntity).length > 0 && Object.keys(this.state.selectedEntity.position).length > 0 ? this.state.selectedEntity.position.z : "")} /></div>
                  </div>
                </div>
                {this.state.selectedEntity.class !== "waypoint" ? (<>
                  <h2>Scale</h2>
                  <div class="row pb-3">
                    <div class="col">
                      <div>X:</div>
                      <div><input class="sidebar_pwa08run_forminput" onChange={e => this.selectedUpadate(e, "scaleX")} value={(Object.keys(this.state.selectedEntity).length > 0 && Object.keys(this.state.selectedEntity.scale).length > 0  ? this.state.selectedEntity.scale.x : "")} /></div>
                    </div>
                    <div class="col">
                      <div>Y:</div>
                      <div><input class="sidebar_pwa08run_forminput" onChange={e => this.selectedUpadate(e, "scaleY")} value={(Object.keys(this.state.selectedEntity).length > 0 && Object.keys(this.state.selectedEntity.scale).length > 0  ? this.state.selectedEntity.scale.y : "")} /></div>
                    </div>
                    <div class="col">
                      <div>Z:</div>
                      <div><input class="sidebar_pwa08run_forminput" onChange={e => this.selectedUpadate(e, "scaleZ")} value={(Object.keys(this.state.selectedEntity).length > 0 && Object.keys(this.state.selectedEntity.scale).length > 0  ? this.state.selectedEntity.scale.z : "")} /></div>
                    </div>
                  </div>
                </>) : (<></>)}
                <h2>Rotation</h2>
                <div class="row">
                  <div class="col">
                    <div>X:</div>
                    <div><input class="sidebar_pwa08run_forminput" onChange={e => this.selectedUpadate(e, "rotX")} value={(Object.keys(this.state.selectedEntity).length > 0 && Object.keys(this.state.selectedEntity.rotation).length > 0 ? this.state.selectedEntity.rotation._x : "")} /></div>
                  </div>
                  <div class="col">
                    <div>Y:</div>
                    <div><input class="sidebar_pwa08run_forminput" onChange={e => this.selectedUpadate(e, "rotY")} value={(Object.keys(this.state.selectedEntity).length > 0 && Object.keys(this.state.selectedEntity.rotation).length > 0 ? this.state.selectedEntity.rotation._y : "")} /></div>
                  </div>
                  <div class="col">
                    <div>Z:</div>
                    <div><input class="sidebar_pwa08run_forminput" onChange={e => this.selectedUpadate(e, "rotZ")} value={(Object.keys(this.state.selectedEntity).length > 0 && Object.keys(this.state.selectedEntity.rotation).length > 0 ? this.state.selectedEntity.rotation._z : "")} /></div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="pwa03builder_objectlist">
            <div class="sidebar_panel_titel">
              <TabHeader text={`OBJECT LIST`} />

            </div>
            <div class="search_03builder_box1">
              <div class="search_03builder_box2">
                <input class="search_03builder_forminput" placeholder="SEARCH" onChange={e => this.limitObjList(e)} />
                <input class="search_03builder_button1" type="search" />
              </div>
              <ul id="objList">
                {this.state.searchedEntites.map((entity, i) => (
                  <li key={i} className={(this.state.selected === entity.id ? "selected_highlight" : "")}>
                    {entity.type !== "mesh" ? (<>
                      {
                        <a onClick={() => this.props.toggleHide(entity)}><img src={entity.visible === true ? "/images/eye_open.png" : "/images/eye_close.png"} alt="" /></a>
                      }
                    </>) : (<></>)}
                    <a className={(entity.visible === true ? "obj_" + entity.classification : "obj_hidden")} onClick={() => this.props.selectObject(entity.id)}>{entity.name}</a></li>
                ))}
              </ul>
            </div>
            <div class="search_03builder_box3">
              <div class="sidebar_panel_titel">
                <TabHeader text={`CAPACITY`} />
              </div>
              <div class="search_03builder_box4">
                <div class="sidebar_pane6_loading1">
                  <div class="sidebar_pane6_loading2" style={{ width: this.state.capacity + "%" }}></div>
                </div>
                <p>VER {process.env.REACT_APP_RELEASE}</p></div>
            </div>
          </div>
        </div>
      </div>
    );

  }
}

export default ObjectPanel;