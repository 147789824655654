import React, { Component, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import {Accordion,AccordionItem,AccordionItemHeading,AccordionItemButton,AccordionItemPanel} from 'react-accessible-accordion';

import * as THREE from 'three';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { OrbitControls} from 'three/examples/jsm/controls/OrbitControls';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import { WEBGL } from 'three/examples/jsm/WebGL';
import Stats from 'three/examples/jsm/libs/stats.module';
import Popup from 'reactjs-popup';
import { Scrubber } from 'react-scrubber';

import ObjectPanel from "./ObjectPanel";
import ConsoleEvents from "../utility/ConsoleEvents";
import Loader from '../webxr/Loader';
import filesAPI from '../utility/files';
import nakamaConnection from '../utility/nakama';

import LeftPanelSession from "./LeftPanelSession";
import LeftPanelRun from "./LeftPanelRun";
import LeftPanelSpectate from "./LeftPanelSpectate";

const assetLoader = new Loader();
const consoleClient = new ConsoleEvents();
const filesClient = new filesAPI();

// three js ar
let session, gl, referenceSpace, trackImgRef, canvas;

// animations
const fadeDuration = 0;
// https://threejs.org/examples/#webgl_animation_skinning_morph

// Nakama
let nakamaAgent;

// three js scene
let container;
let cameraPersp, currentCamera;
let scene, renderer, control, orbit;
let selectedObj;
let lastAnimateReq;

// AR

// clock and stats
const stats = Stats();
const clock = new THREE.Clock();

// Keyboard
let ctrlKey;
let lastKeyPress = Date.now();

// Raycast
const raycaster = new THREE.Raycaster();
const pointer = new THREE.Vector2();

// Editor lite
let lastEntityUpdate = Date.now();
const lastEntityUpdateOffset = 500;

// progress bar
let lastProgessUpdate = Date.now();
const lastProgressUpdateOffset = 500;
let updateTick = true;

// Session vars
let sessionTick = 0;
let sessionTime = "00:00:00";
let scrubValue = 0;

// asset types
const objectsCheck = [0,4,5];
const logicCheck = [1,2,3];

const setStateAsync = (obj, state) => {
    return new Promise((resolve) =>
        obj.setState(state, resolve)
    )
}

// Display Text for sign
const defaultDisplayText = "Sample Text\n2nd Line Sample\n3rd Line Sample";
const defaultUserName = "Unknown";

// Metrics
let metricTimeout = null;
let metricUpdateTime = 1000;

class ThreeDScene extends Component {
    constructor(props) {
        super(props);

        // setup init state
        this.state = this.getInitialState();

        // general
        this.loadSession = this.loadSession.bind(this);
        this.loadScenario = this.loadScenario.bind(this);
        this.clearScenario = this.clearScenario.bind(this);
        this.checkState = this.checkState.bind(this);
        this.endSession = this.endSession.bind(this);
        this.saveAs = this.saveAs.bind(this);
        this.handleSaveAsUpdate = this.handleSaveAsUpdate.bind(this);
        this.updateScenarioList = this.updateScenarioList.bind(this);
        this.closeError = this.closeError.bind(this);

        // Three JS Basic funcs
        this.renderScene = this.renderScene.bind(this);
        this.animate = this.animate.bind(this);

        // AR
        this.onSessionStarted = this.onSessionStarted.bind(this);
        this.updateRefSpace = this.updateRefSpace.bind(this);
        this.startWebXrScene = this.startWebXrScene.bind(this);
        this.startWebXR = this.startWebXR.bind(this);

        // Add / remove / update scene objs
        this.deleteObjFromScene = this.deleteObjFromScene.bind(this);
        this.clipObj = this.clipObj.bind(this);
        this.cutObj = this.cutObj.bind(this);
        this.deleteObj = this.deleteObj.bind(this);
        this.copyObj = this.copyObj.bind(this);
        this.pasteObj = this.pasteObj.bind(this);
        this.selectObject = this.selectObject.bind(this);
        this.addEntity = this.addEntity.bind(this);
        this.addObj = this.addObj.bind(this);
        this.buildSign = this.buildSign.bind(this);
        this.toggleHide = this.toggleHide.bind(this);
        this.loadObj = this.loadObj.bind(this);

        // Loading
        this.FBXLoadFinish = this.FBXLoadFinish.bind(this);
        this.FBXAnimLoadFinish = this.FBXAnimLoadFinish.bind(this);
        this.meshLoadFinish = this.meshLoadFinish.bind(this);
        this.FBXSubMesh = this.FBXSubMesh.bind(this);
        
        // keyboard
        this.handleKeydown = this.handleKeydown.bind(this);
        this.handleKeyup = this.handleKeyup.bind(this);

        // browser
        this.onWindowResize = this.onWindowResize.bind(this);

        // Session
        this.joinMatch = this.joinMatch.bind(this);
        this.matchDataUpdate = this.matchDataUpdate.bind(this);
        this.processFrameQueue = this.processFrameQueue.bind(this);
        this.processFrame = this.processFrame.bind(this);
        this.sendMatchMove = this.sendMatchMove.bind(this);
        this.sendMatchAdd = this.sendMatchAdd.bind(this);

        // View buttons
        this.changeToFirstPerson = this.changeToFirstPerson.bind(this);
        this.changeToThirdPerson = this.changeToThirdPerson.bind(this);
        this.cameraPositionTop = this.cameraPositionTop.bind(this);

        // scene clears
        this.stopWebScene = this.stopWebScene.bind(this);
        this.stopWebXrScene = this.stopWebXrScene.bind(this);

        //raycast
        this.onMouseDown = this.onMouseDown.bind(this);

        // Editor
        this.updateEntity = this.updateEntity.bind(this);

        // Asset popups
        this.modalFilesToggle = this.modalFilesToggle.bind(this);	
        this.logicToggle = this.logicToggle.bind(this);
        this.closeFiles = this.closeFiles.bind(this);
        this.closeLogic = this.closeLogic.bind(this);

        // More popups
        this.closeSessionPopup = this.closeSessionPopup.bind(this);
        this.closeSessionNewPopup = this.closeSessionNewPopup.bind(this);
        this.closeAARPopup = this.closeAARPopup.bind(this);
        this.closeAARPopupReplay = this.closeAARPopupReplay.bind(this);
        this.closeResetPopup = this.closeResetPopup.bind(this);

        this.toggleSessionPopup = this.toggleSessionPopup.bind(this);
        this.toggleSessionNewPopup = this.toggleSessionNewPopup.bind(this);
        this.toggleAARPopup = this.toggleAARPopup.bind(this);
        this.toggleResetPopup = this.toggleResetPopup.bind(this);

        this.displayPopup = this.displayPopup.bind(this);
        this.closePopup = this.closePopup.bind(this);

        // Trainer controls
        this.toggleStartScene = this.toggleStartScene.bind(this);
        this.toggleShowScene = this.toggleShowScene.bind(this);
        this.resetScene = this.resetScene.bind(this);

        // Transform buttons
        this.setMovement = this.setMovement.bind(this);
        this.setRotation = this.setRotation.bind(this);
        this.setScale = this.setScale.bind(this);

        // Avatar
        this.avatarLoadFinish = this.avatarLoadFinish.bind(this);

        // Session controls
        this.playSession = this.playSession.bind(this);
        this.pauseSession = this.pauseSession.bind(this);
        this.resetSession = this.resetSession.bind(this);

        // Scrub controls
        this.handleScrubStart = this.handleScrubStart.bind(this);
        this.handleScrubEnd = this.handleScrubEnd.bind(this);
        this.handleScrubChange = this.handleScrubChange.bind(this);

        // Metrics
        this.updateMetrics = this.updateMetrics.bind(this);

        this.softLoadData = this.softLoadData.bind(this);

    }

    // Settings
    getInitialState = () => ({
        clipboard: {},
        entities: [],
        sessions: [],
        frameQueue: [],
        editQueue: [],
        mixers: [],
        actions: [],
        activeActions: [],
        assets: [],
        libraries: [],
        scenarios: [],
        error: [],
        loadedStateEntities: [],
        sessionUsers: [],
        metrics: [],
        isSpatialMapActive: false,
        isLoaded: false,
        openThreeErr: false,
        stats: false,
        openPopup: false,
        isWeb: false,
        isAR: false,
        isBuilder: false,
        liteMode: false,
        isReplay: false,
        resizeable: false,
        meshLoaded: false,
        isTracked: false,
        sceneLoaded: false,
        matchActive: false,
        openPopupWebXr: false,
        logic: false,
        openFiles: false,
        showScene: false,
        playScene: false,
        overhead: false,
        xrSupported: false,
        endSessionPopup: false,
        saveSessionAsNewPopup: false,
        showAARPopup: false,
        resetPopup: false,
        scenarioSaveAsValid: true,
        resetPath: false,
        sessionPath: false,
        openError: false,
        allowClosePopup: false,
        isRender: false,
        sessionTick: 0,
        scrubValue: 0,
        sessionFrameCount: 0,
        sessionTime: "00:00:00",
        popupTitle: "",
        popupMsg: "",
        sessionId: "",
        scenarioId: "",
        selected: "",
        prevSelected: "",
        matchId: "",
        matchData: "",
        matchSend: "",
        mesh: "color",
        sessionName: "",
        sessionLocation: "",
        scenarioName: "",
        scenarioDesc: "",
        scenarioSaveAs: "",
        saveAsError: ""
    });

    async setProps() {

        // Read props from parent
        if (this.props.mode === "AR") {
            await setStateAsync(this,{isAR:true});
            await setStateAsync(this,{isWeb:false});
            await setStateAsync(this,{openPopupWebXr:true});
        } else {
            await setStateAsync(this,{isAR:false});
            await setStateAsync(this,{isWeb:true});
        }

        if (this.props.sessionId) {
            await setStateAsync(this,{sessionId: this.props.sessionId});
        }

        if (this.props.scenarioId) {
            await setStateAsync(this,{scenarioId: this.props.scenarioId});
        }

        if (this.props.builderMode === "lite") {
            await setStateAsync(this,{isBuilder: true});
            await setStateAsync(this,{liteMode: true});
        } else if (this.props.builderMode === "full") {
            await setStateAsync(this,{isBuilder: true});
            await setStateAsync(this,{liteMode: false});
        }


    }

    // Scrub
    async handleScrubStart(value) {
        
    }

    async handleScrubEnd(value) {
        
        let toFrame = Math.round((value / 100) * this.state.sessionFrameCount);
        
        let data = {};
        data.frameNumber = toFrame;

        updateTick = false;
        //this.setState({sessionTick: toFrame});
        sessionTick = toFrame;

        let seek = await filesClient.seekSession(this.state.sessionId, data);

        updateTick = true;
        
    }

    async handleScrubChange(value) {
        
        lastProgessUpdate = Date.now();

        //this.setState({scrubValue: value});
        scrubValue = value;
    }

    // Callbacks
    updateParent(data) {
        this.props.dataCallBack(data);
    }

    closeError() {
        this.setState({openError: false});
    }

    async changeToFirstPerson() {
        
        if (this.state.isWeb === true && this.state.xrSupported === true) {
            this.stopWebScene();

            await setStateAsync(this,{isAR:true});
            await setStateAsync(this,{isWeb:false});

            if (this.state.matchActive === true) {
                this.closeMatch();
            }

            this.startWebXrScene();
        } else {
            console.log("NOT SUPPORTED MODE");
        }
    }

    async changeToThirdPerson() {

        if (this.state.isAR === true) {
            this.stopWebXrScene();

            await setStateAsync(this,{isAR:false});
            await setStateAsync(this,{isWeb:true});

            await setStateAsync(this,{isTracked:false});

            await setStateAsync(this,{isLoaded:false});
            await setStateAsync(this,{isRender:false});

            if (this.state.matchActive === true) {
                this.closeMatch();
            }

            this.startWebScene(true);
        }

        if (this.state.overhead === true) {
            this.cameraPositionDefault();

            this.setState({overhead: false});
        }
    }

    async startWebXR() {
        if (this.state.isAR === true && this.state.xrSupported === true) {
            console.log("Button Clicked startWebXR");
            console.log(this.state.sessionId);

            this.setState({isRender: true});

            this.loadSession(this.state.sessionId);
        } else {
            console.log("NOT SUPPORTED MODE");
        }
    }

    // Start Scene
    async startWebXrScene() {

        this.setState({openPopupWebXr:false});

        if (WEBGL.isWebGLAvailable()) {
            try {
                // canvas
                canvas = document.createElement("canvas");
                canvas.setAttribute("id","canvas");
                document.getElementById("webxr").appendChild(canvas);
                gl = canvas.getContext("webgl", {xrCompatible: true});

                scene = new THREE.Scene();

                renderer = new THREE.WebGLRenderer({
                    alpha: true,
                    preserveDrawingBuffer: true,
                    canvas: canvas,
                    context: gl
                });
                renderer.autoClear = false;

                currentCamera = new THREE.PerspectiveCamera();
                currentCamera.matrixAutoUpdate = false;

                // constant light to everything
                const light = new THREE.AmbientLight();
                scene.add(light);

                // read image to bitmap
                const getBase64FromUrl = async (url) => {
                    const data = await fetch(url);
                    const blob = await data.blob();
                    return blob;
                }
            
                const imgBlob = await getBase64FromUrl(process.env.REACT_APP_TAG);
                const imgBitmap = await createImageBitmap(imgBlob);
                
                session = await navigator.xr.requestSession('immersive-ar', {
                    requiredFeatures: ['image-tracking'],
                    optionalFeatures: ['dom-overlay'],
                    domOverlay: {root: document.body},
                    trackedImages: [{
                        image: imgBitmap,
                        widthInMeters: 0.2
                    }]
                });
                
                this.onSessionStarted(session);

                // three render
                const { XRWebGLLayer } = window;
                const baselayer = new XRWebGLLayer(session, gl);
                    session.updateRenderState({
                    baseLayer: baselayer
                });

                referenceSpace = await session.requestReferenceSpace('local');

                // Start Animation
                session.requestAnimationFrame(this.animate);

                // Start Load
                // set as loaded
                this.setState({isLoaded: true});

                this.setState({isRender: false});
                this.setState({isWeb: false});
                this.setState({isAR: true});
                this.setState({isLoaded: true});
                this.setState({isTracked: false});

            } catch (error) {
                this.displayPopup("Error", error);
                console.log("WebXR fail: " + error);
            }

        } else {
            const warning = WEBGL.getWebGLErrorMessage();
            this.displayPopup("Error", warning);

            console.log("WEBGL faile " + warning);
        }
    }

    stopWebXrScene() {

        if (session) {
            session.end();

            cancelAnimationFrame(lastAnimateReq);
            while(scene.children.length > 0){ 
                scene.remove(scene.children[0]); 
            }

            this.setState({isLoaded: false});

            this.setState({isRender: false});
            this.setState({isWeb: true});
            this.setState({isAR: false});

            // remove canvas
            document.getElementById("canvas").remove();
        }
    }

    cameraPositionTop() {
        
        if (this.state.isAR === true) {
            this.changeToThirdPerson();
        }

        if (this.state.overhead === false) {
            orbit.reset();
        
            currentCamera.position.set(0, 20, 0);
            currentCamera.updateProjectionMatrix();
            currentCamera.lookAt(new THREE.Vector3(0,0,0));

            this.setState({overhead: true});
        }
    }

    cameraPositionDefault() {
        orbit.reset();

        currentCamera.position.set(0, 5, 20);
        currentCamera.updateProjectionMatrix();

        currentCamera.lookAt(new THREE.Vector3(0,0,0));
    }

    setMovement() {
        control.setMode('translate');
    }

    setRotation() {
        control.setMode('rotate');
    }

    setScale() {
        if (this.state.resizeable === true) {
            control.setMode('scale');
        }
    }
  
    async startWebScene(loadNow) {
        if (WEBGL.isWebGLAvailable()) {
            try {
            
                container = document.getElementById('webxr');
        
                container.addEventListener('mousedown', this.onWindowResize);
                window.addEventListener('keydown', this.handleKeydown);
                window.addEventListener('keyup', this.handleKeyup);
        
                renderer = new THREE.WebGLRenderer({antialias: true});
                renderer.domElement.setAttribute("id","canvas");

                renderer.setPixelRatio(window.devicePixelRatio);
                renderer.setSize(window.innerWidth, window.innerHeight);
                renderer.localClippingEnabled = true;
                
                container.appendChild(renderer.domElement);
                const aspect = window.innerWidth / window.innerHeight;
                
                cameraPersp = new THREE.PerspectiveCamera(50, aspect, 0.01, 30000);
                currentCamera = cameraPersp;
                
                

                scene = new THREE.Scene();
                //if (this.state.isBuilder === true) {
                    scene.add(new THREE.GridHelper(40, 40, 0x888888, 0x444444));
                //}
                
        
                // constant light to everything
                const light = new THREE.AmbientLight();
                scene.add(light);
        
                orbit = new OrbitControls(currentCamera, renderer.domElement);
                orbit.update();
                orbit.addEventListener('change', this.renderScene);

                this.cameraPositionDefault();
        
                control = new TransformControls(currentCamera, renderer.domElement);
                control.addEventListener('change', this.renderScene);
        
                control.addEventListener('dragging-changed', function (event) {
                    orbit.enabled = ! event.value
                });
        
                window.addEventListener('resize', this.onWindowResize);
        
                control.addEventListener('objectChange', (e) => {
                    //selectedObj = e.target.object;
                    lastEntityUpdate = Date.now();

                    if (this.state.matchActive === true && this.state.liteMode === true) {    
                        if (Object.keys(this.state.selectedEntity).length > 0) {
                            this.sendMatchMove(this.state.selectedEntity);
                        }
                        
                    }

                });

                // Add stats to view
                if (this.state.stats) {
                    stats.setMode(0);
                    stats.domElement.style.position = 'absolute';
                    stats.domElement.style.left = '150px';
                    stats.domElement.style.top = '0';
                    stats.domElement.id = "webXrStats";

                    document.body.appendChild(stats.domElement);
                }
                
                // raycast
                window.addEventListener("click", this.onMouseDown);
        
                // Start Animation
                this.animate();
        
                // set as loaded
                this.setState({isLoaded: true});
                
                if (loadNow === true && this.state.sessionId) {
                    this.loadSession(this.state.sessionId);
                }

                /*
                if (this.state.scenarioId) {
                    this.getScenarioAndLoad(this.state.scenarioId);
                } else if (this.state.sessionId) {

                    this.loadSession(this.state.sessionId);
                }
                */

            } catch (error) {

                this.displayPopup("Error", error);
            }

        } else {
            const warning = WEBGL.getWebGLErrorMessage();
            this.displayPopup("Error", warning);
        }
  
    }

    stopWebScene() {

        cancelAnimationFrame(lastAnimateReq);
        while(scene.children.length > 0){ 
            scene.remove(scene.children[0]); 
        }

        this.setState({isLoaded: false});

        container.removeEventListener('mousedown', this.onWindowResize);
        orbit.removeEventListener('change', this.renderScene);
        control.removeEventListener('change', this.renderScene);
        control.removeEventListener('dragging-changed');
        window.removeEventListener('resize', this.onWindowResize);
        control.removeEventListener('objectChange');

        // remove canvas
        document.getElementById("canvas").remove();

    }

    // General
    async animate(time, frame) {
        var delta = clock.getDelta();

        if (this.state.isWeb === true) {
            lastAnimateReq = requestAnimationFrame(this.animate);
        } else if (this.state.isAR === true) {
            lastAnimateReq = session.requestAnimationFrame(this.animate);

            // Bind the graphics framebuffer to the baseLayer's framebuffer
            let glLayer = session.renderState.baseLayer;
            gl.bindFramebuffer(gl.FRAMEBUFFER, glLayer.framebuffer);
            
            // Retrieve the pose of the device.
            // XRFrame.getViewerPose can return null while the session attempts to establish tracking.
            const pose = frame.getViewerPose(referenceSpace);
            let poseRef;

            // tracking
           
            //if (isTracked === false) {
                if (pose) {
                    poseRef = pose.transform;
                }
        
                const results = frame.getImageTrackingResults();
                for (const result of results) {
                
                    const imageIndex = result.index;
                    
                    const state = result.trackingState;
            
                    if (state == "tracked") {
                        
                        // Get the pose of the image relative to a reference space.
                        let imgPose = frame.getPose(result.imageSpace, referenceSpace);
                        trackImgRef = imgPose.transform;

                        // update reference space
                        this.updateRefSpace(trackImgRef, poseRef);

                        if (this.state.isTracked === false) {
                            await setStateAsync(this,{isTracked:true});
                        }

                        // on first track remove tracker
                    } else if (state == "emulated") {
                        // not in view
                        //console.log("State emulated");
                    }
                }
            //}
            // End tracking

            if (pose) {
                // In mobile AR, we only have one view.
                const view = pose.views[0];

                const viewport = glLayer.getViewport(view);
                renderer.setSize(viewport.width, viewport.height);

                // Use the view's transform matrix and projection matrix to configure the THREE.camera.
                currentCamera.matrix.fromArray(view.transform.matrix);
                currentCamera.projectionMatrix.fromArray(view.projectionMatrix);
                currentCamera.updateMatrixWorld(true);
            }

        }

        // check for animations
        if (this.state.sceneLoaded === true) {
            for (let a=0;a < this.state.mixers.length; a++) {
                if (this.state.mixers[a]) {
                    this.state.mixers[a].mixer.update(delta);
                }
            }
        }

        //if (this.state.isLoaded) mixer.update(clock.getDelta())
        stats.update();

        this.renderScene();
    }

    renderScene() {
        let cap = 0;
        cap = Math.round((renderer.info.render.triangles/process.env.REACT_APP_CAPACITY)*100);

        if (cap > 100) {
        cap = 100;
        }
        
        // update capacity
        this.setState({capacity: cap});

        renderer.render(scene, currentCamera);
    }

    deleteObjFromScene(objectId) {

        let deleted = false;
        let allEntities = this.state.entities;
        this.state.entities.forEach((entity, i) => {
            if (entity.type !== "mesh") {
                if (objectId === entity.id) {
                    // Remove from scene
                    let removeObj = scene.getObjectByName(objectId);
                    
                    scene.getObjectByName(process.env.REACT_APP_TAGNAME).remove(removeObj);
                    
                    // delete from state
                    allEntities.splice(i, 1);

                    deleted = true;
                }
            }
        });

        this.setState({entities: allEntities});
        if (deleted === true) {
            this.setState({selected: ""});
            this.setState({selectedEntity: {}});
        }

    }

    clipObj() {
        let selectedObj = scene.getObjectByName(this.state.selected);

        this.state.entities.forEach((entity) => {
            if (entity.type !== "mesh") {
                if (this.state.selected === entity.id) {

                    let clipboard = {position: {}, scale: {}, rotation: {}};
                    clipboard.position.x = selectedObj.position.x;
                    clipboard.position.y = selectedObj.position.y;
                    clipboard.position.z = selectedObj.position.z;

                    clipboard.rotation.x = selectedObj.rotation.x;
                    clipboard.rotation.y = selectedObj.rotation.y;
                    clipboard.rotation.z = selectedObj.rotation.z;

                    clipboard.scale.x = selectedObj.scale.x;
                    clipboard.scale.y = selectedObj.scale.y;
                    clipboard.scale.z = selectedObj.scale.z;

                    clipboard.filePath = entity.url;
                    clipboard.name = entity.assetName;
                    clipboard.fileId = entity.fileId;
                    clipboard.class = entity.class;
                    clipboard.id = entity.assetId;

                    clipboard.pwaScaleMultiplier = entity.pwaScaleMultiplier;
                    clipboard.animations = entity.animations;
                    clipboard.defaultAnimation = entity.defaultAnimation;
                    clipboard.classification = entity.classification;
                    clipboard.displayText = entity.displayText;

                    this.setState({clipboard: clipboard});
                }
            }
        });

    }
    
    cutObj() {
        if (this.state.liteMode === false && this.state.isBuilder === true) {
            // Remove controls
            control.detach();
            scene.remove(control);

            // Save to clipboard
            this.clipObj();
            // remove from scene
            this.deleteObjFromScene(this.state.selected);
        } else {
            this.displayPopup("Error", "Objects can not be deleted in lite mode", true);
        }
    }

    deleteObj() {
        if (this.state.liteMode === false && this.state.isBuilder === true) {
            // Remove controls
            control.detach();
            scene.remove(control);

            this.deleteObjFromScene(this.state.selected);
        } else {
            this.displayPopup("Error", "Objects can not be deleted in lite mode", true);
        }
    }
    
    copyObj() {
        // Save to clipboard
        this.clipObj();
    }

    pasteObj() {
        if (Object.keys(this.state.clipboard).length !== 0) {
            this.addObj(this.state.clipboard);
        }
    }

    setMesh(opt) {
        this.setState({mesh: opt});

        if (this.state.meshLoaded === true) {
            let meshSel = scene.getObjectByName("meshlines");

            if (opt === "color") {
            meshSel.visible = true;
            } else if (opt === "off") {
            meshSel.visible = false;
            }
        }
    
    };

    async toggleSpectate(userId, spectate = false) {
        console.log("toggleSpectate");

        console.log(this.state.sessionId);
        console.log(userId);
        console.log(spectate);

        if (this.state.sessionId && userId) {
            let data = {};
            data.userId = userId;
            data.spectate = spectate;
            
            let updateSpectate = await filesClient.updateSpectate(this.state.sessionId, data);

            console.log(updateSpectate);
        }
    }

    toggleHide(entity) {
        const userDataString = localStorage.getItem('userData');
        let clientId = "00000000-0000-0000-0000-000000000000";

        if (userDataString !== null) {
            const userData = JSON.parse(userDataString);
            if (userData.id) {
                clientId = userData.id;
            }
        }

        let toToggle = scene.getObjectByName(entity.id);

        let jsonToSend = {};
        jsonToSend.clientId = clientId;
        jsonToSend.timestamp = Math.round(Date.now()/1000);
        //jsonToSend.tick = this.state.sessionTick;
        jsonToSend.tick = sessionTick;
        jsonToSend.args = {};
        jsonToSend.args.entityId = entity.id;

        if (entity.visible === true) {
            entity.visible = false;

            // Hide in scene
            if (this.state.matchActive === false) {
                toToggle.visible = false;
            }

            // Send to Remote server & check if in match
            jsonToSend.type = "entity_hide";

        } else if (entity.visible === false) {
            entity.visible = true;

            // Hide in scene
            if (this.state.matchActive === false) {
                toToggle.visible = true;
            }

            // Send to Remote server & check if in match
            jsonToSend.type = "entity_show";
        }

        if (this.state.matchActive === true && this.state.liteMode === true) {
            nakamaAgent.sendData(this.state.matchId, 0, jsonToSend);
        }

        // update entity data
        let updatedEntites = [];
        let allEntities = this.state.entities;
        
        allEntities.forEach((entityExist) => {
            if (entity.id === entityExist.id) {
                entityExist.visible = entity.visible;
            }
            updatedEntites.push(entityExist);
        });

        this.setState({entities: updatedEntites});
    }

    selectObject(objectId) {

        // Remove controls from old
        if (this.state.selected) {
            control.detach();
            scene.remove(control);
        }

        // add controls
        let sceneSelectedObj = scene.getObjectByName(objectId);
        control.attach(sceneSelectedObj);
        scene.add(control);

        // set controls to translate
        control.setMode('translate');

        this.setState({selected: objectId});

        this.state.entities.forEach((entity) => {
            if (entity.type !== "mesh") {
        
                if (objectId === entity.id) {
                    // Save copy of Object data
                    let newSelectEntityData = {};
                    newSelectEntityData.id = entity.id;
                    newSelectEntityData.position = sceneSelectedObj.position;
                    newSelectEntityData.rotation = sceneSelectedObj.rotation;
                    newSelectEntityData.scale = sceneSelectedObj.scale;
                    newSelectEntityData.name = entity.name;
                    newSelectEntityData.defaultAnimation = entity.defaultAnimation;
                    newSelectEntityData.animations = entity.animations;
                    newSelectEntityData.classification = entity.classification;
                    newSelectEntityData.displayText = entity.displayText;

                    newSelectEntityData.class = entity.class;
                    newSelectEntityData.entityType = "A";

                    this.setState({selectedEntity: newSelectEntityData});
            
                    if (entity.class === "waypoint") {
                        this.setState({resizeable: false});
                    } else {
                        this.setState({resizeable: true});
                    }
                
                }
            } else {

                this.setState({resizeable: false});
        
                if (objectId === entity.id) {
                    this.setState({selectedEntity: {}});
                }
        
            }
        });

    }

    addEntity(newEntity, name) {
        let allEntities = this.state.entities;

        let entityCount = 1;
        this.state.entities.forEach((entity) => {
            if (newEntity.fileId == entity.fileId) {
                entityCount++;
            }
        });

        if (entityCount > 1) {
            newEntity.name = newEntity.name + " " + entityCount.toString();
        }

        if (name) {
            newEntity.name = name;
        }

        allEntities.push(newEntity);

        this.setState({entities: allEntities});

        // updated selected & Prev
        if (this.state.selected) {
            this.setState({prevSelected: this.state.selected});
        }

        this.setState({selected: newEntity.id});

    }

    updateEntity(entityData, objectChanged) {

        // Track time since last update
        //lastEntityUpdate = Date.now();

        if (objectChanged === true && entityData.id) {
            let currentObj = scene.getObjectByName(entityData.id);

            let scaleX = Number(entityData.scale.x);
            let scaleY = Number(entityData.scale.y);
            let scaleZ = Number(entityData.scale.z);

            currentObj.scale.set(scaleX, scaleY, scaleZ);
        
            let posX = Number(entityData.position.x);
            let posY = Number(entityData.position.y);
            let posZ = Number(entityData.position.z);

            currentObj.position.set(posX, posY, posZ);

            let rotationX = Number(entityData.rotation._x);
            let rotationY = Number(entityData.rotation._y);
            let rotationZ = Number(entityData.rotation._z);

            currentObj.rotation.set(rotationX, rotationY, rotationZ);

            if (this.state.matchActive === true && this.state.liteMode === true) {
                lastEntityUpdate = Date.now();
                this.sendMatchMove(this.state.selectedEntity);
            }

        }

        // update entity data
        let updatedEntites = [];
        let allEntities = this.state.entities;
        
        allEntities.forEach((entity) => {
            if (entityData.id === entity.id) {
                entity.name = entityData.name;
                entity.classification = entityData.classification;
                entity.defaultAnimation = entityData.defaultAnimation;

                if (entity.displayText != entityData.displayText) {
                    // Call to rebuild
                    entity.displayText = entityData.displayText;
        
                    this.buildSign(entity.id, entity.displayText);
                }
            }
            updatedEntites.push(entity);
        });
        
        this.setState({entities: updatedEntites});

        

    }

    async loadObj(entity) {
        let allAssets = this.state.assets;

        var assetToLoad = allAssets.filter(obj => {
            return obj.id === entity.asset.id && obj.fileType === 1 && obj.fileClassification === 1
        });

        var animationsToLoad = allAssets.filter(obj => {
            return obj.id === entity.asset.id && obj.fileType === 1 && obj.fileClassification === 2
        });

        if (assetToLoad.length > 0) {
            if (this.state.isAR === true && (assetToLoad[0].classification === 1 || assetToLoad[0].classification === 2)) {
                // Don't load Trigger and waypoints for AR
                //console.log("IGNORE: " + assetToLoad[0].name);
            } else {
                //console.log("ADD: " + assetToLoad[0].name);
                
                let loadEntry = {};

                loadEntry.id = assetToLoad[0].id;
                loadEntry.filePath = assetToLoad[0].filePath;
                loadEntry.name = assetToLoad[0].name;
                loadEntry.fileId = assetToLoad[0].fileId;
                loadEntry.class = assetToLoad[0].class;
                loadEntry.pwaScaleMultiplier = assetToLoad[0].pwaScaleMultiplier;
                loadEntry.animations = animationsToLoad;

                loadEntry.classification = entity.classification;
                loadEntry.defaultAnimation = entity.defaultAnimation;
                loadEntry.visible = true;

                loadEntry.position = {};
                loadEntry.scale = {};
                loadEntry.rotation = {};

                let objQ = new THREE.Quaternion(-entity.rotation.x, -entity.rotation.y, entity.rotation.z, entity.rotation.w);
                var rotation = new THREE.Euler().setFromQuaternion(objQ, "XYZ");
                
                loadEntry.position.x = entity.position.x;
                loadEntry.position.y = entity.position.y;
                loadEntry.position.z = -entity.position.z;
    
                loadEntry.rotation.x = rotation.x;
                loadEntry.rotation.y = rotation.y;
                loadEntry.rotation.z = rotation.z;
    
                loadEntry.scale.x = entity.scale.x;
                loadEntry.scale.y = entity.scale.y;
                loadEntry.scale.z = entity.scale.z;
                
                await this.addObj(loadEntry, entity.id, entity.name);
            

            }
        }
    }

    buildSign(entityId, displayText) {
        let currentObj = scene.getObjectByName(entityId);
        const loader = new FontLoader();
        loader.load('/fonts/Source Sans Pro_Bold.json', function ( font ) {
            const color = 0x000000;
  
            const matDark = new THREE.LineBasicMaterial( {
                color: color,
                side: THREE.DoubleSide
            } );
  
            const message = displayText;
            const shapes = font.generateShapes(message, 1);
            const geometry = new THREE.ShapeGeometry(shapes);
            const text = new THREE.Mesh(geometry, matDark);
            
            text.scale.x = .02;
            text.scale.y = .02;
            text.scale.z = .02;
            
            text.position.z = .015;
            text.position.x = -.125;
            text.position.y = .125;
  
            text.name = "displayText";
  
            
            for (var i = currentObj.children.length - 1; i >= 0; i--) {
              currentObj.remove(currentObj.children[i]);
            } 
            //scene.getObjectByName(entityId).remove(removeObj);
            
            currentObj.add(text);
        });
    }

    async addObj(asset, id, name) {
        let newToScene = false;
        let newName = "";

        if (this.state.isLoaded == true) {
            let newFBX = {position: {}, scale: {}, rotation: {}};

            if (id) {
                newFBX.id = id;
            } else {
                newFBX.id = uuidv4();
                newToScene = true;
            }

            var animationsToLoad = this.state.assets.filter(obj => {
                return obj.id === asset.id && obj.fileType === 1 && obj.fileClassification === 2
            });

            let entityCount = 1;
            this.state.entities.forEach((entity) => {
                if (asset.fileId == entity.fileId) {
                    entityCount++;
                }
            });

            newName = asset.name;

            if (entityCount > 1) {
                newName = asset.name + " " + entityCount.toString();
            }

            if (name) {
                newName = name;
            }
            
            // default display Text
            let displayText = "";
            if (asset.class === "sign" && asset.displayText === undefined) {
                displayText = defaultDisplayText;
            } else if (asset.class === "sign" && asset.displayText !== undefined) {
                displayText = asset.displayText;
            }

            newFBX.assetId = asset.id;
            newFBX.url = asset.filePath;
            newFBX.name = asset.name;
            newFBX.assetName = asset.name;
            newFBX.fileId = asset.fileId;
            newFBX.class = asset.class;
            newFBX.type = "object";
            newFBX.pwaScaleMultiplier = asset.pwaScaleMultiplier;
            newFBX.animations = animationsToLoad;
            newFBX.defaultAnimation = (asset.defaultAnimation !== undefined && asset.defaultAnimation !== null ? asset.defaultAnimation : "");
            newFBX.classification = (isNaN(asset.classification) ? asset.classification : "undefined");
            newFBX.displayText = displayText;
            newFBX.visible = true;

            if (asset.position) {
                newFBX.position.x = asset.position.x;
                newFBX.position.y = asset.position.y;
                newFBX.position.z = asset.position.z;
            } else {
                newFBX.position.x = 0;
                newFBX.position.y = 0;
                newFBX.position.z = 0;
            }

            if (asset.rotation) {
                newFBX.rotation.x = asset.rotation.x;
                newFBX.rotation.y = asset.rotation.y;
                newFBX.rotation.z = asset.rotation.z;
            } else {
                newFBX.rotation.x = 0;
                newFBX.rotation.y = 0;
                newFBX.rotation.z = 0;
            }
            
            if (asset.scale) {
                newFBX.scale.x = asset.scale.x;
                newFBX.scale.y = asset.scale.y;
                newFBX.scale.z = asset.scale.z;
            } else if (asset.pwaScaleMultiplier) {
                newFBX.scale.x = asset.pwaScaleMultiplier;
                newFBX.scale.y = asset.pwaScaleMultiplier;
                newFBX.scale.z = asset.pwaScaleMultiplier;
            } else {
                // default scale
                newFBX.scale.x = 1;
                newFBX.scale.y = 1;
                newFBX.scale.z = 1;
            }

            // Add to live session
            let newEntry = {position: {}, scale: {}, rotation: {}, size: {}};
            if (this.state.matchActive === true && this.state.liteMode === true && newToScene === true) {
                
                if (asset.class === "trigger") {
                    newEntry.type = 1;
                } else if (asset.class === "waypoint") {
                    newEntry.type = 2;
                } else if (asset.class === "sign") {
                    newEntry.type = 5;
                } else {
                    newEntry.type = 0;
                }

                newEntry.size.x = 1;
                newEntry.size.y = 1;
                newEntry.size.z = 1;

                newEntry.scale.x = newFBX.scale.x;
                newEntry.scale.y = newFBX.scale.y;
                newEntry.scale.z = newFBX.scale.z;

                newEntry.position.x = 0;
                newEntry.position.y = 0;
                newEntry.position.z = 0;

                newEntry.rotation.x = 0;
                newEntry.rotation.y = 0;
                newEntry.rotation.z = 0;
                newEntry.rotation.w = 0;

                newEntry.name = newName;
                newEntry.assetId = asset.id;
                newEntry.defaultAnimation = asset.defaultAnimation;
                newEntry.classification = (isNaN(asset.classification) ? asset.classification : "undefined");
                newEntry.displayText = displayText;
                newEntry.hasGravity = true;

                let entityCreate = await filesClient.createEntity(newEntry);

                if (entityCreate.isSuccess === true && entityCreate.entity) {
                    newFBX.id = entityCreate.entity.id;
                    newEntry.id = entityCreate.entity.id;
                    
                }
            }
            
            // Add to list
            this.addEntity(newFBX, name);
           
            if (this.state.isBuilder === true) {
                // set controls to translate
                control.setMode('translate');

                // Enable / disable resize
                if (asset.class === "waypoint") {
                    this.setState({resizeable: false});
                } else {
                    this.setState({resizeable: true});
                }

                // close popup modals
                this.closeLogic();
                this.closeFiles();
            }

            this.displayPopup("LOADING", "Loading " + newFBX.assetName);
            

            if (asset.class !== "sign") {
                // Load
                await assetLoader.loadFBXAsync(newFBX.url, this.FBXLoadFinish, newFBX);
            } else {
                // Add Sign
                // Build sign
                const geometry = new THREE.BoxGeometry(.3, .3, 0.02);
                const cubeMaterial = new THREE.MeshBasicMaterial({color: 0xFFFFFF});
                const cube = new THREE.Mesh(geometry, cubeMaterial);
                cube.name = newFBX.id;

                cube.position.x = newFBX.position.x;
                cube.position.y = newFBX.position.y;
                cube.position.z = newFBX.position.z;

                cube.scale.x = newFBX.scale.x;
                cube.scale.y = newFBX.scale.y;
                cube.scale.z = newFBX.scale.z;

                cube.rotation.x = newFBX.rotation.x;
                cube.rotation.y = newFBX.rotation.y;
                cube.rotation.z = newFBX.rotation.z;

                // add to scene  
                if (this.state.isAR === true) {
                    scene.add(cube);
                } else {
                    scene.getObjectByName(process.env.REACT_APP_TAGNAME).add(cube);
                }

                this.buildSign(newFBX.id, displayText);

                if (this.state.isBuilder === true) {
                    control.attach(cube);
                    scene.add(control);

                    let newSelectEntityData = {};
                    newSelectEntityData.id = newFBX.id;
                    newSelectEntityData.position = newFBX.position;
                    newSelectEntityData.rotation = newFBX.rotation;
                    newSelectEntityData.scale = newFBX.scale;
                    newSelectEntityData.name = newFBX.name;
                    newSelectEntityData.defaultAnimation = newFBX.defaultAnimation;
                    newSelectEntityData.animations = newFBX.animations;
                    newSelectEntityData.classification = newFBX.classification;
                    newSelectEntityData.displayText = displayText;

                    newSelectEntityData.class = newFBX.class;
                    this.setState({selectedEntity: newSelectEntityData});
                }
            }

            if (this.state.matchActive === true && this.state.liteMode === true && newToScene === true && newEntry) {
                // send entity add to match
                this.sendMatchAdd(newEntry);
            }

            // clear loading screen
            this.closePopup();
            
        }

    }

    meshLoadFinish(object, assetObj, tag) {
        
        // Group and name
        const group = new THREE.Group();
        group.name = "sceneGroup";
        scene.add(group);

        const meshGroup = new THREE.Group();
        meshGroup.name = "meshGroup";

        // https://github.com/daign/clipping-with-caps
        const localPlane = new THREE.Plane(new THREE.Vector3(0, -1, 0), 3.2);
        
        // Mesh settings
        const mesh = object.children[0];
        const material = new THREE.LineBasicMaterial({
            color: 0x3f48cc,
            clippingPlanes: [localPlane]
        });

        const edges = new THREE.EdgesGeometry(mesh.geometry);
        let line = new THREE.LineSegments(edges, material);
        line.name = "meshlines";
        meshGroup.add(line);
        meshGroup.quaternion.set(tag.rotation.x, tag.rotation.y, tag.rotation.z, tag.rotation.w);


        // tag
        const geometry = new THREE.BoxGeometry(.3, .3, 0.02);
        const cubeMaterial = new THREE.MeshBasicMaterial({color: 0x00ff00});
        const cube = new THREE.Mesh(geometry, cubeMaterial);
        cube.name = process.env.REACT_APP_TAGNAME;

        // Group tag to everything else
        const groupTag = new THREE.Group();
        groupTag.name = assetObj.id;
        group.add(groupTag);

        groupTag.add(cube);
        groupTag.add(meshGroup);
        
        // create box a round mesh
        var box = new THREE.Box3().setFromObject(mesh);
        let measure = new THREE.Vector3();
        box.getSize(measure);

        // find center point of mesh
        let cent = new THREE.Vector3();
        box.getCenter(cent);

        // Position or Rotation
        line.position.set(-tag.position.x, -tag.position.y, tag.position.z);

        // Position mesh on top of grid
        group.position.set(0,  (measure.y/2) + tag.position.y - cent.y, 0);

        if (this.state.isBuilder === true) {
            // Add controls to move
            control.attach(group);
            scene.add(control);
        }
        
    }

    async FBXLoadFinish(object, assetObj) {
        object.name = assetObj.id;
        object.scale.set(assetObj.scale.x, assetObj.scale.y, assetObj.scale.z);
        
        let posX = Number(assetObj.position.x);
        let posY = Number(assetObj.position.y);
        let posZ = Number(assetObj.position.z);

        object.position.set(posX, posY, posZ);

        let rotationX = Number(assetObj.rotation.x);
        let rotationY = Number(assetObj.rotation.y);
        let rotationZ = Number(assetObj.rotation.z);

        object.rotation.set(rotationX, rotationY, rotationZ);

        object.traverse(function (child) {
            if (child.isMesh) {
                child.castShadow = true;
                child.receiveShadow = true;
            }
        });

        // add to scene  
        if (this.state.isAR === true) {
            scene.add(object);
        } else {
            scene.getObjectByName(process.env.REACT_APP_TAGNAME).add(object);
        }

        if (this.state.isBuilder === true) {
            // add controls
            control.attach(object);
            scene.add(control);

            // Save copy of Object data
            let newSelectEntityData = {};
            newSelectEntityData.id = assetObj.id;
            newSelectEntityData.position = object.position;
            newSelectEntityData.rotation = object.rotation;
            newSelectEntityData.scale = object.scale;
            newSelectEntityData.name = assetObj.name;
            newSelectEntityData.defaultAnimation = assetObj.defaultAnimation;
            newSelectEntityData.animations = assetObj.animations;
            newSelectEntityData.classification = assetObj.classification;
            newSelectEntityData.displayText = assetObj.displayText;
            newSelectEntityData.entityType = "A";
            newSelectEntityData.class = assetObj.class;

            this.setState({selectedEntity: newSelectEntityData});
        }

        
        // TEMP Add sub objects
        //if (assetObj.name === "Soldier" || assetObj.name === "Soldier 2" || assetObj.name === "Soldier 3") {
        //if (assetObj.name === "Soldier") {
        if (1 === 2) {
            
            /*
            object.traverse(function(child) {
                if (child.type === "Bone" && child.name !== "root" && child.name !== "Humanoid_") {
                    child.rotation.set(0,0,0);
                }
            });
            */
            console.log("Add Gun");

            // await assetLoader.loadFBXAsync(newFBX.url, this.FBXLoadFinish, newFBX);
            await assetLoader.loadFBXLocalAsync("/models/SM_AssaultRifleProp.fbx", this.FBXSubMesh, assetObj.id);
            /*
            SM_GunProp.fbx
            SM_AssaultRifleProp.fbx
            SM_ShotgunProp.fbx
            */
        }

        // Loading animations
        if (assetObj.animations.length > 0) {
            let newMixer = {};
            newMixer.id = assetObj.id;
            newMixer.mixer = new THREE.AnimationMixer(object);
            this.state.mixers.push(newMixer);
            
            for(let a = 0; a < assetObj.animations.length; a++) {
                let animData = assetObj.animations[a];

                await assetLoader.loadFBXAnimAsync(animData.filePath, this.FBXAnimLoadFinish, object, animData.fileName, assetObj.defaultAnimation);
            }
            
        }
        
        
        
    }

    // TEMP
    async FBXSubMesh(object, objectId) {
        console.log("testing fbx sub mesh");
        console.log(scene.getObjectByName(objectId));
        console.log(scene.getObjectByName(objectId).skeleton);
        console.log(scene.getObjectByName(objectId).children[0]);
        console.log(scene.getObjectByName(objectId).children[0].skeleton);

        //object.position.set(-0.109, 0.021, 0.002);
        //object.rotation.set(-162.869, 83.9, 81.552);

        // Position fix, mutiple by 100
        object.position.set(10.9, 2.1, -0.2);
        //object.rotation.set(-162.869, -83.9, -81.552);
        //object.rotateY(180);
        
        // https://stackoverflow.com/questions/18066581/convert-unity-transforms-to-three-js-rotations

        let newVector = new THREE.Vector3(-162.869, -83.9, 81.552);
        let rotation = new THREE.Euler().setFromVector3(newVector, "ZXY");
        console.log(rotation);
        
        object.rotation.set(rotation.x, rotation.y, rotation.z);

        // probably needs this THREE.Math.degToRad(180)
        object.rotateY(180);

        //scene.getObjectByName(objectId).children[0].skeleton.getBoneByName("boneToAttachObject").add(object)
        scene.getObjectByName(objectId).children[0].getObjectByName("Humanoid__R_Hand").add(object)

        // scene.getObjectByName(objectId).add(object);
        // modelWithSkeleton.children[x].skeleton.getBoneByName("boneToAttachObject").add(object)
    }

    async FBXAnimLoadFinish(objectAnim, object, animateName, defaultAnimation) {
        
        var mixerToUse = this.state.mixers.filter(mixerData => {
            return mixerData.id === object.name
        });

        if (mixerToUse.length > 0) {
            objectAnim.animations[0].name = animateName;
            const action = mixerToUse[0].mixer.clipAction(objectAnim.animations[0]);
            action.loop = THREE.LoopOnce;
            action.clampWhenFinished = true;
            
            let newAction = {};
            newAction.name = animateName;
            newAction.id = object.name;
            newAction.action = action;

            this.state.actions.push(newAction);

            if (this.state.matchActive === true && animateName === defaultAnimation) {
                this.state.activeActions[object.name] = newAction.action;
                // to remove repeat, disable next 2 lines
                newAction.action.loop = THREE.LoopRepeat;
                newAction.action.clampWhenFinished = false;

                newAction.action.reset().fadeIn(fadeDuration).play();
            }

        }

        
    }

    async addMesh(asset, id) {

        this.displayPopup("LOADING", "Loading Mesh..");

        if (this.state.meshLoaded === true) {
            // delete old mesh
            this.clearScenario();
        }

        if (this.state.meshLoaded == false) {

            // tag
            let tag = {id: uuidv4(), position: {}, rotation: {}, scale: {}};
            tag.scale.x = 1;
            tag.scale.y = 1;
            tag.scale.z = 1;

            if (asset.tags.length > 0) {
                for (let t = 0; t < asset.tags.length; t++) {
                    if (asset.tags[t].ordinal === 0) {
                    tag.position.x = asset.tags[t].position.x;
                    tag.position.y = asset.tags[t].position.y;
                    tag.position.z = asset.tags[t].position.z;
                    // scan client flips for visual notice
                    
                    tag.rotation.x = asset.tags[t].rotation.x;
                    tag.rotation.y = asset.tags[t].rotation.y;
                    tag.rotation.z = asset.tags[t].rotation.z;
                    tag.rotation.w = asset.tags[t].rotation.w;
                    }
                }
            } else {
                tag.position.x = 0;
                tag.position.y = 0;
                tag.position.z = 0;

                tag.rotation.x = 0;
                tag.rotation.y = 0;
                tag.rotation.z = 0;
                tag.rotation.w = 0;
            }

            // mesh
            let newMesh = {position: {}, scale: {}};

            if (id){
                newMesh.id = id;
            } else {
                newMesh.id = uuidv4();
            }
            
            newMesh.assetId = asset.id;
            newMesh.url = asset.filePath;
            newMesh.name = asset.name;
            newMesh.fileId = asset.fileId;
            newMesh.class = asset.class;
            newMesh.type = "mesh";
            newMesh.visible = true;

            newMesh.position.x = 0;
            newMesh.position.y = 0;
            newMesh.position.z = 0;

            newMesh.scale.x = 1;
            newMesh.scale.y = 1;
            newMesh.scale.z = 1;

            //assetLoader.loadMesh(scene, newMesh, tag, control);

            // toggle close & Change value
            
            // Need to fix
            //this.closeSpatialMap();
            this.setState({spatial: asset.name})

            // Add to list
            this.addEntity(newMesh);

            // set mesh to color
            this.setState({mesh: "color"});

            // set tag data
            this.setState({tag: tag});

            // set mesh loaded
            this.setState({meshLoaded: true});

            if (this.state.isBuilder === true) {
                // set controls to translate
                control.setMode('translate');
            }

            if (process.env.REACT_APP_DEBUG === true && this.state.isWeb === true) {  
                // Start Stats
                stats.setMode(0);
                stats.domElement.style.position = 'absolute';
                stats.domElement.style.left = '150px';
                stats.domElement.style.top = '0';
                //stats.domElement.style.display = 'none';
                stats.domElement.id = "webXrStats";
            
                document.body.appendChild(stats.domElement);
            }

            // Load asset
            await assetLoader.loadMeshAsync(newMesh.url, this.meshLoadFinish, newMesh, tag);

            // clear loading screen
            this.closePopup();

        }
    }

    clearScenario(saved = false) {

        // temp data for current scenarios / assets
        let tempScenarios = this.state.scenarios;
        let tempAssets = this.state.assets;
        let templibraries = this.state.libraries;

        let tempWebMode = this.state.isWeb;
        let tempArMode = this.state.isAR;
        let tempXRSupported = this.state.xrSupported;
        let tempisBuilder = this.state.isBuilder;
        let tempIsTracked = this.state.isTracked;
        let tempLiteMode = this.state.liteMode;

        let tempMatchActive = this.state.matchActive;
        let tempSessionId = this.state.sessionId;

        let tempMatchId = this.state.matchId;
        let tempSessionName = this.state.sessionName;
        let tempSessionLocation = this.state.sessionLocation;

        let tempReplay = this.state.isReplay;
        let tempSavedSessionId = this.state.savedSessionId;

        let tempRender = this.state.isRender;
        let tempTagTragged = this.state.isTracked;

        // clear state
        this.setState(this.getInitialState());
        this.setState({scenarios: tempScenarios});
        this.setState({assets: tempAssets});
        this.setState({libraries: templibraries});
        this.setState({isLoaded: true});

        this.setState({isWeb: tempWebMode});
        this.setState({isAR: tempArMode});
        this.setState({xrSupported: tempXRSupported});
        this.setState({isBuilder: tempisBuilder});
        this.setState({isTracked: tempIsTracked});
        this.setState({liteMode: tempLiteMode});

        this.setState({matchActive: tempMatchActive});
        this.setState({sessionId: tempSessionId});

        this.setState({matchId: tempMatchId});
        this.setState({sessionName: tempSessionName});
        this.setState({sessionLocation: tempSessionLocation});
        this.setState({isReplay: tempReplay});
        this.setState({savedSessionId: tempSavedSessionId});

        this.setState({isRender: tempRender});
        this.setState({isTracked: tempTagTragged});

        if (tempisBuilder === true) {
            if (control) {
                // remove controls
                control.detach();
                scene.remove(control);
            }
        }

        if (tempWebMode === true) {
            this.changeToThirdPerson();
        }

        // remove all objects
        this.clearScene();

        if (saved === true) {
            this.setState({showAARPopup: true});
        }
    }

    clearScene() {
        console.log("clearScene");
        if (scene) {
            // clear scene
            for (let i = scene.children.length - 1; i >= 0 ; i--) {
                let child = scene.children[i];

                if (child.type == "Group") { 
                    scene.remove(child);
                }
            }
        }
    }

    async softLoadData(sessionId) {
        
        let sessionList = await filesClient.getSessionsList();
    
        if (sessionList.isSuccess == true && sessionList.sessions) {
            this.setState({sessions: sessionList.sessions});

            for (let i = 0; i < sessionList.sessions.length; i++) {
                let sessionData = sessionList.sessions[i];

                if (sessionData.id === sessionId) {

                    this.setState({sessionId: sessionData.id});
                    this.setState({scenarioId: sessionData.scenarioId});
                    this.setState({sessionName: sessionData.name});
                    this.setState({sessionLocation: sessionData.location});

                    let scenarioData = await filesClient.getScenario(sessionData.scenarioId);

                    if (scenarioData && scenarioData.scenario) {
                        
                        this.setState({scenarioName: scenarioData.scenario.title});
                        this.setState({scenarioDesc: scenarioData.scenario.description});
                    }
                    
                }
            }

        }

    }

    async loadSession(sessionId) {
        console.log("loadSession");
        console.log(sessionId);

        let sessionList = await filesClient.getSessionsList();
    
        if (sessionList.isSuccess == true && sessionList.sessions) {
            this.setState({sessions: sessionList.sessions});

            for (let i = 0; i < sessionList.sessions.length; i++) {
                let sessionData = sessionList.sessions[i];

                if (sessionData.id === sessionId) {

                    console.log("sessionData");
                    console.log(sessionData);

                    this.setState({matchId: sessionData.matchId});
                    this.setState({sessionId: sessionData.id});
                    this.setState({scenarioId: sessionData.scenarioId});
                    this.setState({sessionName: sessionData.name});
                    this.setState({sessionLocation: sessionData.location});
                    

                    if (sessionData.replayable === true) {
                        // if replay session
                        this.setState({isReplay: true});
                        this.getScenarioAndLoad(sessionData.scenarioId);
                        
                        this.setState({sessionFrameCount: sessionData.frameCount});

                        /*
                        // Get session details
                        let sessionDetail = await filesClient.getSessionsById(sessionData.id);
                        if (sessionDetail.isSuccess == true && sessionDetail.session) {
                            this.setState({sessionFrameCount: sessionDetail.session.frameCount});
                        }
                        */
                    } else {
                        this.getStateAndLoad(sessionData.id);
                    }

                    if (this.state.matchActive === false) {
                        // Join session
                        this.joinMatch(sessionData.matchId);
                    }
                    
                }
            }

        } else {
            // display error connectiong to files API

        }
    }

    async delay(ms) {
        return new Promise(res => setTimeout(res, ms));
    }

    async checkState(sessionId) {
        let limit = 30;

        let scenarioData;

        for(let a=0; a<limit; a++) {
            scenarioData = await filesClient.getState(sessionId);

            if (scenarioData && scenarioData.scenario) {
                a = limit;
            } else {
                await this.delay(500);
            }
        }

        if (!scenarioData) {
            scenarioData = {};
        }

        return scenarioData;
    }

    async getStateAndLoad(sessionId) {
        console.log("getStateAndLoad");
        console.log(sessionId);
        if (sessionId) {
            // clear Scenario
            this.clearScenario();
            let scenarioData = await this.checkState(sessionId);
            
            if (scenarioData && scenarioData.scenario) {
                this.loadScenario(scenarioData);
                
                this.setState({sessionId: sessionId});
                this.setState({scenarioId: scenarioData.scenario.id});
                this.setState({sessionName: this.state.sessionName});
                this.setState({sessionLocation: this.state.sessionLocation});
                this.setState({scenarioName: scenarioData.scenario.title});
                this.setState({scenarioDesc: scenarioData.scenario.description});

            } else {
                // error
                this.setState({matchId: ""});
                this.setState({sessionId: ""});
                this.setState({scenarioId: ""});

                if (this.state.matchActive === true) {
                    this.closeMatch();
                }

                console.log("Couldn't load state");

                this.setState({error: ["Couldn't load state"]});
                this.setState({openError: true});

            }
        } else {
            // error
        }

    }

    async getScenarioAndLoad(scenarioId) {
        console.log("getScenarioAndLoad");
        console.log(scenarioId);
        if (scenarioId) {
            // clear Scenario
            this.clearScenario();
            //let scenarioData = await this.checkState(sessionId);
            let scenarioData = await filesClient.getScenario(scenarioId);

            if (scenarioData && scenarioData.scenario) {
                this.loadScenario(scenarioData);

                this.setState({sessionId: this.state.sessionId});
                this.setState({scenarioId: scenarioData.scenario.id});
                this.setState({sessionName: this.state.sessionName});
                this.setState({sessionLocation: this.state.sessionLocation});
                this.setState({scenarioName: scenarioData.scenario.title});
                this.setState({scenarioDesc: scenarioData.scenario.description});
                
            } else {
                // error
                this.setState({matchId: ""});
                this.setState({sessionId: ""});
                this.setState({scenarioId: ""});

                if (this.state.matchActive === true) {
                    this.closeMatch();
                }

                console.log("Couldn't load scenario");

                this.setState({error: ["Couldn't load scenario"]});
                this.setState({openError: true});

            }
        } else {
            // error
        }

    }

    async loadScenario(scenarioData) {
        if (scenarioData && scenarioData.scenario) {

            // Set ScenarioId
            this.setState({scenarioId: scenarioData.scenario.id});

            // set name
            this.setState({scenarioName: scenarioData.scenario.title});

            // set desc
            this.setState({scenarioDesc: scenarioData.scenario.description});

            // set script / theme
            this.setState({theme: Buffer.from(scenarioData.scenario.theme.script.data, "base64").toString()});

            let toLoadMesh = {};
            let toLoadMeshId = "";
            let toLoadAssets = [];

            if (scenarioData.scenario.environment.entities) {
                let entities = scenarioData.scenario.environment.entities;
                for(let i=0; i< entities.length;i++) {
                    toLoadMesh = entities[i];
                    toLoadMeshId = entities[i].id;
                }
            }

            // get all entities
            if (scenarioData.scenario.scene.entities) {
                let entities = scenarioData.scenario.scene.entities;
                for(let i=0; i< entities.length;i++) {
                    toLoadAssets.push(entities[i]);
                }
            }

            let allAssets = this.state.assets;
            var meshToLoad;
            if (allAssets.length > 0) {
                // Load Mesh
                meshToLoad = allAssets.filter(obj => {
                    return obj.id === toLoadMesh.asset.id && obj.fileType === 3
                });
            }

            if (meshToLoad.length > 0) {
                
                if (this.state.isWeb === true) {
                    await this.addMesh(meshToLoad[0], toLoadMeshId);
                } else if (this.state.isAR === true) {
                    /*
                    const group = new THREE.Group();
                    group.name = "sceneGroup";
                    scene.add(group);
                    */
                    // tag
                    /*
                    const geometry = new THREE.BoxGeometry(.3, .3, 0.02);
                    const cubeMaterial = new THREE.MeshBasicMaterial({color: 0x00ff00});
                    const cube = new THREE.Mesh(geometry, cubeMaterial);
                    cube.name = process.env.REACT_APP_TAGNAME;
                    cube.position.set(0,0,0);
                    scene.add(cube);
                    */
                }
                
                for (let i=0; i<toLoadAssets.length; i++) {
                    var assetToLoad = allAssets.filter(obj => {
                        return obj.id === toLoadAssets[i].asset.id && obj.fileType === 1 && obj.fileClassification === 1
                    });

                    var animationsToLoad = allAssets.filter(obj => {
                        return obj.id === toLoadAssets[i].asset.id && obj.fileType === 1 && obj.fileClassification === 2
                    });

                    if (assetToLoad.length > 0) {
                        if (this.state.isAR === true && (assetToLoad[0].classification === 1 || assetToLoad[0].classification === 2)) {
                            // Don't load Trigger and waypoints for AR
                            //console.log("IGNORE: " + assetToLoad[0].name);
                        } else {
                            //console.log("ADD: " + assetToLoad[0].name);
                            
                            let loadEntry = {};

                            loadEntry.id = assetToLoad[0].id;
                            loadEntry.filePath = assetToLoad[0].filePath;
                            loadEntry.name = assetToLoad[0].name;
                            loadEntry.fileId = assetToLoad[0].fileId;
                            loadEntry.class = assetToLoad[0].class;
                            loadEntry.pwaScaleMultiplier = assetToLoad[0].pwaScaleMultiplier;
                            loadEntry.animations = animationsToLoad;

                            loadEntry.classification = toLoadAssets[i].classification;
                            loadEntry.defaultAnimation = toLoadAssets[i].defaultAnimation;
                            loadEntry.displayText = toLoadAssets[i].displayText;
                            loadEntry.visible = true;

                            loadEntry.position = {};
                            loadEntry.scale = {};
                            loadEntry.rotation = {};

                            let objQ = new THREE.Quaternion(-toLoadAssets[i].rotation.x, -toLoadAssets[i].rotation.y, toLoadAssets[i].rotation.z, toLoadAssets[i].rotation.w);
                            var rotation = new THREE.Euler().setFromQuaternion(objQ, "XYZ");
                            
                            loadEntry.position.x = toLoadAssets[i].position.x;
                            loadEntry.position.y = toLoadAssets[i].position.y;
                            loadEntry.position.z = -toLoadAssets[i].position.z;
                
                            loadEntry.rotation.x = rotation.x;
                            loadEntry.rotation.y = rotation.y;
                            loadEntry.rotation.z = rotation.z;
                
                            loadEntry.scale.x = toLoadAssets[i].scale.x;
                            loadEntry.scale.y = toLoadAssets[i].scale.y;
                            loadEntry.scale.z = toLoadAssets[i].scale.z;
                            
                            await this.addObj(loadEntry, toLoadAssets[i].id, toLoadAssets[i].name);
                        

                        }
                    }
                }

                // prcess frame queue
                this.processFrameQueue();

                //this.setState({sceneLoaded: true});
            }
            

        } else {
        // SHOW ERROR

        }
    }

    handleSaveAsUpdate(e) {
        this.setState({scenarioSaveAsValid: true, scenarioSaveAs: e.target.value});
    }

    async updateScenarioList() {
        let scenariosList = await filesClient.getScenariosList();
        
        if (scenariosList.isSuccess === true && scenariosList.scenarios) {
            this.setState({scenarios: scenariosList.scenarios});
        } else {
            // display error connectiong to files API
        }
    }

    saveAs() {
        // Check if existing scenario
        let currentScenarios = this.state.scenarios;

        if (this.state.scenarioSaveAs === "") {
            //
            this.setState({scenarioSaveAsValid: false});
            this.setState({saveAsError: "Missing Scenario Name"});
        } else {
            var scenarioCheck = currentScenarios.filter(scenario => {
                return scenario.title.toLowerCase() === this.state.scenarioSaveAs.toLowerCase();
            });

            if (scenarioCheck.length === 0) {
                this.setState({saveSessionAsNewPopup: false});
                this.saveScenario(true);
            } else {
                this.setState({scenarioSaveAsValid: false});
                this.setState({saveAsError: "Scenario with that name already exists"});
            }
            
        }
    }

    async updateScenario() {

        let currentScenario = await filesClient.getScenario(this.state.scenarioId);
        
        let currentEntities = [];
        let entityCreate;

        if (currentScenario && currentScenario.scenario) {
            let scriptId = currentScenario.scenario.theme.script.id;
            let themeId = currentScenario.scenario.theme.id;
            let sceneId = currentScenario.scenario.theme.scene.id;
            let environmentId = currentScenario.scenario.environment.id;

            // get all entities
            if (currentScenario.scenario.theme.scene.entities) {
                let entities = currentScenario.scenario.theme.scene.entities;
                for(let i=0; i< entities.length;i++) {
                    currentEntities.push(entities[i].id);
                }
            }

            // get all entities from environment
            if (currentScenario.scenario.environment.entities) {
                let entities = currentScenario.scenario.environment.entities;
                for(let i=0; i< entities.length;i++) {
                    currentEntities.push(entities[i].id);
                }
            }

            for (let e = 0; e < this.state.entities.length; e++) {
                let entity = this.state.entities[e];
                let newEntry = {position: {}, scale: {}, rotation: {}, size: {}};
                let currentObj = {};

                var isCurrent = currentEntities.filter(obj => {
                    return obj === entity.id
                });
            
                if (entity.type === "object") {
                    currentObj = scene.getObjectByName(entity.id);
                } else {
                    currentObj = scene.getObjectByName("meshlines");
                }

                newEntry.scale.x = currentObj.scale.x;
                newEntry.scale.y = currentObj.scale.y;
                newEntry.scale.z = currentObj.scale.z;

                newEntry.name = entity.name;
                newEntry.assetId = entity.assetId;
                newEntry.defaultAnimation = entity.defaultAnimation;
                newEntry.classification = entity.classification;
                newEntry.displayText = entity.displayText;
            
                if (entity.type === "object") {
                    newEntry.sceneId = sceneId;
                    newEntry.hasGravity = true;
                    let objQ = new THREE.Quaternion(currentObj.quaternion.x, currentObj.quaternion.y, currentObj.quaternion.z, currentObj.quaternion.w);

                    newEntry.position.x = currentObj.position.x;
                    newEntry.position.y = currentObj.position.y;
                    newEntry.position.z = -currentObj.position.z;

                    newEntry.rotation.x = -objQ.x;
                    newEntry.rotation.y = -objQ.y;
                    newEntry.rotation.z = objQ.z;
                    newEntry.rotation.w = objQ.w;
                } else if (entity.type === "mesh") {
                    newEntry.environmentId = environmentId;
                    newEntry.hasGravity = false;

                    // create quaternion
                    // invert

                    // -x
                    let meshQ = new THREE.Quaternion(currentObj.parent.quaternion.x, currentObj.parent.quaternion.y, currentObj.parent.quaternion.z, currentObj.parent.quaternion.w);
                    meshQ.invert();

                    newEntry.position.x = -currentObj.position.x;
                    newEntry.position.y = currentObj.position.y;
                    newEntry.position.z = currentObj.position.z;

                    newEntry.rotation.x = meshQ.x;
                    newEntry.rotation.y = meshQ.y;
                    newEntry.rotation.z = meshQ.z;
                    newEntry.rotation.w = meshQ.w;
                }

                if (entity.class === "trigger") {
                    newEntry.type = 1;
                } else if (entity.class === "waypoint") {
                    newEntry.type = 2;
                } else if (entity.class === "sign") {
                    newEntry.type = 5;
                } else {
                    newEntry.type = 0;
                }

                newEntry.size.x = 1;
                newEntry.size.y = 1;
                newEntry.size.z = 1;

                if (isCurrent.length > 0) {
                    let entityUpdate = await filesClient.updateEntity(entity.id, newEntry);

                    // Remove updated
                    currentEntities = currentEntities.filter(function(id) {
                        return id !== entity.id
                    })

                } else {
                    newEntry.assetId = entity.assetId;
                    if (entity.type === "object") {
                        newEntry.sceneId = sceneId;
                    } else if (entity.type === "mesh") {
                        newEntry.environmentId = environmentId;
                    }
                    entityCreate = await filesClient.createEntity(newEntry);

                    if (entityCreate.isSuccess === true && entityCreate.entity) {
                        //let entityId = entityCreate.entity.id;
                    }
                }
            
            }

            // remove any unused entities
            for (let e = 0; e < currentEntities.length; e++) {
                await filesClient.deleteEntity(currentEntities[e]);
            }
            
            let scriptsData = {};
            scriptsData.id = scriptId;
            scriptsData.tag = 0;
            scriptsData.data = Buffer.from(this.state.theme).toString('base64');
            let scriptsCreate = await filesClient.updateScript(scriptId, scriptsData);
            if (scriptsCreate.isSuccess === true && scriptsCreate.script) {
            }
            
            let scenarioData = {};
            scenarioData.themeId = themeId;
            scenarioData.title = this.state.scenarioName;
            scenarioData.description = this.state.scenarioDesc;

            let scenarioUpdate = await filesClient.updateScenario(this.state.scenarioId, scenarioData);
            if (scenarioUpdate && scenarioUpdate.isSuccess === true && scenarioUpdate.scenario) {
                
                
                this.setState({openSaveConfirm: true});

                this.setState({scenarioId: this.state.scenarioId});

                this.updateScenarioList();

                // End session and save


            } else {
                // display error

                //this.setState({error: ["Failed to update scenario"]});
                //this.setState({openError: true});
            }
            


        } else {
            // Display Error Saving

            //this.setState({error: ["Failed to load scene"]});
            //this.setState({openError: true});
            
        }

    }

    async newScenario(saveAs = false) {
        // POST ​/api​/scenes​/create
        let sceneData = {};
        let sceneCreate = await filesClient.createScene(sceneData);

        let environmentData = {};
        let environmentCreate = await filesClient.createEnvironment(environmentData);
        
        let entityCreate;

        if (sceneCreate.isSuccess === true && sceneCreate.scene && environmentCreate.isSuccess === true && environmentCreate.environment) {
            let sceneId = sceneCreate.scene.id;
            let environmentId = environmentCreate.environment.id;
        
            // POST ​/api​/entities​/create
            // createEntity(data)
        
            for (let e = 0; e < this.state.entities.length; e++) {
                let entity = this.state.entities[e];
                let newEntry = {position: {}, scale: {}, rotation: {}, size: {}};
                let currentObj = {};

                if (entity.type === "object") {
                    currentObj = scene.getObjectByName(entity.id);
                } else {
                    currentObj = scene.getObjectByName("meshlines");
                }

                newEntry.scale.x = currentObj.scale.x;
                newEntry.scale.y = currentObj.scale.y;
                newEntry.scale.z = currentObj.scale.z;

                newEntry.name = entity.name;
                newEntry.assetId = entity.assetId;
                newEntry.defaultAnimation = entity.defaultAnimation;
                newEntry.classification = entity.classification;
                newEntry.displayText = entity.displayText;

                if (entity.type === "object") {
                    newEntry.sceneId = sceneId;
                    newEntry.hasGravity = true;
                    // create quaternion
                    // invert

                    // -z

                    let objQ = new THREE.Quaternion(currentObj.quaternion.x, currentObj.quaternion.y, currentObj.quaternion.z, currentObj.quaternion.w);
                    //objQ.invert();

                    newEntry.position.x = currentObj.position.x;
                    newEntry.position.y = currentObj.position.y;
                    newEntry.position.z = -currentObj.position.z;

                    newEntry.rotation.x = -objQ.x;
                    newEntry.rotation.y = -objQ.y;
                    newEntry.rotation.z = objQ.z;
                    newEntry.rotation.w = objQ.w;
                } else if (entity.type === "mesh") {
                    newEntry.environmentId = environmentId;
                    newEntry.hasGravity = false;

                    // create quaternion
                    // invert

                    // -x
                    let meshQ = new THREE.Quaternion(currentObj.parent.quaternion.x, currentObj.parent.quaternion.y, currentObj.parent.quaternion.z, currentObj.parent.quaternion.w);
                    meshQ.invert();

                    newEntry.position.x = -currentObj.position.x;
                    newEntry.position.y = currentObj.position.y;
                    newEntry.position.z = currentObj.position.z;

                    newEntry.rotation.x = meshQ.x;
                    newEntry.rotation.y = meshQ.y;
                    newEntry.rotation.z = meshQ.z;
                    newEntry.rotation.w = meshQ.w;
                }

                if (entity.class === "trigger") {
                    newEntry.type = 1;
                } else if (entity.class === "waypoint") {
                    newEntry.type = 2;
                } else if (entity.class === "sign") {
                    newEntry.type = 5;
                } else {
                    newEntry.type = 0;
                }

                newEntry.size.x = 1;
                newEntry.size.y = 1;
                newEntry.size.z = 1;

                entityCreate = await filesClient.createEntity(newEntry);
            }

            // POST /api/scripts/create
            // tag, data (BASE64)
            let scriptsData = {};
            scriptsData.tag = 0;
            scriptsData.data = Buffer.from(this.state.theme).toString('base64');
            let scriptsCreate = await filesClient.createScript(scriptsData);
            if (scriptsCreate.isSuccess === true && scriptsCreate.script) {

                let themeData = {};
                themeData.scriptId = scriptsCreate.script.id;
                let themeCreate = await filesClient.createTheme(themeData);
                if (themeCreate.isSuccess === true && themeCreate.theme) {
                    let themeId =  themeCreate.theme.id;
                    
                    let scenarioData = {};
                    scenarioData.themeId = themeId;
                    if (saveAs) {
                        scenarioData.title = this.state.scenarioSaveAs;
                    } else {
                        scenarioData.title = this.state.scenarioName;
                    }

                    scenarioData.sceneId = sceneId;
                    scenarioData.environmentId = environmentId;

                    let scenarioCreate = await filesClient.createScenario(scenarioData);
                    if (scenarioCreate && scenarioCreate.isSuccess === true && scenarioCreate.scenario) {
                        //this.setState({openSaveConfirm: true});
                        
                        if (saveAs === false) {
                            this.setState({scenarioId: scenarioCreate.scenario.id});
                        }

                        this.updateScenarioList();

                        if (this.state.sessionPath === true) {
                            this.endSession(true);
                        } else if (this.state.resetPath === true) {
                            this.resetScene();
                        }
                        
                    } else {
                        // display error
                        //this.setState({error: ["Failed to create scenario"]});
                        //this.setState({openError: true});

                    }

                    

                }
            }
        
        } else {
            // Error
            //this.setState({error: ["Failed to create scene"]});
            //this.setState({openError: true});
        }
    }

    async saveScenario(saveAs = false) {
    
        // /backend/src/master/csharp/dotnet/README.md
        let errorMsg = [];

        if (this.state.scenarioName === "") {
            errorMsg.push("Missing Scenario Name");
        } else {
            if (saveAs === false) {
                this.setState({scenarioSavedName: this.state.scenarioName});
            } else {
                this.setState({scenarioSavedName: this.state.scenarioSaveAs});
            }
        }

        if (this.state.theme === "") {
            errorMsg.push("Missing theme data");
        }

        if (this.state.entities.length === 0) {
            errorMsg.push("Must contain atleast a mesh");
        }

        // Check for required fields theme, scenarioName, entities
        if (errorMsg.length > 0) {
            // show error modal
            this.setState({error: errorMsg});
            this.setState({openError: true});

        } else {
            // Update
            if (this.state.scenarioId && saveAs === false) {
                this.updateScenario();
            } else { // new scenario
                this.newScenario(saveAs);
            }
        }

    }
    
    
    // browser
    onWindowResize() {
        const aspect = window.innerWidth / window.innerHeight;
    
        cameraPersp.aspect = aspect;
        cameraPersp.updateProjectionMatrix();
    
        renderer.setSize(window.innerWidth, window.innerHeight);
    
        this.renderScene();        
    }

    onMouseDown(event) {
        // if not loading
        if (this.state.openPopup === false && this.state.isBuilder === true && (Date.now() - lastEntityUpdate) > lastEntityUpdateOffset) {
        
          // Update mouse location
          let offsetRec = document.getElementById('webxr').getBoundingClientRect();
          let offset = offsetRec.top;
    
          pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
          pointer.y = -((event.clientY-offset) / window.innerHeight ) * 2 + 1;
    
          raycaster.setFromCamera(pointer, currentCamera);
          
          // Check for intersect
          let allIntersects = [];
          if (scene.getObjectByName(process.env.REACT_APP_TAGNAME)) {
            if (this.state.entities && this.state.entities.length > 0) {
    
              for(let i=0; i<this.state.entities.length; i++) {
                let curEntity = this.state.entities[i];
                let returnIntersect = {};
    
                if (curEntity.type != "mesh") {
                  let intersects = raycaster.intersectObject(scene.getObjectByName(curEntity.id), true);
                  if (intersects.length > 0) {
                    returnIntersect.id = curEntity.id;
                    returnIntersect.distance = intersects[0].distance;
    
                    allIntersects.push(returnIntersect);
                  }            
                }
              }
            }
            
            allIntersects.sort((a, b) => (a.distance > b.distance) ? 1 : -1)
    
            if (allIntersects.length > 0) {
              this.selectObject(allIntersects[0].id);
            } else {
              //this.deSelectObject();
            }
    
          }
    
        }
        
      }


    // Keyboard
    handleKeydown(event) {
        // check where typing, ignore if input or textarea
        if (event.target.nodeName !== "INPUT" && event.target.nodeName !== "TEXTAREA") {

            if (Date.now() - lastKeyPress > 250) {
                ctrlKey = false;
            }

            if (event.ctrlKey) {
                ctrlKey = true;
            }

            if (ctrlKey === true) {
                switch (event.keyCode) {
                case 67: // c
                    this.copyObj();
                    break;
                case 86: // v
                    this.pasteObj();
                    break;
                case 88: // x
                    this.cutObj();
                    break;
                case 90: // z
                    // undo ?
                    break;
                }

            } else {
                switch (event.keyCode) {

                case 16: // Shift
                    control.setTranslationSnap(100);
                    control.setRotationSnap(THREE.MathUtils.degToRad(15));
                    control.setScaleSnap(0.25);
                    break;
                
                case 46: // del
                    this.deleteObj();
                    break;

                case 87: // W
                    control.setMode('translate');
                    break;

                case 69: // E
                    control.setMode('rotate');
                    break;

                case 82: // R
                    if (this.state.resizeable === true) {
                        control.setMode('scale');
                    }
                    
                    break;
                case 187:
                case 107: // +, =, num+
                    control.setSize(control.size + 0.1);
                    break;

                case 189:
                case 109: // -, _, num-
                    control.setSize(Math.max(control.size - 0.1, 0.1));
                    break;

                case 88: // X
                    //control.showX = ! control.showX;
                    break;

                case 89: // Y
                    //control.showY = ! control.showY;
                    break;

                case 90: // Z
                    //control.showZ = ! control.showZ;
                    break;

                case 32: // Spacebar
                    //control.enabled = ! control.enabled;
                    break;

                case 27: // Esc
                    
                    //
                    //selectedObj.scale.set(1, 1, 1);
                    //selectedObj.rotation.set(0, 0, 0);


                    break;

                }
            
            }

            lastKeyPress = Date.now();
        }
        
    }

    handleKeyup(event) {
        switch (event.keyCode) {

            case 16: // Shift
                control.setTranslationSnap(null);
                control.setRotationSnap(null);
                control.setScaleSnap(null);
            break;

        }
    }

    // Webxr
    async onSessionStarted(session) {
        const scores = await session.getTrackedImageScores();
    
        let trackableImages = 0;
        for (let index = 0; index < scores.length; ++index) {
            console.log("Tracked score:" + scores[index]);

            if (scores[index] == 'untrackable') {
                // Need error, tag untrackable
                console.log("Image untrackable");
            } else {
                ++trackableImages;
            }
        }
        if (trackableImages == 0) {
            //WarnUser("No trackable images");
        }
    }

    updateRefSpace(tagPosition, poseRef) {

        const { XRRigidTransform } = window;
        
        let newPosition = new XRRigidTransform({
            w: tagPosition.position.w,
            x: tagPosition.position.x,
            y: tagPosition.position.y,
            z: tagPosition.position.z
        });

        //
        const newReferenceSpace = referenceSpace.getOffsetReferenceSpace(newPosition);
        
        session.referenceSpace = newReferenceSpace;
        referenceSpace = newReferenceSpace;
    }

    // Popup
    displayPopup(title, msg, allowClose = false) {
        this.setState({openPopup: true});
        this.setState({popupTitle: title});
        this.setState({popupMsg: msg});
        this.setState({allowClosePopup: allowClose});
    }

    closePopup() {
        this.setState({openPopup: false});
        this.setState({popupTitle: ""});
        this.setState({popupMsg: ""});
        this.setState({allowClosePopup: false});
    }

    modalFilesToggle = () => {
        this.setState({openFiles: !this.state.openFiles});
    };
      
    logicToggle() {
        this.setState({logic: !this.state.logic});
    }

    closeFiles() {
        this.setState({openFiles: false})
    }

    closeLogic() {
        this.setState({logic: false});
    }

    closeSessionPopup() {
        this.setState({endSessionPopup: false});
        this.setState({sessionPath: false});
    }
    
    closeSessionNewPopup() {
        this.setState({saveSessionAsNewPopup: false});
    }
    
    closeAARPopup() {
        this.setState({showAARPopup: false});
    }

    async closeAARPopupReplay() {
        this.closeAARPopup();

        let sessionReplay = await filesClient.replaySession(this.state.savedSessionId);
        console.log(sessionReplay);
        console.log(this.state.savedSessionId);

        if (sessionReplay.isSuccess === true && sessionReplay.session) {
            // redirect
            window.location = "/session/review/" + this.state.savedSessionId;
        } else if (sessionReplay.isSuccess === false) {
            // error
        }

    }
    
    closeResetPopup() {
        this.setState({resetPopup: false});
        this.setState({resetPath: false});
    }

    toggleSessionPopup() {
        console.log("session popup");
        if (this.state.endSessionPopup === false) {
            this.setState({sessionPath: true});
        }

        this.setState({endSessionPopup: !this.state.endSessionPopup});
    }
    
    toggleSessionNewPopup() {
        // Close other popups
        this.setState({resetPopup: false});
        this.setState({endSessionPopup: false});

        this.setState({saveSessionAsNewPopup: !this.state.saveSessionAsNewPopup});
    }
    
    toggleAARPopup() {
        this.setState({showAARPopup: !this.state.showAARPopup});
    }
    
    toggleResetPopup() {
        if (this.state.resetPopup === false) {
            this.setState({resetPath: true});
        }

        this.setState({resetPopup: !this.state.resetPopup});
    }
      

    // Session

    async endSession(saved = false) {
        let sessionDelete = await filesClient.deleteSession(this.state.sessionId);

        this.setState({savedSessionId: this.state.sessionId});

        this.closeMatch();

        this.setState({matchId: ""});
        this.setState({sessionId: ""});
        this.setState({matchActive: false});
        
        this.clearScenario(saved);


    }

    async toggleStartScene() {
        if (this.state.matchActive && this.state.matchId && this.state.sessionId) {
            
            if (this.state.playScene === true) {
                await filesClient.pauseSession(this.state.sessionId);
            } else {
                await filesClient.playSession(this.state.sessionId);
            }
            
        }
    }

    async playSession() {
        await filesClient.playSession(this.state.sessionId);
    }

    async pauseSession() {
        await filesClient.pauseSession(this.state.sessionId);
    }

    async resetSession() {
        await filesClient.resetSession(this.state.sessionId);
    }

    async toggleShowScene() {
        if (this.state.matchActive && this.state.matchId && this.state.sessionId) {
            
            if (this.state.showScene === true) {
                await filesClient.hideSession(this.state.sessionId);
            } else {
                await filesClient.showSession(this.state.sessionId);
            }

        }
    }

    async resetScene() {
        if (this.state.matchActive && this.state.matchId && this.state.sessionId) {
            await filesClient.resetSession(this.state.sessionId);

        }
        
    }

    joinMatch(matchId) {
        if (matchId) {
            nakamaAgent = new nakamaConnection(matchId);

            // connect to nakama
            const nakamaConn = nakamaAgent.connect(this.matchDataUpdate);

            // Start pulling data
            if (this.state.liteMode === true && this.state.isReplay === false) {
                metricTimeout = setTimeout(this.updateMetrics, metricUpdateTime);
            }

        } else {
            // error missing match id

        }
    }

    sendMatchMove(entity) {

        const userDataString = localStorage.getItem('userData');
        let clientId = "00000000-0000-0000-0000-000000000000";

        if (userDataString !== null) {
            const userData = JSON.parse(userDataString);
            if (userData.id) {
                clientId = userData.id;
            }
        }

        let euler = new THREE.Euler(entity.rotation.x, entity.rotation.y, entity.rotation.z, 'XYZ');
        let objQ = new THREE.Quaternion().setFromEuler(euler, "XYZ");
        
        let jsonToSend = {};
        jsonToSend.clientId = clientId;
        jsonToSend.type = "entity_move";
        jsonToSend.timestamp = Math.round(Date.now()/1000);
        //jsonToSend.tick = this.state.sessionTick;
        jsonToSend.tick = sessionTick;
        
        jsonToSend.args = {};
        jsonToSend.args.entityId = entity.id;
        
        jsonToSend.args.position = {};
        jsonToSend.args.position.x = entity.position.x;
        jsonToSend.args.position.y = entity.position.y;
        jsonToSend.args.position.z = -entity.position.z;
        
        jsonToSend.args.rotation = {};
        jsonToSend.args.rotation.x = -objQ.x;
        jsonToSend.args.rotation.y = -objQ.y;
        jsonToSend.args.rotation.z = objQ.z;
        jsonToSend.args.rotation.w = objQ.w;

        jsonToSend.args.scale = {};
        jsonToSend.args.scale.x = entity.scale.x;
        jsonToSend.args.scale.y = entity.scale.y;
        jsonToSend.args.scale.z = entity.scale.z;

        nakamaAgent.sendData(this.state.matchId, 0, jsonToSend);
    }

    sendMatchAdd(entity) {

        const userDataString = localStorage.getItem('userData');
        let clientId = "00000000-0000-0000-0000-000000000000";

        if (userDataString !== null) {
            const userData = JSON.parse(userDataString);
            if (userData.id) {
                clientId = userData.id;
            }
        }
        
        let jsonToSend = {};
        jsonToSend.clientId = clientId;
        jsonToSend.type = "entity_add";
        jsonToSend.timestamp = Math.round(Date.now()/1000);
        //jsonToSend.tick = this.state.sessionTick;
        jsonToSend.tick = sessionTick;
        
        jsonToSend.args = {};
        jsonToSend.args.entityId = entity.id;
        
        jsonToSend.args.position = {};
        jsonToSend.args.position.x = entity.position.x;
        jsonToSend.args.position.y = entity.position.y;
        jsonToSend.args.position.z = entity.position.z;
        
        jsonToSend.args.rotation = {};
        jsonToSend.args.rotation.x = entity.rotation.x;
        jsonToSend.args.rotation.y = entity.rotation.y;
        jsonToSend.args.rotation.z = entity.rotation.z;

        jsonToSend.args.scale = {};
        jsonToSend.args.scale.x = entity.scale.x;
        jsonToSend.args.scale.y = entity.scale.y;
        jsonToSend.args.scale.z = entity.scale.z;

        nakamaAgent.sendData(this.state.matchId, 0, jsonToSend);
    }

    closeMatch() {
        nakamaAgent.closeConnection();

        this.setState({matchActive: false});

        clearTimeout(metricTimeout);
    }

    async matchDataUpdate(matchData) {
        this.setState({matchActive: true});

        if (this.state.sceneLoaded === true) {
            // update objects
            //console.log("update objs");

            this.processFrame(matchData);
        } else if (this.state.sceneLoaded === false) {

            //console.log("frame queue");

            let currentQueue = this.state.frameQueue;
            currentQueue.push(matchData);

            this.setState({frameQueue: currentQueue});
            
        }
        //this.setState({matchData: JSON.stringify(matchData)});
        // update all objectts
        //console.log("matchDataUpdate");
    }

    async processFrameQueue() {

        // loop thru frameQueue
        /*
        for (let i = 0; i < this.state.frameQueue.length; i++) {
            let currentFrame = this.state.frameQueue[i];
            //await this.processFrame(currentFrame);
        }
        */

        // done processing, start taking current state
        this.setState({sceneLoaded: true});

    }

    async avatarLoadFinish(object, userData) {
        object.name = userData.userId;
        object.scale.set(0.01, 0.01, 0.01);

        //let heightOffset = 1.7769486999511719;
        let heightOffset = 1.7;

        object.traverse(function (child) {
            if (child.isMesh) {
                child.castShadow = true;
                child.receiveShadow = true;
            }
        });

        // Add Name above
        const canvas = this.makeLabelCanvas(1200, 64, userData.avatarName);
        const texture = new THREE.CanvasTexture(canvas);
        texture.minFilter = THREE.LinearFilter;
        texture.wrapS = THREE.ClampToEdgeWrapping;
        texture.wrapT = THREE.ClampToEdgeWrapping;

        const labelMaterial = new THREE.SpriteMaterial({
            map: texture,
            transparent: true,
        });

        const label = new THREE.Sprite(labelMaterial);
        label.name = "Avatar Label";
        object.add(label);

        const labelBaseScale = .25;
        label.scale.x = canvas.width  * labelBaseScale;
        label.scale.y = canvas.height * labelBaseScale;
        
        label.position.set(0, 190, 0);
        
        
        // add to scene  
        if (this.state.isAR === true) {
            scene.add(object);
        } else {
            scene.getObjectByName(process.env.REACT_APP_TAGNAME).add(object);
        }

        // Move and rotate
        if (this.state.isAR === true) {
            object.position.set(-userData.position.x, userData.position.y - heightOffset, userData.position.z);
        } else {
            object.position.set(userData.position.x, userData.position.y - heightOffset, -userData.position.z);
        }
    }

    makeLabelCanvas(baseWidth, size, name) {
        const ctx = document.createElement('canvas').getContext('2d');
        const font =  `${size}px Source Sans Pro`;
        ctx.font = font;
        // measure how long the name will be
        const textWidth = ctx.measureText(name).width;
    
        const width = baseWidth;
        const height = size;

        ctx.canvas.width = width;
        ctx.canvas.height = height;
    
        // need to set font again after resizing canvas
        ctx.font = font;
        ctx.textBaseline = 'middle';
        ctx.textAlign = 'center';
    
        // scale to fit but don't stretch
        const scaleFactor = Math.min(1, baseWidth / textWidth);
        ctx.translate(width / 2, height / 2);
        ctx.scale(scaleFactor, 1);
        ctx.fillStyle = 'white';
        ctx.fillText(name, 0, 0);
    
        return ctx.canvas;
      }


    async updateMetrics() {

        if (this.state.matchId) {
            let metrics = await filesClient.getSessionMetrics(this.state.sessionId);

            if (metrics.length > 0) {
                this.setState({metrics: metrics});
            }
            
            metricTimeout = setTimeout(this.updateMetrics, metricUpdateTime);
        }
        
        
    }

    async processFrame(matchData) {
        let allEntities = this.state.entities;
        let toLoad = this.state.loadedStateEntities;

        // users
        let sessionUsersExist = this.state.sessionUsers;
        for(let u = 0; u < sessionUsersExist.length; u++) {
            sessionUsersExist[u].valid = false;
        }

        if (matchData && matchData.users && matchData.users.length > 0) {
            for(let u = 0; u < matchData.users.length; u++) {
                let currentUser = matchData.users[u];
                let currentObj = scene.getObjectByName(currentUser.userId);

                
                let existingIndex = sessionUsersExist.findIndex(x => x.userId === currentUser.userId);
                
                if (existingIndex > -1){
                    sessionUsersExist[existingIndex].valid = true;
                }

                // Check if already exists
                if (currentObj) {
                    
                    let heightOffset = 1.7;
                    
                    // Move / rotate
                    if (this.state.isAR === true) {
                        currentObj.position.set(-currentUser.position.x, currentUser.position.y - heightOffset, currentUser.position.z);

                        let objQ = new THREE.Quaternion(currentUser.rotation.x, currentUser.rotation.y, currentUser.rotation.z, currentUser.rotation.w);
                        var rotation = new THREE.Euler().setFromQuaternion(objQ, "ZXY");
                        rotation.z = 0;
                        rotation.x = 0;
                        rotation.y *= -1;
                        currentObj.rotation.copy(rotation);
                        currentObj.rotateY(THREE.Math.degToRad(180));

                    } else {
                        currentObj.position.set(currentUser.position.x, currentUser.position.y - heightOffset, -currentUser.position.z);
                        
                        let objQ = new THREE.Quaternion(currentUser.rotation.x, currentUser.rotation.y, currentUser.rotation.z, currentUser.rotation.w);
                        var rotation = new THREE.Euler().setFromQuaternion(objQ, "ZXY");
                        rotation.z = 0;
                        rotation.x = 0;
                        rotation.y *= -1;
                        currentObj.rotation.copy(rotation);
                        currentObj.rotateY(THREE.Math.degToRad(180));
                    }
                } else if (existingIndex === -1) {
                    console.log("Add User");
                    console.log(currentUser);

                    currentUser.valid = true;
                    
                    // Add user
                    sessionUsersExist.push(currentUser);
                    this.setState({sessionUsers: sessionUsersExist});

                    console.log(sessionUsersExist);

                    // Get name by userId
                    let avatarName = defaultUserName;
                    if (currentUser.userId) {
                        let userInfo = await filesClient.getUserById(currentUser.userId);
                        if (userInfo && userInfo.isSuccess === true && userInfo.user) {
                            avatarName = userInfo.user.firstName + " " + userInfo.user.lastName;
                        }
                    }

                    currentUser.avatarName = avatarName;

                    // Add and setDefualt Position
                    assetLoader.loadFBXLocalAsync("/objects/avatar.fbx", this.avatarLoadFinish, currentUser);
                }
                

            }

        }

        // Remove users if no longer in frame
        let updateUsers = false;
        for(let u = 0; u < sessionUsersExist.length; u++) {
            if (sessionUsersExist[u].valid === false) {
                console.log("delete user");
                // remove
                let currentObjDel = scene.getObjectByName(sessionUsersExist[u].userId);
                if (currentObjDel) {
                    scene.getObjectByName(process.env.REACT_APP_TAGNAME).remove(currentObjDel);
                }

                sessionUsersExist.splice(u, 1);
                u--;

                updateUsers = true;
            }
        }

        if (updateUsers === true) {
            this.setState({sessionUsers: sessionUsersExist});
        }

        // Entities
        if (matchData && matchData.entities.length > 0) {
            
            if (updateTick === true) {
                
                //this.setState({sessionTime: new Date(Math.round(matchData.tick/process.env.REACT_APP_FPS) * 1000).toISOString().substr(11, 8)});
                // sessionTime
                // 
                sessionTime = new Date(Math.round(matchData.tick/process.env.REACT_APP_FPS) * 1000).toISOString().substr(11, 8);
                
                //this.setState({sessionTick: matchData.tick});
                sessionTick = matchData.tick;

                
                // Calc progress
                let replayPer = 0;
                replayPer = Math.round((matchData.tick / this.state.sessionFrameCount) * 100);

                if (replayPer > 100) {
                    replayPer = 100;
                }

                if (replayPer < 0) {
                    replayPer = 0;
                }

                // update progress bar
                if ((Date.now() - lastProgessUpdate) > lastProgressUpdateOffset) {
                    //this.setState({scrubValue: replayPer});
                    scrubValue = replayPer;
                }
                
            }
            
            // Check show value
            if (this.state.showScene !== matchData.show || scene.visible !== matchData.show) {
                this.setState({showScene: matchData.show});

                if (this.state.liteMode === false && this.state.isReplay === false) {
                    // hide whole scene
                    scene.visible = matchData.show;
                }
                
            }

            // Check play value
            if (this.state.playScene !== matchData.play) {
                this.setState({playScene: matchData.play});
            }

            if (matchData.reset === true) {
                this.setState({sceneLoaded: false});
                
                if (this.state.sessionId) {
                    this.loadSession(this.state.sessionId);
                }


            } else if (matchData.reset === false) {

                // Check for Hide all

                for(let e = 0; e < matchData.entities.length; e++) {
                    let currentEntity = matchData.entities[e];

                    let processFrame = true;

                    // Check if item is being edited
                    if (this.state.selectedEntity && this.state.selectedEntity.id === currentEntity.entityId && (Date.now() - lastEntityUpdate) < lastEntityUpdateOffset) {
                        processFrame = false;
                    }

                    //check if frame same as last frame
                    //processFrame = false;
                    
                    if (processFrame === true) {
                        
                        let currentObj = scene.getObjectByName(currentEntity.entityId);
                        
                        // disableFrameUpdate
                        
                        var assetToUpdate = allEntities.filter(obj => {
                            return obj.id === currentEntity.entityId
                        });

                        if (currentObj) {  

                            // Show
                            if (currentEntity.hide === false && currentObj.visible === false) {
                                currentObj.visible = true;
                            }

                            // Hide
                            if (currentEntity.hide === true && currentObj.visible === true) {
                                currentObj.visible = false;
                            }
                            
                            
                            if (this.state.isAR === true) {
                                currentObj.position.set(-currentEntity.position.x, currentEntity.position.y, currentEntity.position.z);
                                currentObj.quaternion.set(-currentEntity.rotation.x, -currentEntity.rotation.y, currentEntity.rotation.z, currentEntity.rotation.w);
                                currentObj.rotateY(THREE.Math.degToRad(180));

                            } else {
                                currentObj.position.set(currentEntity.position.x, currentEntity.position.y, -currentEntity.position.z);
                                currentObj.quaternion.set(-currentEntity.rotation.x, -currentEntity.rotation.y, currentEntity.rotation.z, currentEntity.rotation.w);
                            }

                            
                            if (assetToUpdate && assetToUpdate[0].class === "trigger") {
                                currentObj.scale.set(currentEntity.scale.x*assetToUpdate[0].pwaScaleMultiplier, currentEntity.scale.y*assetToUpdate[0].pwaScaleMultiplier, currentEntity.scale.z*assetToUpdate[0].pwaScaleMultiplier);
                            } else {
                                currentObj.scale.set(currentEntity.scale.x, currentEntity.scale.y, currentEntity.scale.z);
                            }
                            

                            // Handle animations
                            if (currentEntity.animation && Object.keys(currentEntity.animation).length > 0) {
                                //console.log(matchData);

                                /*
                                    play = play
                                    stop = stop looping
                                    loop = start looping
                                */

                                if (currentEntity.animation.play === true) {
                                    var actionToUse = this.state.actions.filter(actionData => {
                                        return actionData.name === currentEntity.animation.name && actionData.id === currentEntity.entityId
                                    });

                                    if (actionToUse.length > 0) {

                                        if (this.state.activeActions[currentEntity.entityId] && this.state.activeActions[currentEntity.entityId] != actionToUse[0].action) {
                                            this.state.activeActions[currentEntity.entityId].fadeOut(fadeDuration);
                                        }

                                        this.state.activeActions[currentEntity.entityId] = actionToUse[0].action;

                                        if (currentEntity.animation.loop === true) {
                                            actionToUse[0].action.loop = THREE.LoopRepeat;
                                            actionToUse[0].action.clampWhenFinished = false;
                                        }
                                        
                                        actionToUse[0].action.reset().fadeIn(fadeDuration).play();  

                                    }
                                    
                                } else if (currentEntity.animation.stop === true) {
                                    if (this.state.activeActions[currentEntity.entityId]) {
                                        // fadeout
                                        this.state.activeActions[currentEntity.entityId].fadeOut(fadeDuration);
                                    }
                                    
                                    
                                    // {name: 'walk', speed: 0.5, loop: true, stop: true}

                                    // let actionToFadeOut = this.state.activeActions[currentEntity.entityId];
                                    //actionToFadeOut.reset().fadeOut(fadeDuration).stop();
                                }

                            }
                            
                        } else {
                            console.log("NEW ENTITY: " + currentEntity.entityId);
                            
                            var isLoading = toLoad.filter(loadData => {
                                return currentEntity.entityId === loadData.id
                            });
                            
                            if (isLoading.length === 0) {
                                
                                let newToLoad = {};
                                newToLoad.id = currentEntity.entityId;
                                this.state.loadedStateEntities.push(newToLoad);
                                // Load new entity
                                // getEntity
                                let getEntity = await filesClient.getEntity(currentEntity.entityId);
                                if (getEntity.isSuccess === true && getEntity.entity) {
                                    await this.loadObj(getEntity.entity);
                                }

                            }

                            
                        }
                    
                    }
                }
            }                
        }
  
    }

    cameraDefault(){

    }

    cameraOverhead(){

    }

    async componentDidMount() {
        await this.setProps();

        // check for ar support
        if (navigator.xr) {
            navigator.xr.isSessionSupported('immersive-ar')
            .then((isSupported) => {
                if (isSupported) {
                    this.setState({xrSupported: true});
                }
            });
        }

        // Scenario list
        this.updateScenarioList();

        // list of Libraries
        let libraryList = await filesClient.getLibrariesListFormatted();
        console.log(libraryList);

        if (libraryList[0].isSuccess === true && libraryList[0].libraries) {
            this.setState({libraries: libraryList[0].libraries});
        } else {
            this.displayPopup("Error", "Can not load API");
        }

        if (libraryList[1].isSuccess === true && libraryList[1].assets) {
            this.setState({assets: libraryList[1].assets});
        }

        // list of assets
        //let assetList = await filesClient.getAssetsList();
        
        /*
        if (assetList.isSuccess === true && assetList.assets) {
            this.setState({assets: assetList.assets});
        } else {
            // display error connectiong to files API
            this.displayPopup("Error", "Can not load API");
        }
        */

        // start scene
        if (this.state.isWeb) {
            this.startWebScene(false);
        
        } else if (this.state.isAR) {
            //this.startWebXrScene();
            // Add button to launch WebXR

        }

        if (this.state.matchActive === false && this.state.liteMode === false && this.state.sessionId) {
            this.softLoadData(this.state.sessionId);
        }
    }

    componentWillUnmount() {

        
        if (this.state.isLoaded === true) {
            if (this.state.isAR === true) {

                if (session) {
                    session.end();
                    cancelAnimationFrame(lastAnimateReq);
                }

            } else if (this.state.isWeb === true) {
                cancelAnimationFrame(lastAnimateReq);
                container.removeEventListener('mousedown', this.onWindowResize);
                orbit.removeEventListener('change', this.renderScene);
                control.removeEventListener('change', this.renderScene);
                control.removeEventListener('dragging-changed');
                window.removeEventListener('resize', this.onWindowResize);
                control.removeEventListener('objectChange');

                window.removeEventListener('keydown', this.handleKeydown);
                window.removeEventListener('keyup', this.handleKeyup);

                window.removeEventListener("click", this.onMouseDown);

                clearTimeout(metricTimeout);
            }

            if (this.state.matchActive === true) {
                this.closeMatch();
            }
        }
        

        
    }

    render() {

        const objectSelect = this.state.selected;
        let modeBtns;
        if (objectSelect !== "" && this.state.isBuilder === true) {
        modeBtns = <div class="builder_object_left1">
            <a onClick={this.setMovement} title="Move Tool"><img alt="Move Tool" src="/images/builder_objecticon1.png" /></a>
            <a onClick={this.setRotation} title="Rotate Tool"><img alt="Rotate Tool" src="/images/builder_objecticon2.png" /></a>
            {(this.state.resizeable === true ? <a onClick={this.setScale} title="Scale Tool"><img alt="Scale Tool" src="/images/builder_objecticon3.png" /></a> : "")}
            </div>;
        } else {
        modeBtns = "";
        }

        let leftPanel;

        if (this.state.matchActive === true) {
            leftPanel = <LeftPanelSession 
            sessionUsers={this.state.sessionUsers}
            matchActive={this.state.matchActive}
            metrics={this.state.metrics}
            sessionTime={sessionTime}
            liteMode={this.state.liteMode}

            isAR={this.state.isAR}
            sessionId={this.state.sessionId}
            sessionName={this.state.sessionName}
            sessionLocation={this.state.sessionLocation}
            scenarioName={this.state.scenarioName}
            scenarioDesc={this.state.scenarioDesc}
            isReplay={this.state.isReplay}

            endSession={this.endSession}
            toggleSessionPopup={this.toggleSessionPopup}

            />
        } else if (this.state.liteMode === true) {
            
            leftPanel = <LeftPanelRun 
            loadSession={this.loadSession}
            />

        } else if (this.state.liteMode === false && this.state.sessionId) {

            leftPanel = <LeftPanelSpectate

            isAR={this.state.isAR}
            sessionId={this.state.sessionId}
            sessionName={this.state.sessionName}
            sessionLocation={this.state.sessionLocation}
            scenarioName={this.state.scenarioName}
            scenarioDesc={this.state.scenarioDesc}
            loadSession={this.loadSession}
            />
        }

      return (
        <div id="scene">
            <div>
            {this.state.playScene === false && this.state.showScene === false && this.state.liteMode === false && this.state.matchActive === true && this.state.isReplay === false ? (<>{
                <div class="scene-overlay">
                    <p>STAND BY. WAITING FOR SESSION TO BEGIN.</p>
                </div>
                } </>) : (<></>)}
                
                {this.state.playScene === false && this.state.showScene === true && this.state.liteMode === false && this.state.matchActive === true && this.state.isReplay === false ? (<> {
                <div class="scene-overlay">
                    <p>Stand by. Facilitator has paused session.</p>
                </div>
                } </>) : (<></>)}

                {this.state.isAR === true && this.state.isLoaded === true && this.state.isRender === false ? (<>
                <div class="spectator_content_top">
                    <div class="spectator_content_toptitel">
                        <div class="spectator_content_toptitel1"><h3>AIM AT TAG AND TAP LOCK POSITION BUTTON TO POSITION YOUR SCENE.</h3></div>
                    </div>
                    <ul class="spectator_content_topmenu">
                        <li className={`spectator_content_topbutton ${this.state.isTracked === true ? "" : "active"}`}><a href="#">TAG 1{this.state.isTracked === true ? <span>DETECTED</span> : <span>DETECTING</span>}</a></li>
                        <li class="spectator_content_topbutton"><a href="#">TAG 2</a></li>
                        <li className={`spectator_content_topcancel ${this.state.isTracked === true ? "" : "disabled"}`}><a href="#" onClick={this.startWebXR} class="button_gray"><span>Done</span></a> </li>                        
                    </ul>
                </div>
                </>) : (<></>)}
            </div>
            <div class="scene-ui-container">
              <div class="main-area">
                {leftPanel}
                <div class="toolbar" style={{'justifyContent':'space-between'}}>
                    <div class="toolbar-left builder_object_left1">
                        {modeBtns}
                    </div>
                    <div class="toolbar-middle flex-grow"></div>
                    <div class="toolbar-right">
                        <div class="toolbar-actions">
                            <ul>
                                <li className={`${this.state.isAR === true ? "active" : this.state.xrSupported === false ? "disabled" : ""}`}><a onClick={this.changeToFirstPerson} title="First Person View"><span><font><img src="/images/pwa_27icon1.png" alt="First Person View" /></font></span></a></li>
                                <li className={`${this.state.isWeb === true && this.state.overhead === false ? "active" : ""}`}><a onClick={this.changeToThirdPerson} title="Perspective View"><span><font><img src="/images/pwa_27icon2.png" alt="Perspective View" /></font></span></a></li>
                                <li className={`${this.state.isWeb === true && this.state.overhead === true ? "active" : ""}`}><a onClick={this.cameraPositionTop} title="Topdown View"><span><font><img src="/images/pwa_27icon3.png" alt="Topdown View" /></font></span></a></li>
                            </ul>
                        </div>
                    </div>
                </div>

                
                
                <div id="webxr"></div>
              </div>
            </div>
            <div id="sceneContainer">
                <div id="webXrStats"></div>

                <div class="main-scene"> 
                    <div class="spectator_content_right">

                    {this.state.isAR === false && this.state.isBuilder === true && this.state.isReplay === false && (this.state.liteMode === true && this.state.matchActive === true) ? (<>
                    {
                        <div class="pwa12review_sessionbottom1">
                            <div class="pwa12review_sessionwidth">
                                <div class="pwa12review_playcontrols">
                                    <ul class="pwa12review_playcontrolsul">
                                        <li class="pwa12_playcontrols1"><a onClick={this.toggleStartScene}>{this.state.playScene === true ? "Pause" : "Play"}</a></li>
                                        <li class="pwa12_playcontrols2"><a onClick={this.toggleShowScene}>{this.state.showScene === true ? "Hide" : "Show"}</a></li>
                                        <li class="pwa12_playcontrols3"><a onClick={this.toggleResetPopup}>Reset</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    }
                    </>) : (<></>)}

                    {this.state.isAR === false && this.state.isReplay === true && this.state.matchActive === true ? (<>
                    {
                        <div class="pwa12review_sessionbottom1">
                            <div class="pwa12review_sessionwidth">
                                
                                <div className="scrubber-container"  style={{ height:  '20px' }}>
                                    <Scrubber
                                        min={0}
                                        max={100}
                                        value={scrubValue}
                                        onScrubStart={this.handleScrubStart}
                                        onScrubEnd={this.handleScrubEnd}
                                        onScrubChange={this.handleScrubChange}
                                    />
                                </div>
                                <div>
                                    {sessionTime}
                                </div>

                                <div class="pwa12review_playcontrols">
                                    <ul class="pwa12review_playcontrolsul">
                                        <li class="pwa12_playcontrols1"><a onClick={this.playSession}>Play</a></li>
                                        <li class="pwa12_playcontrols1"><a onClick={this.pauseSession}>Pause</a></li>
                                        <li class="pwa12_playcontrols3"><a onClick={this.resetSession}>Reset</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    }
                    </>) : (<></>)}

                        

                    </div>
                </div>

                {this.state.isAR === false && this.state.isBuilder === true && (this.state.liteMode === true && this.state.matchActive === true) ? (<>
                    {
                    <div>
                        <div class="col-md-3 p-0 sideMenu" id="objectSideMenu" style={{display: this.state.openFiles === true ? 'block' : 'none' }}>
                            <div class="left_sidebar_border left_sidebar_builderobject">                                    
                                <div class="sidebar_panel_titel">
                                    <h2><span>OBJECT LIBRARY</span></h2>
                                    <a className="sideMenuClose" onClick={this.closeFiles}></a>
                                </div>
                                <div class="sidebar_pwa08run_padding"> 
                                    <Accordion allowMultipleExpanded allowZeroExpanded>
                                    {this.state.libraries.map(library => (
                                    <AccordionItem>
                                        <AccordionItemHeading>
                                                <AccordionItemButton>
                                                {library.name}
                                                </AccordionItemButton>
                                        </AccordionItemHeading>
                                        <AccordionItemPanel>
                                        {this.state.assets.filter(assetCheck => assetCheck.fileType === 1 && assetCheck.fileClassification === 1 && objectsCheck.includes(assetCheck.classification) && assetCheck.libraryId === library.id).map(asset => (
                                        <div onClick={() => this.addObj(asset)} class="pointer">
                                            <img src="/images/object_icon.png" alt="" />
                                            {asset.name}
                                        </div>
                                        ))}
                                        </AccordionItemPanel>
                                    </AccordionItem>
                                    ))}
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 p-0 sideMenu" id="logicSideMenu" style={{display: this.state.logic === true ? 'block' : 'none' }}>
                            <div class="left_sidebar_border left_sidebar_builderobject">
                                    
                            <div class="sidebar_panel_titel">
                                <h2><span>LOGIC LIBRARY</span></h2>
                                <a className="sideMenuClose" onClick={this.closeLogic}></a>
                            </div>

                            <div class="sidebar_pwa08run_padding"> 
                                    {this.state.assets.filter(assetCheck => assetCheck.fileType === 1 && logicCheck.includes(assetCheck.classification)).map(asset => (
                                        <div onClick={() => this.addObj(asset)} class="pointer">
                                        <img src="/images/object_icon.png" alt="" />
                                        {asset.name}
                                        </div>
                                    ))}
                            </div>
                        </div>
                        </div>
                        {this.state.liteMode === true ? (<>
                            {
                        <ObjectPanel
                            
                            dataCallBack={this.dataUpdate}
                            selectObject={this.selectObject}
                            toggleHide={this.toggleHide}
                            
                            scenarioName={this.state.scenarioName}
                            
                            selectedEntity={this.state.selectedEntity}
                            entities={this.state.entities}
                            assets={this.state.assets}
                            capacity={this.state.capacity}
                            selected={this.state.selected}
                            modalFilesToggle={this.modalFilesToggle}
                            logicToggle={this.logicToggle}
                            
                            updateEntity={this.updateEntity}
                            scenarioDesc={this.state.scenarioDesc}
                            liteMode={this.state.liteMode} 

                            addMesh={this.addMesh}
                            miniMenu={true}
                            handleSpatialMapToggle={this.handleSpatialMapToggle}
                            updateScenarioDesc={this.updateScenarioDesc}
                            updateScenarioName={this.updateScenarioName}
                            isSpatialMapActive={this.state.isSpatialMapActive}
                            spatial={this.state.spatial}
                            modalThemeToggle={this.modalThemeToggle}
                        />
                            }
                        </>) : (<></>)}
                    </div>

                    }
                    </>) : (<></>)}
                

                <Popup open={this.state.openPopup} modal closeOnDocumentClick={false} lockScroll={true} closeOnEscape={false}>
                <div class="fancybox_popup builderWideModal">  
                    <h3>{this.state.popupTitle}</h3>
                    {(this.state.allowClosePopup === true ? <a className="popup_close" onClick={this.closePopup}></a> : "")}
                    <div class="builder30left_popup">
                    {this.state.popupMsg}
                    </div>
                </div>
                </Popup>

                <Popup open={this.state.openPopupWebXr} modal closeOnDocumentClick={false} lockScroll={true} closeOnEscape={false}>
                <div class="fancybox_popup builderWideModal">  
                    <h3>Start WebXR</h3>
                    <div class="builder30left_popup">
                        <button onClick={this.startWebXrScene} id="webXrBtn">Start WebXr</button>
                    </div>
                </div>
                </Popup>

                <Popup open={this.state.endSessionPopup} modal closeOnDocumentClick={false} lockScroll={true} closeOnEscape={false}>
                    <div id="settings-edit-scenario" class="fancybox_popup">
                        <h3>END SESSION</h3>
                        <a className="popup_close" onClick={this.closeSessionPopup}></a>

                        <div class="builder05left_popup">
                            <div class="row m-0">
                                <div class="col-md-12 p-0">
                                    <div class="builder05contnet_popup">
                                        <h4>WOULD YOU LIKE TO END YOUR SESSION?</h4>
                                        <div>
                                            <a onClick={this.endSession} class="button_blue"><span>END SESSION</span></a>
                                            <a onClick={this.toggleSessionNewPopup} class="button_gray"><span>SAVE CHANGES</span></a>
                                            <a onClick={this.closeSessionPopup} class="button_gray"><span>CANCEL</span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Popup>

                <Popup open={this.state.saveSessionAsNewPopup} modal closeOnDocumentClick={false} lockScroll={true} closeOnEscape={false}>
                    <div id="settings-edit-scenario" class="fancybox_popup">
                        <h3>SAVE CHANGES AS A NEW SCENARIO</h3>
                        <a className="popup_close" onClick={this.closeSessionNewPopup}></a>

                        <div class="builder05left_popup">
                            <div class="row m-0">
                                <div class="col-md-12 p-0">
                                    <div class="builder05contnet_popup">
                                        <h4>ENTER NAME OF NEW SCENARIO</h4>
                                        <input type="text" value={this.state.scenarioSaveAs} onChange={this.handleSaveAsUpdate}/>

                                        {!this.state.scenarioSaveAsValid && <div className="error">{this.state.saveAsError}</div>}
                                        <div>
                                            <a href="#" onClick={this.saveAs} className={this.state.scenarioSaveAsValid === true ? 'button_blue' : 'button_gray disabled'}><span>OK</span></a>
                                            <a href="#" onClick={this.closeSessionNewPopup} className="button_gray"><span>Cancel</span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Popup>

                <Popup open={this.state.showAARPopup} modal closeOnDocumentClick={false} lockScroll={true} closeOnEscape={false}>
                    <div id="settings-edit-scenario" class="fancybox_popup">
                        <h3>SESSION ENDED</h3>
                        <a className="popup_close" onClick={this.closeAARPopup}></a>

                        <div class="builder05left_popup">
                            <div class="row m-0">
                                <div class="col-md-12 p-0">
                                    <div class="builder05contnet_popup">
                                        <h4>YOUR SESSION HAS ENDED</h4>
                                        <h2>Would you like to start an After Action Review?</h2>
                                        <div>
                                            <a onClick={this.closeAARPopupReplay} class="button_blue"><span>YES</span></a>
                                            <a onClick={this.closeAARPopup} class="button_gray"><span>NO</span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Popup>

                <Popup open={this.state.resetPopup} modal closeOnDocumentClick={false} lockScroll={true} closeOnEscape={false}>
                    <div id="settings-edit-scenario" class="fancybox_popup">
                        <h3>Reset Scenario</h3>
                        <a className="popup_close" onClick={this.closeResetPopup}></a>

                        <div class="builder05left_popup">
                            <div class="row m-0">
                                <div class="col-md-12 p-0">
                                    <div class="builder05contnet_popup">
                                        <h4>WOULD YOU LIKE TO RESET THE SCENARIO TO ITS SAVED STATE?</h4>

                                        <div>
                                            <a onClick={this.resetScene} class="button_blue"><span>RESET SCENARIO</span></a>
                                            <a onClick={this.toggleSessionNewPopup} className="button_gray"><span>SAVE SCENARIO</span></a>
                                            <a onClick={this.closeResetPopup} className="button_gray"><span>CANCEL</span></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Popup>

                <Popup open={this.state.openError} modal>
                    <div class="fancybox_popup builderWideModal">  
                        <h3>Error</h3>
                        <a className="popup_close" onClick={this.closeError}></a>
                        <div class="builder30left_popup">
                            {this.state.error.map(error => (
                                <p>{error}</p>
                            ))}
                            <ul class="fancybox_endsession1">
                                <li><a href="#" onClick={this.closeError} class="button_blue"><span>OK</span></a></li>
                            </ul>
                        </div>
                    </div>
                </Popup>

            </div>
        </div>
      );

    }
}

export default ThreeDScene;
