import React, { Component } from "react";
import ThreeDScene from '../../webxr/Scene';
class WEB extends Component {

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
  }

  render() {

    return (
        <div>
            <ThreeDScene xscenarioId={this.props.toloadId} sessionId={this.props.toloadId} mode="WEB" />
        </div>
        
    );
  }

}

export default WEB;