import React, { Component } from "react";
import Header from "../../helpers/Header";
import Popup from 'reactjs-popup';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import TabHeader from "../../components/TabHeader";

import { v4 as uuidv4 } from 'uuid';

import * as THREE from 'three';
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import { WEBGL } from 'three/examples/jsm/WebGL';
/* import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer'; */

import Auth from "../../utility/auth";

import ObjectPanel from "../../webxr/ObjectPanel";

import ConsoleEvents from "../../utility/ConsoleEvents";
import Loader from '../../webxr/Loader';
import filesAPI from '../../utility/files';

import Stats from 'three/examples/jsm/libs/stats.module';

const authCheck = new Auth();
const assetLoader = new Loader();
const consoleClient = new ConsoleEvents();
const filesClient = new filesAPI();

let container;
let cameraPersp, currentCamera;
let scene, renderer, control, orbit, labelRenderer;
let selectedObj;

let ctrlKey;
let lastKeyPress = Date.now();

const stats = Stats();

const objectsCheck = [0, 4, 5];
const logicCheck = [1, 2, 3];

// raycast
const raycaster = new THREE.Raycaster();
//raycaster.layers.set(1);

const pointer = new THREE.Vector2();

// Display Text for sign
const defaultDisplayText = "Sample Text\n2nd Line Sample\n3rd Line Sample";

// Watch entity changes
let lastEntityUpdate = Date.now();
const lastEntityUpdateOffset = 500;

// History Queue
let historyQueueLimit = 64;
class Builder_Component extends Component {

  constructor(props) {
    super(props);

    this.state = this.getInitialState();

    this.closeModalPref = this.closeModalPref.bind(this);
    this.closeFiles = this.closeFiles.bind(this);
    this.modalFilesToggle = this.modalFilesToggle.bind(this);
    this.handleSpatialMapToggle = this.handleSpatialMapToggle.bind(this);
    this.closeSpatialMap = this.closeSpatialMap.bind(this);
    this.modalPrefToggle = this.modalPrefToggle.bind(this);

    this.startScene = this.startScene.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);
    this.addObj = this.addObj.bind(this);
    this.addMesh = this.addMesh.bind(this);
    this.renderScene = this.renderScene.bind(this);

    this.selectObject = this.selectObject.bind(this);
    this.addEntity = this.addEntity.bind(this);
    this.updateEntity = this.updateEntity.bind(this);
    this.buildSign = this.buildSign.bind(this);

    this.setMesh = this.setMesh.bind(this);
    this.setMovement = this.setMovement.bind(this);
    this.setRotation = this.setRotation.bind(this);
    this.setScale = this.setScale.bind(this);

    this.cutObj = this.cutObj.bind(this);
    this.deleteObj = this.deleteObj.bind(this);
    this.copyObj = this.copyObj.bind(this);
    this.pasteObj = this.pasteObj.bind(this);

    this.clipObj = this.clipObj.bind(this);
    this.deleteObjFromScene = this.deleteObjFromScene.bind(this);

    this.handleKeydown = this.handleKeydown.bind(this);
    this.handleKeyup = this.handleKeyup.bind(this);

    this.toggleControls = this.toggleControls.bind(this);
    this.saveScenario = this.saveScenario.bind(this);
    this.updateScenarioName = this.updateScenarioName.bind(this);
    this.updateScenarioDesc = this.updateScenarioDesc.bind(this);

    this.loadScenario = this.loadScenario.bind(this);
    this.modalScenarioToggle = this.modalScenarioToggle.bind(this);
    this.closeModalScenarioList = this.closeModalScenarioList.bind(this);

    this.modalSaveConfirmToggle = this.modalSaveConfirmToggle.bind(this);
    this.closeSaveConfirm = this.closeSaveConfirm.bind(this);

    this.modalThemeToggle = this.modalThemeToggle.bind(this);
    this.saveTheme = this.saveTheme.bind(this);
    this.closeTheme = this.closeTheme.bind(this);
    this.updateThemeTemp = this.updateThemeTemp.bind(this);

    this.closeError = this.closeError.bind(this);
    this.closeLogic = this.closeLogic.bind(this);
    this.logicToggle = this.logicToggle.bind(this);

    this.clearScenario = this.clearScenario.bind(this);
    this.closeLoading = this.closeLoading.bind(this);

    this.animate = this.animate.bind(this);

    this.handleChange = this.handleChange.bind(this);

    this.cameraDefault = this.cameraDefault.bind(this);
    this.cameraOverhead = this.cameraOverhead.bind(this);

    //raycast
    this.onMouseDown = this.onMouseDown.bind(this);

    // Save As
    this.closeSaveAs = this.closeSaveAs.bind(this);
    this.saveAs = this.saveAs.bind(this);
    this.handleSaveAsUpdate = this.handleSaveAsUpdate.bind(this);
    this.saveAsScenario = this.saveAsScenario.bind(this);

    this.revertToLastSaved = this.revertToLastSaved.bind(this);

    // Child Object panel
    this.dataUpdate = this.dataUpdate.bind(this);
    this.FBXLoadFinish = this.FBXLoadFinish.bind(this);
    this.toggleHide = this.toggleHide.bind(this);
    this.meshLoadFinish = this.meshLoadFinish.bind(this);

    // Undo / history queue
    this.addToHistoryQueue = this.addToHistoryQueue.bind(this);
    this.processHistoryQueue = this.processHistoryQueue.bind(this);
    this.updateEntityData = this.updateEntityData.bind(this);

  }

  getInitialState = () => ({
    isSpatialMapActive: false,
    open: false,
    openPref: false,
    isLoaded: false,
    openFiles: false,
    openError: false,
    logic: false,
    openScenarioList: false,
    meshLoaded: false,
    openSaveConfirm: false,
    openTheme: false,
    openLoading: false,
    resizeable: false,
    openThreeErr: false,
    overhead: false,
    openSaveAs: false,
    scenarioSaveAsValid: true,
    uploading: false,
    clipboard: {},
    selctedFile: {},
    selectedEntity: {},
    assets: [],
    libraries: [],
    entities: [],
    tag: [],
    scenarios: [],
    error: [],
    historyQueue: [],
    entitySelect: "",
    spatial: "Select a Spatial Map",
    selected: "",
    prevSelected: "",
    mesh: "",
    scenarioName: "",
    scenarioDesc: "",
    scenarioId: "",
    theme: "",
    themeTemp: "",
    loadingMsg: "",
    threeErr: "",
    selectFileName: "",
    selectFileType: "",
    scenarioSaveAs: "",
    saveAsError: "",
    scenarioSavedName: "",
    triCount: 0,
    capacity: 0
  });

  dataUpdate(data) {

    /*
    if (data.isLoaded) {
        this.setState({isLoaded: data.isLoaded});
    }
    */
  }

  handleChange(e) {
    console.log(e);
    console.log(e.target.files);
    const file = e.target.files[0];
    let full = file.name.split('.');
    let name = full[0];
    let ext = full[full.length - 1];
    //f = full.join('.').replace(new RegExp('.' + ext + '$'), '');

    /*
    let full = file.name.split('.'), ext = full[full.length - 1],
        f = full.join('.').replace(new RegExp('.' + ext + '$'), '');
    */
    this.setState({ selctedFile: file, selectFileName: name, selectFileType: ext });
  }

  closeModalPref() {
    this.setState({ openPref: false })
  }

  closeFiles() {
    this.setState({ openFiles: false })
  }

  handleSpatialMapToggle = () => {
    this.setState({ isSpatialMapActive: !this.state.isSpatialMapActive });
  };

  closeSpatialMap = () => {
    this.setState({ isSpatialMapActive: false });
  }

  modalPrefToggle = () => {
    this.setState({ openPref: !this.state.openPref });
  };

  modalFilesToggle = () => {
    this.setState({ openFiles: !this.state.openFiles });
  };

  setMovement() {
    control.setMode('translate');
  }

  setRotation() {
    control.setMode('rotate');
  }

  setScale() {
    if (this.state.resizeable === true) {
      control.setMode('scale');
    }
  }

  updateScenarioName(evt) {
    let scenarioName = evt.target.value
    console.log(evt.nativeEvent, scenarioName)
    // if(evt.nativeEvent.which == 0 && scenarioName.length === 1) scenarioName = ''
    this.setState({ scenarioName });
  }

  updateScenarioDesc(e) {
    this.setState({ scenarioDesc: e.target.value || "" });
  }

  modalScenarioToggle() {
    this.setState({ openScenarioList: !this.state.openScenarioList });
  }

  closeModalScenarioList() {
    this.setState({ openScenarioList: false });
  }

  modalSaveConfirmToggle() {
    this.setState({ openSaveConfirm: !this.state.openSaveConfirm });
  }

  closeSaveConfirm() {
    this.setState({ openSaveConfirm: false });
  }

  modalThemeToggle() {
    this.setState({ openTheme: !this.state.openTheme });
  }

  closeTheme() {
    this.setState({ openTheme: false });
  }

  closeLoading() {
    this.setState({ openLoading: false });
    this.setState({ loadingMsg: "" });
  }

  updateThemeTemp(evt) {
    this.setState({ themeTemp: evt.target.value });
  }

  saveTheme() {
    this.setState({ theme: this.state.themeTemp });
    this.setState({ openTheme: false });
  }

  closeError() {
    this.setState({ openError: false });
  }

  logicToggle() {
    this.setState({ logic: !this.state.logic });
  }

  closeLogic() {
    this.setState({ logic: false });
  }

  closeSaveAs() {
    this.setState({ openSaveAs: false });
  }

  saveAs() {
    // Check if existing scenario
    let currentScenarios = this.state.scenarios;

    if (this.state.scenarioSaveAs === "") {
      //
      this.setState({ scenarioSaveAsValid: false });
      this.setState({ saveAsError: "Missing Scenario Name" });
    } else {
      var scenarioCheck = currentScenarios.filter(scenario => {
        return scenario.title.toLowerCase() === this.state.scenarioSaveAs.toLowerCase();
      });

      if (scenarioCheck.length === 0) {
        this.setState({ openSaveAs: false });
        this.saveScenario(true);
      } else {
        this.setState({ scenarioSaveAsValid: false });
        this.setState({ saveAsError: "Scenario with that name already exists" });
      }
    }



  }

  saveAsScenario() {
    this.setState({ openSaveAs: true });
  }

  handleSaveAsUpdate(e) {
    this.setState({ scenarioSaveAsValid: true, scenarioSaveAs: e.target.value });
  }

  revertToLastSaved() {

    if (this.state.scenarioId) {
      this.loadScenario(this.state.scenarioId);
    }

  }

  async addToHistoryQueue() {
    console.log("Add to Queue");
    let historyQueue = this.state.historyQueue;
    if (historyQueue.length > 63) { 
      historyQueue.shift();
    }

    // testing historyQueue.push(uuidv4());

    let allEntities = this.state.entities;

    //let newQueue = historyQueue.concat([allEntities]);
    let entityCopy = JSON.parse(JSON.stringify(allEntities));
    historyQueue.push(entityCopy);

    //let newQueue = historyQueue.slice();

    this.setState({historyQueue: historyQueue});

    console.log(historyQueue);
  }

  async processHistoryQueue() {
    console.log("Undo NOW!");

    // Remove controls
    control.detach();
    scene.remove(control);

    // Deselect
    this.setState({selected: ""});
    this.setState({selectedEntity: {}});

    // gather data
    let historyQueue = this.state.historyQueue;
    let allEntities = this.state.entities;

    if (historyQueue.length > 1) { 

      let currentState = historyQueue[historyQueue.length-2];

      // update all entities and find missing
      for(let u = 0; u < currentState.length; u++) {
        let currentObj = scene.getObjectByName(currentState[u].id);
        // check if exist in current entity

        // find in scene
        if (currentObj) {

          // if no mesh, update data
          if (currentState[u].type !== "mesh") {

            // visible fix
            currentObj.visible = currentState[u].visible;
            
            // Set Transforms/attr
            currentObj.scale.set(currentState[u].scale.x, currentState[u].scale.y, currentState[u].scale.z);
  
            let posX = Number(currentState[u].position.x);
            let posY = Number(currentState[u].position.y);
            let posZ = Number(currentState[u].position.z);

            currentObj.position.set(posX, posY, posZ);

            let rotationX = Number(currentState[u].rotation.x);
            let rotationY = Number(currentState[u].rotation.y);
            let rotationZ = Number(currentState[u].rotation.z);

            currentObj.rotation.set(rotationX, rotationY, rotationZ);

          }

          // If sign, update text
          if (currentState[u].displayText != "") {
            this.buildSign(currentState[u].id, currentState[u].displayText);
          }

        } else {

          let clipboard = {position: {}, scale: {}, rotation: {}};
          clipboard.position.x = currentState[u].position.x;
          clipboard.position.y = currentState[u].position.y;
          clipboard.position.z = currentState[u].position.z;

          clipboard.rotation.x = currentState[u].rotation.x;
          clipboard.rotation.y = currentState[u].rotation.y;
          clipboard.rotation.z = currentState[u].rotation.z;

          clipboard.scale.x = currentState[u].scale.x;
          clipboard.scale.y = currentState[u].scale.y;
          clipboard.scale.z = currentState[u].scale.z;

          clipboard.filePath = currentState[u].url;
          clipboard.name = currentState[u].assetName;
          clipboard.fileId = currentState[u].fileId;
          clipboard.class = currentState[u].class;
          clipboard.id = currentState[u].assetId;

          clipboard.pwaScaleMultiplier = currentState[u].pwaScaleMultiplier;
          clipboard.animations = currentState[u].animations;
          clipboard.defaultAnimation = currentState[u].defaultAnimation;
          clipboard.classification = currentState[u].classification;
          clipboard.displayText = currentState[u].displayText;

          // Add obj
          await this.addObj(clipboard, currentState[u].id, currentState[u].name, false);

        }

      }


      // Find Delete obj
      for(let e = 0; e < allEntities.length; e++) {
        // if not exist in current e
        let toDelete = currentState.findIndex(x => x.id === allEntities[e].id);
        if (toDelete === -1){
          this.deleteObjFromScene(allEntities[e].id, false);
        }

        // currentState
      }

      // remove last item in queue
      historyQueue.pop();

      this.setState({historyQueue: historyQueue});

      // Update entity data
      let entityCopy = JSON.parse(JSON.stringify(currentState));
      console.log(entityCopy);
      console.log(allEntities);
      this.setState({entities: entityCopy});

      console.log(historyQueue);
    }

  }

  async updateEntityData(entityData) {
    let allEntities = this.state.entities;

    if (entityData.name) {

      let existingIndex = allEntities.findIndex(x => x.id === entityData.name);

      if (existingIndex > -1){

        allEntities[existingIndex].position = {x: Number(entityData.position.x),y: Number(entityData.position.y),z: Number(entityData.position.z)};
        allEntities[existingIndex].rotation = {x: Number(entityData.rotation._x),y: Number(entityData.rotation._y),z: Number(entityData.rotation._z)};
        allEntities[existingIndex].scale = {x: Number(entityData.scale.x),y: Number(entityData.scale.y),z: Number(entityData.scale.z)};

        this.setState({entities: allEntities});

        this.addToHistoryQueue();
      }

    }
    
  }

  deleteObjFromScene(objectId, addToQueue=true) {
    let deleted = false;
    let allEntities = this.state.entities;
    this.state.entities.forEach((entity, i) => {
      if (entity.type !== "mesh") {
        if (objectId === entity.id) {
          // Remove from scene
          let removeObj = scene.getObjectByName(objectId);

          scene.getObjectByName(process.env.REACT_APP_TAGNAME).remove(removeObj);

          // delete from state
          allEntities.splice(i, 1);

          deleted = true;
        }
      }
    });

    this.setState({ entities: allEntities });
    if (deleted === true) {
      this.setState({ selected: "" });
      this.setState({ selectedEntity: {} });
    }

    if (addToQueue === true) {
      // add to history queue
      this.addToHistoryQueue();
    }

    if (addToQueue === true) {
      // add to history queue
      this.addToHistoryQueue();
    }
    

  }

  clipObj() {
    let selectedObj = scene.getObjectByName(this.state.selected);

    this.state.entities.forEach((entity) => {
      if (entity.type !== "mesh") {
        if (this.state.selected === entity.id) {
          let clipboard = { position: {}, scale: {}, rotation: {} };
          clipboard.position.x = selectedObj.position.x;
          clipboard.position.y = selectedObj.position.y;
          clipboard.position.z = selectedObj.position.z;

          clipboard.rotation.x = selectedObj.rotation.x;
          clipboard.rotation.y = selectedObj.rotation.y;
          clipboard.rotation.z = selectedObj.rotation.z;

          clipboard.scale.x = selectedObj.scale.x;
          clipboard.scale.y = selectedObj.scale.y;
          clipboard.scale.z = selectedObj.scale.z;

          clipboard.filePath = entity.url;
          clipboard.name = entity.assetName;
          clipboard.fileId = entity.fileId;
          clipboard.class = entity.class;
          clipboard.id = entity.assetId;

          clipboard.pwaScaleMultiplier = entity.pwaScaleMultiplier;
          clipboard.animations = entity.animations;
          clipboard.defaultAnimation = entity.defaultAnimation;
          clipboard.classification = entity.classification;
          clipboard.displayText = entity.displayText;

          this.setState({ clipboard: clipboard });
        }
      }
    });

  }

  cutObj() {
    // Remove controls
    control.detach();
    scene.remove(control);

    // Save to clipboard
    this.clipObj();
    // remove from scene
    this.deleteObjFromScene(this.state.selected);

  }

  deleteObj() {
    // Remove controls
    control.detach();
    scene.remove(control);

    this.deleteObjFromScene(this.state.selected);
  }

  copyObj() {
    // Save to clipboard
    this.clipObj();

  }

  pasteObj() {
    if (Object.keys(this.state.clipboard).length !== 0) {
      this.addObj(this.state.clipboard);
    }
  }

  toggleHide(entity) {

    let toToggle = scene.getObjectByName(entity.id);

    if (entity.visible === true) {
      entity.visible = false;

      // Hide in scene
      toToggle.visible = false;

      // Send to Remote server & check if in match

    } else if (entity.visible === false) {
      entity.visible = true;

      // Hide in scene
      toToggle.visible = true;

      // Send to Remote server & check if in match

    }

    // update entity data
    let updatedEntites = [];
    let allEntities = this.state.entities;

    allEntities.forEach((entityExist) => {
      if (entity.id === entityExist.id) {
        entityExist.visible = entity.visible;
      }
      updatedEntites.push(entityExist);
    });


    this.setState({ entities: updatedEntites});
    
    this.addToHistoryQueue();
  }

  async updateScenarioList() {
    let scenariosList = await filesClient.getScenariosList();

    if (scenariosList.isSuccess === true && scenariosList.scenarios) {
      this.setState({ scenarios: scenariosList.scenarios });
    } else {
      // display error connectiong to files API
    }
  }

  setMesh(opt) {
    this.setState({ mesh: opt });

    if (this.state.meshLoaded === true) {
      let meshSel = scene.getObjectByName("meshlines");

      if (opt === "color") {
        meshSel.visible = true;
      } else if (opt === "off") {
        meshSel.visible = false;
      }
    }

  };

  deSelectObject() {
    control.detach();
    scene.remove(control);

    this.setState({ selected: "" });

  }

  selectObject(objectId) {

    // Remove controls from old
    if (this.state.selected) {
      control.detach();
      scene.remove(control);
    }

    // add controls
    let sceneSelectedObj = scene.getObjectByName(objectId);
    control.attach(sceneSelectedObj);
    scene.add(control);

    // set controls to translate
    control.setMode('translate');

    this.setState({ selected: objectId });

    this.state.entities.forEach((entity) => {
      if (entity.type !== "mesh") {

        if (objectId === entity.id) {
          // Save copy of Object data
          let newSelectEntityData = {};
          newSelectEntityData.id = entity.id;
          newSelectEntityData.position = sceneSelectedObj.position;
          newSelectEntityData.rotation = sceneSelectedObj.rotation;
          newSelectEntityData.scale = sceneSelectedObj.scale;
          newSelectEntityData.name = entity.name;
          newSelectEntityData.defaultAnimation = entity.defaultAnimation;
          newSelectEntityData.animations = entity.animations;
          newSelectEntityData.classification = entity.classification;
          newSelectEntityData.displayText = entity.displayText;
          newSelectEntityData.class = entity.class;

          this.setState({ selectedEntity: newSelectEntityData });

          if (entity.class === "waypoint") {
            this.setState({ resizeable: false });
          } else {
            this.setState({ resizeable: true });
          }

        }
      } else {

        this.setState({ resizeable: false });

        if (objectId === entity.id) {
          this.setState({ selectedEntity: {} });
        }

      }
    });
  }

  addEntity(newEntity, name) {

    let allEntities = this.state.entities;

    let entityCount = 1;
    this.state.entities.forEach((entity) => {
      if (newEntity.fileId === entity.fileId) {
        entityCount++;
      }
    });

    if (entityCount > 1) {
      newEntity.name = newEntity.name + " " + entityCount.toString();
    }

    if (name) {
      newEntity.name = name;
    }

    allEntities.push(newEntity);

    this.setState({ entities: allEntities });

    // updated selected & Prev
    if (this.state.selected) {
      this.setState({ prevSelected: this.state.selected });
    }

    this.setState({ selected: newEntity.id });

  }

  updateEntity(entityData, objectChanged, final=true) {

    if (objectChanged === true && entityData.id) {
      let currentObj = scene.getObjectByName(entityData.id);

      currentObj.scale.set(entityData.scale.x, entityData.scale.y, entityData.scale.z);

      let posX = Number(entityData.position.x);
      let posY = Number(entityData.position.y);
      let posZ = Number(entityData.position.z);

      currentObj.position.set(posX, posY, posZ);

      let rotationX = Number(entityData.rotation._x);
      let rotationY = Number(entityData.rotation._y);
      let rotationZ = Number(entityData.rotation._z);

      currentObj.rotation.set(rotationX, rotationY, rotationZ);
    }

    // update entity data
    let updatedEntites = [];
    let allEntities = this.state.entities;

    allEntities.forEach((entity) => {
      if (entityData.id === entity.id) {
        entity.name = entityData.name;
        entity.classification = entityData.classification;
        entity.defaultAnimation = entityData.defaultAnimation;

        if (entity.displayText != entityData.displayText) {
          // Call to rebuild
          entity.displayText = entityData.displayText;

          this.buildSign(entity.id, entity.displayText);
        }

        entity.position = {x: Number(entityData.position.x),y: Number(entityData.position.y),z: Number(entityData.position.z)};
        entity.rotation = {x: Number(entityData.rotation._x),y: Number(entityData.rotation._y),z: Number(entityData.rotation._z)};
        entity.scale = {x: Number(entityData.scale.x),y: Number(entityData.scale.y),z: Number(entityData.scale.z)};

      }
      updatedEntites.push(entity);
    });

    this.setState({entities: updatedEntites});

    if (final === true) {
      // update history queue
      this.addToHistoryQueue();
    }

  }

  FBXLoadFinish(object, assetObj, addToQueue=true) {

    object.name = assetObj.id;
    object.scale.set(assetObj.scale.x, assetObj.scale.y, assetObj.scale.z);

    let posX = Number(assetObj.position.x);
    let posY = Number(assetObj.position.y);
    let posZ = Number(assetObj.position.z);

    object.position.set(posX, posY, posZ);

    let rotationX = Number(assetObj.rotation.x);
    let rotationY = Number(assetObj.rotation.y);
    let rotationZ = Number(assetObj.rotation.z);

    object.rotation.set(rotationX, rotationY, rotationZ);

    object.traverse(function (child) {
      if (child.isMesh) {
        child.castShadow = true;
        child.receiveShadow = true;
      }
    });

    // add to scene  
    scene.getObjectByName(process.env.REACT_APP_TAGNAME).add(object);

    // Save copy of Object data
    let newSelectEntityData = {};
    newSelectEntityData.id = assetObj.id;
    newSelectEntityData.position = object.position;
    newSelectEntityData.rotation = object.rotation;
    newSelectEntityData.scale = object.scale;
    newSelectEntityData.name = assetObj.name;
    newSelectEntityData.defaultAnimation = assetObj.defaultAnimation;
    newSelectEntityData.animations = assetObj.animations;
    newSelectEntityData.classification = assetObj.classification;
    newSelectEntityData.displayText = "";
    newSelectEntityData.class = assetObj.class;
    //console.log(newSelectEntityData);


    this.setState({ selectedEntity: newSelectEntityData });

    // add controls
    control.attach(object);
    scene.add(control);

    if (addToQueue === true) {
      // update history queue
      this.addToHistoryQueue();
    }
  }

  buildSign(entityId, displayText) {
    let currentObj = scene.getObjectByName(entityId);
    const loader = new FontLoader();
    loader.load('/fonts/Source Sans Pro_Bold.json', function (font) {
      const color = 0x000000;

      const matDark = new THREE.LineBasicMaterial({
        color: color,
        side: THREE.DoubleSide
      });

      const message = displayText;
      const shapes = font.generateShapes(message, 1);
      const geometry = new THREE.ShapeGeometry(shapes);
      const text = new THREE.Mesh(geometry, matDark);

      text.scale.x = .02;
      text.scale.y = .02;
      text.scale.z = .02;

      text.position.z = .015;
      text.position.x = -.125;
      text.position.y = .125;

      text.name = "displayText";

      for (var i = currentObj.children.length - 1; i >= 0; i--) {
        currentObj.remove(currentObj.children[i]);
      }

      currentObj.add(text);
    });
  }

  // Onclick for adding assets
  async addObj(asset, id, name, addToQueue=true) {
    if (this.state.isLoaded === true) {
      let newFBX = { position: {}, scale: {}, rotation: {} };

      if (id) {
        newFBX.id = id;
      } else {
        newFBX.id = uuidv4();
      }

      // default display Text
      let displayText = "";
      if (asset.class === "sign" && asset.displayText === undefined) {
        displayText = defaultDisplayText;
      } else if (asset.class === "sign" && asset.displayText !== undefined) {
        displayText = asset.displayText;
      }

      var animationsToLoad = this.state.assets.filter(obj => {
        return obj.id === asset.id && obj.fileType === 1 && obj.fileClassification === 2
      });

      newFBX.assetId = asset.id;
      newFBX.url = asset.filePath;
      newFBX.name = asset.name;
      newFBX.assetName = asset.name;
      newFBX.fileId = asset.fileId;
      newFBX.class = asset.class;
      newFBX.type = "object";
      newFBX.pwaScaleMultiplier = asset.pwaScaleMultiplier;
      newFBX.animations = animationsToLoad;
      newFBX.defaultAnimation = (asset.defaultAnimation !== undefined && asset.defaultAnimation !== null ? asset.defaultAnimation : "");
      newFBX.classification = (isNaN(asset.classification) ? asset.classification : "undefined");
      newFBX.displayText = displayText;
      newFBX.visible = true;

      if (asset.position) {
        newFBX.position.x = asset.position.x;
        newFBX.position.y = asset.position.y;
        newFBX.position.z = asset.position.z;
      } else {
        newFBX.position.x = 0;
        newFBX.position.y = 0;
        newFBX.position.z = 0;
      }

      if (asset.rotation) {
        newFBX.rotation.x = asset.rotation.x;
        newFBX.rotation.y = asset.rotation.y;
        newFBX.rotation.z = asset.rotation.z;
      } else {
        newFBX.rotation.x = 0;
        newFBX.rotation.y = 0;
        newFBX.rotation.z = 0;
      }

      if (asset.scale) {
        newFBX.scale.x = asset.scale.x;
        newFBX.scale.y = asset.scale.y;
        newFBX.scale.z = asset.scale.z;
      } else if (asset.pwaScaleMultiplier) {
        newFBX.scale.x = asset.pwaScaleMultiplier;
        newFBX.scale.y = asset.pwaScaleMultiplier;
        newFBX.scale.z = asset.pwaScaleMultiplier;
      } else {
        // default scale
        newFBX.scale.x = 1;
        newFBX.scale.y = 1;
        newFBX.scale.z = 1;
      }

      //assetLoader.loadAsset(scene, newFBX, control);

      // Add to list
      this.addEntity(newFBX, name);

      // set controls to translate
      control.setMode('translate');

      // Enable / disable resize
      if (asset.class === "waypoint") {
        this.setState({ resizeable: false });
      } else {
        this.setState({ resizeable: true });
      }

      // close popup modals
      this.closeLogic();
      this.closeFiles();

      this.displayLoading("Loading " + newFBX.assetName);

      if (asset.class !== "sign") {
        // Load
        await assetLoader.loadFBXAsync(newFBX.url, this.FBXLoadFinish, newFBX, addToQueue);
      } else {
        // Add Sign
        // Build sign
        const geometry = new THREE.BoxGeometry(.3, .3, 0.02);
        const cubeMaterial = new THREE.MeshBasicMaterial({ color: 0xFFFFFF });
        const cube = new THREE.Mesh(geometry, cubeMaterial);
        cube.name = newFBX.id;

        cube.position.x = newFBX.position.x;
        cube.position.y = newFBX.position.y;
        cube.position.z = newFBX.position.z;

        cube.scale.x = newFBX.scale.x;
        cube.scale.y = newFBX.scale.y;
        cube.scale.z = newFBX.scale.z;

        cube.rotation.x = newFBX.rotation.x;
        cube.rotation.y = newFBX.rotation.y;
        cube.rotation.z = newFBX.rotation.z;

        scene.getObjectByName(process.env.REACT_APP_TAGNAME).add(cube);


        this.buildSign(newFBX.id, displayText);

        control.attach(cube);
        scene.add(control);

        let newSelectEntityData = {};
        newSelectEntityData.id = newFBX.id;
        newSelectEntityData.position = newFBX.position;
        newSelectEntityData.rotation = newFBX.rotation;
        newSelectEntityData.scale = newFBX.scale;
        newSelectEntityData.name = newFBX.name;
        newSelectEntityData.defaultAnimation = newFBX.defaultAnimation;
        newSelectEntityData.animations = newFBX.animations;
        newSelectEntityData.classification = newFBX.classification;
        newSelectEntityData.displayText = displayText;
        newSelectEntityData.class = newFBX.class;
        //console.log(newSelectEntityData);


        this.setState({ selectedEntity: newSelectEntityData });

        if (addToQueue === true) {
          // update history queue
          this.addToHistoryQueue();
        }

        if (addToQueue === true) {
          // update history queue
          this.addToHistoryQueue();
        }
        

      }

      // clear loading screen
      this.closeLoading();

    }

  }

  meshLoadFinish(object, assetObj, tag) {

    // Group and name
    const group = new THREE.Group();
    group.name = "sceneGroup";
    scene.add(group);

    const meshGroup = new THREE.Group();
    meshGroup.name = "meshGroup";

    // https://github.com/daign/clipping-with-caps
    const localPlane = new THREE.Plane(new THREE.Vector3(0, -1, 0), 3.2);
    //clippingPlanes: [localPlane] // Add to material

    // Mesh settings
    const mesh = object.children[0];
    const material = new THREE.LineBasicMaterial({
      color: 0x3f48cc,
      clippingPlanes: [localPlane]
    });

    const edges = new THREE.EdgesGeometry(mesh.geometry);
    let line = new THREE.LineSegments(edges, material);
    line.name = "meshlines";
    meshGroup.add(line);
    meshGroup.quaternion.set(tag.rotation.x, tag.rotation.y, tag.rotation.z, tag.rotation.w);

    // tag
    const geometry = new THREE.BoxGeometry(.3, .3, 0.02);
    const cubeMaterial = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
    const cube = new THREE.Mesh(geometry, cubeMaterial);
    cube.name = process.env.REACT_APP_TAGNAME;

    // Group tag to everything else
    const groupTag = new THREE.Group();
    groupTag.name = assetObj.id;
    group.add(groupTag);

    groupTag.add(cube);
    groupTag.add(meshGroup);

    // create box a round mesh
    var box = new THREE.Box3().setFromObject(group);
    let measure = new THREE.Vector3();
    box.getSize(measure);

    // find center point of mesh
    let cent = new THREE.Vector3();
    box.getCenter(cent);

    // Position or Rotation
    line.position.set(-tag.position.x, -tag.position.y, tag.position.z);

    /*
    var hex  = 0xff0000;
    var bbox = new THREE.BoxHelper(groupTag, hex);
    groupTag.add( bbox );
    bbox.update();
    */

    //group.position.set(-0.5,  (measure.y/2) + tag.position.y - cent.y, (measure.z/2) + tag.position.z - cent.z) ; 
    //group.position.set((measure.x/2) + tag.position.x,  (measure.y/2) + tag.position.y, (measure.z/2) + tag.position.z);
    //group.position.set((measure.x/2) + tag.position.x - cent.x,  (measure.y/2) + tag.position.y - cent.y, (measure.z/2) + tag.position.z - cent.z);
    group.position.set(0, (measure.y / 2) + tag.position.y - cent.y, 0);

    // Add controls to move
    control.attach(group);
    scene.add(control);
  }

  async addMesh(asset, id) {

    this.displayLoading("Loading Mesh..");

    if (this.state.meshLoaded === true) {
      // delete old mesh
      this.clearScenario();
    }

    if (this.state.meshLoaded === false) {

      // tag
      let tag = { id: uuidv4(), position: {}, rotation: {}, scale: {} };
      tag.scale.x = 1;
      tag.scale.y = 1;
      tag.scale.z = 1;

      if (asset.tags.length > 0) {
        for (let t = 0; t < asset.tags.length; t++) {
          if (asset.tags[t].ordinal === 0) {
            tag.position.x = asset.tags[t].position.x;
            tag.position.y = asset.tags[t].position.y;
            tag.position.z = asset.tags[t].position.z;
            // scan client flips for visual notice

            tag.rotation.x = asset.tags[t].rotation.x;
            tag.rotation.y = asset.tags[t].rotation.y;
            tag.rotation.z = asset.tags[t].rotation.z;
            tag.rotation.w = asset.tags[t].rotation.w;
          }
        }
      } else {
        tag.position.x = 0;
        tag.position.y = 0;
        tag.position.z = 0;

        tag.rotation.x = 0;
        tag.rotation.y = 0;
        tag.rotation.z = 0;
        tag.rotation.w = 0;
      }



      // mesh
      let newMesh = { position: {}, scale: {} };

      if (id) {
        newMesh.id = id;
      } else {
        newMesh.id = uuidv4();
      }

      newMesh.assetId = asset.id;
      newMesh.url = asset.filePath;
      newMesh.name = asset.name;
      newMesh.fileId = asset.fileId;
      newMesh.class = asset.class;
      newMesh.type = "mesh";
      newMesh.visible = true;
      newMesh.classification = "undefined";
      newMesh.displayText = "";
      newMesh.defaultAnimation = "";

      newMesh.position.x = 0;
      newMesh.position.y = 0;
      newMesh.position.z = 0;

      newMesh.scale.x = 1;
      newMesh.scale.y = 1;
      newMesh.scale.z = 1;

      //assetLoader.loadMesh(scene, newMesh, tag, control);

      // toggle close & Change value
      //this.handleSpatialMapToggle();
      this.closeSpatialMap();
      this.setState({ spatial: asset.name })

      // Add to list
      this.addEntity(newMesh);

      // set mesh to color
      this.setState({ mesh: "color" });

      // set tag data
      this.setState({ tag: tag });

      // set mesh loaded
      this.setState({ meshLoaded: true });

      // set controls to translate
      control.setMode('translate');

      if (process.env.REACT_APP_DEBUG === true) {
        // Start Stats
        stats.setMode(0);
        stats.domElement.style.position = 'absolute';
        stats.domElement.style.left = '150px';
        stats.domElement.style.top = '0';
        //stats.domElement.style.display = 'none';
        stats.domElement.id = "webXrStats";

        document.body.appendChild(stats.domElement);
      }

      // Load asset
      await assetLoader.loadMeshAsync(newMesh.url, this.meshLoadFinish, newMesh, tag);

      // clear loading screen
      this.closeLoading();

    }
  }

  displayLoading(message) {
    this.setState({ openLoading: true });
    this.setState({ loadingMsg: message });
  }

  clearScenario() {

    // temp data for current scenarios / assets
    let tempScenarios = this.state.scenarios;
    let tempAssets = this.state.assets;
    let templibraries = this.state.libraries;

    // clear state
    this.setState(this.getInitialState());

    this.setState({ scenarios: tempScenarios })
    this.setState({ assets: tempAssets });
    this.setState({ libraries: templibraries });
    this.setState({ isLoaded: true })

    // remove controls
    control.detach();
    scene.remove(control);

    // clear scene
    for (let i = scene.children.length - 1; i >= 0; i--) {
      let child = scene.children[i];

      if (child.type === "Group") {
        scene.remove(child);
      }
    }
  }

  async loadScenario(scenarioId) {

    // clear out History queue
    this.setState({historyQueue: []});

    if (scenarioId) {

      // clear Scenario
      this.clearScenario();
      let scenarioData = await filesClient.getScenario(scenarioId);

      if (scenarioData && scenarioData.scenario) {

        // Set ScenarioId
        this.setState({ scenarioId: scenarioData.scenario.id });

        // set name
        this.setState({ scenarioName: scenarioData.scenario.title });

        // set desc
        this.setState({ scenarioDesc: scenarioData.scenario.description });

        // set script / theme
        this.setState({ theme: Buffer.from(scenarioData.scenario.theme.script.data, "base64").toString() });

        // reset view
        this.cameraDefault();

        let toLoadMesh = {};
        let toLoadMeshId = "";
        let toLoadAssets = [];

        // get all entities
        if (scenarioData.scenario.environment.entities) {
          let entities = scenarioData.scenario.environment.entities;
          for (let i = 0; i < entities.length; i++) {
            toLoadMesh = entities[i];
            toLoadMeshId = entities[i].id;
          }
        }

        if (scenarioData.scenario.scene.entities) {
          let entities = scenarioData.scenario.scene.entities;
          for (let i = 0; i < entities.length; i++) {
            toLoadAssets.push(entities[i]);
          }
        }

        let allAssets = this.state.assets;

        // Load Mesh
        var meshToLoad = allAssets.filter(obj => {
          return obj.id === toLoadMesh.asset.id && obj.fileType === 3
        });

        if (meshToLoad.length > 0) {
          await this.addMesh(meshToLoad[0], toLoadMeshId);

          for (let i = 0; i < toLoadAssets.length; i++) {
            var assetToLoad = allAssets.filter(obj => {
              return obj.id === toLoadAssets[i].asset.id && obj.fileType === 1 && obj.fileClassification === 1
            });

            if (assetToLoad.length > 0) {
              let loadEntry = {};

              loadEntry.id = assetToLoad[0].id;
              loadEntry.filePath = assetToLoad[0].filePath;
              loadEntry.name = assetToLoad[0].name;
              loadEntry.fileId = assetToLoad[0].fileId;
              loadEntry.class = assetToLoad[0].class;
              loadEntry.pwaScaleMultiplier = assetToLoad[0].pwaScaleMultiplier;

              loadEntry.classification = toLoadAssets[i].classification;
              loadEntry.defaultAnimation = toLoadAssets[i].defaultAnimation;
              loadEntry.displayText = toLoadAssets[i].displayText;

              loadEntry.position = {};
              loadEntry.scale = {};
              loadEntry.rotation = {};

              let objQ = new THREE.Quaternion(-toLoadAssets[i].rotation.x, -toLoadAssets[i].rotation.y, toLoadAssets[i].rotation.z, toLoadAssets[i].rotation.w);
              var rotation = new THREE.Euler().setFromQuaternion(objQ, "XYZ");

              loadEntry.position.x = toLoadAssets[i].position.x;
              loadEntry.position.y = toLoadAssets[i].position.y;
              loadEntry.position.z = -toLoadAssets[i].position.z;

              loadEntry.rotation.x = rotation.x;
              loadEntry.rotation.y = rotation.y;
              loadEntry.rotation.z = rotation.z;

              loadEntry.scale.x = toLoadAssets[i].scale.x;
              loadEntry.scale.y = toLoadAssets[i].scale.y;
              loadEntry.scale.z = toLoadAssets[i].scale.z;

              await this.addObj(loadEntry, toLoadAssets[i].id, toLoadAssets[i].name);

            }

          }

        }


      } else {
        // SHOW ERROR

      }

      // /api/scenarios/{id}

    }

    // clear out History queue
    this.setState({historyQueue: []});
    this.addToHistoryQueue();
  }

  async updateScenario() {

    let currentScenario = await filesClient.getScenario(this.state.scenarioId);

    let currentEntities = [];
    let entityCreate;

    if (currentScenario && currentScenario.scenario) {
      let scriptId = currentScenario.scenario.theme.script.id;
      let themeId = currentScenario.scenario.theme.id;
      let sceneId = currentScenario.scenario.scene.id;
      let environmentId = currentScenario.scenario.environment.id;

      // get all entities from scene
      if (currentScenario.scenario.scene.entities) {
        let entities = currentScenario.scenario.scene.entities;
        for (let i = 0; i < entities.length; i++) {
          currentEntities.push(entities[i].id);
        }
      }

      // get all entities from environment
      if (currentScenario.scenario.environment.entities) {
        let entities = currentScenario.scenario.environment.entities;
        for (let i = 0; i < entities.length; i++) {
          currentEntities.push(entities[i].id);
        }
      }


      for (let e = 0; e < this.state.entities.length; e++) {
        let entity = this.state.entities[e];
        let newEntry = { position: {}, scale: {}, rotation: {}, size: {} };
        let currentObj = {};

        var isCurrent = currentEntities.filter(obj => {
          return obj === entity.id
        });

        if (entity.type === "object") {
          currentObj = scene.getObjectByName(entity.id);
        } else {
          currentObj = scene.getObjectByName("meshlines");
        }

        newEntry.scale.x = currentObj.scale.x;
        newEntry.scale.y = currentObj.scale.y;
        newEntry.scale.z = currentObj.scale.z;

        newEntry.name = entity.name;
        newEntry.assetId = entity.assetId;
        newEntry.defaultAnimation = entity.defaultAnimation;
        newEntry.classification = entity.classification;
        newEntry.displayText = entity.displayText;

        if (entity.type === "object") {
          newEntry.sceneId = sceneId;
          newEntry.hasGravity = true;
          let objQ = new THREE.Quaternion(currentObj.quaternion.x, currentObj.quaternion.y, currentObj.quaternion.z, currentObj.quaternion.w);

          newEntry.position.x = currentObj.position.x;
          newEntry.position.y = currentObj.position.y;
          newEntry.position.z = -currentObj.position.z;

          newEntry.rotation.x = -objQ.x;
          newEntry.rotation.y = -objQ.y;
          newEntry.rotation.z = objQ.z;
          newEntry.rotation.w = objQ.w;
        } else if (entity.type === "mesh") {
          newEntry.environmentId = environmentId;
          newEntry.hasGravity = false;

          // create quaternion
          // invert

          // -x
          let meshQ = new THREE.Quaternion(currentObj.parent.quaternion.x, currentObj.parent.quaternion.y, currentObj.parent.quaternion.z, currentObj.parent.quaternion.w);
          meshQ.invert();

          newEntry.position.x = -currentObj.position.x;
          newEntry.position.y = currentObj.position.y;
          newEntry.position.z = currentObj.position.z;

          newEntry.rotation.x = meshQ.x;
          newEntry.rotation.y = meshQ.y;
          newEntry.rotation.z = meshQ.z;
          newEntry.rotation.w = meshQ.w;
        }

        if (entity.class === "trigger") {
          newEntry.type = 1;
        } else if (entity.class === "waypoint") {
          newEntry.type = 2;
        } else if (entity.class === "sign") {
          newEntry.type = 5;
        } else {
          newEntry.type = 0;
        }

        newEntry.size.x = 1;
        newEntry.size.y = 1;
        newEntry.size.z = 1;

        if (isCurrent.length > 0) {
          let entityUpdate = await filesClient.updateEntity(entity.id, newEntry);
          // Remove updated
          currentEntities = currentEntities.filter(function (id) {
            return id !== entity.id
          })

        } else {
          newEntry.assetId = entity.assetId;
          if (entity.type === "object") {
            newEntry.sceneId = sceneId;
          } else if (entity.type === "mesh") {
            newEntry.environmentId = environmentId;
          }

          entityCreate = await filesClient.createEntity(newEntry);

          if (entityCreate.isSuccess === true && entityCreate.entity) {
            //let entityId = entityCreate.entity.id;
          }
        }

      }

      // remove any unused entities
      for (let e = 0; e < currentEntities.length; e++) {
        // /api/entities/{id} DELETE
        await filesClient.deleteEntity(currentEntities[e]);
      }

      let scriptsData = {};
      scriptsData.id = scriptId;
      scriptsData.tag = 0;
      scriptsData.data = Buffer.from(this.state.theme).toString('base64');
      let scriptsCreate = await filesClient.updateScript(scriptId, scriptsData);
      if (scriptsCreate.isSuccess === true && scriptsCreate.script) {
      }

      let scenarioData = {};
      scenarioData.themeId = themeId;
      scenarioData.title = this.state.scenarioName;
      scenarioData.description = this.state.scenarioDesc;

      let scenarioUpdate = await filesClient.updateScenario(this.state.scenarioId, scenarioData);
      if (scenarioUpdate && scenarioUpdate.isSuccess === true && scenarioUpdate.scenario) {
        this.setState({ openSaveConfirm: true });

        this.setState({ scenarioId: this.state.scenarioId });

        this.updateScenarioList();
      } else {
        // display error
        this.setState({ error: ["Failed to update scenario"] });
        this.setState({ openError: true });
      }


    } else {
      // Display Error Saving
      this.setState({ error: ["Failed to load scene"] });
      this.setState({ openError: true });
    }


  }

  async newScenario(saveAs = false) {
    // POST ​/api​/scenes​/create
    let sceneData = {};
    let sceneCreate = await filesClient.createScene(sceneData);

    // /api/environments/create
    let environmentData = {};
    let environmentCreate = await filesClient.createEnvironment(environmentData);

    let entityCreate;

    if (sceneCreate.isSuccess === true && sceneCreate.scene && environmentCreate.isSuccess === true && environmentCreate.environment) {
      let sceneId = sceneCreate.scene.id;
      let environmentId = environmentCreate.environment.id;

      // POST ​/api​/entities​/create
      // createEntity(data)

      for (let e = 0; e < this.state.entities.length; e++) {
        let entity = this.state.entities[e];
        let newEntry = { position: {}, scale: {}, rotation: {}, size: {} };
        let currentObj = {};

        if (entity.type === "object") {
          currentObj = scene.getObjectByName(entity.id);
        } else {
          currentObj = scene.getObjectByName("meshlines");
        }

        newEntry.scale.x = currentObj.scale.x;
        newEntry.scale.y = currentObj.scale.y;
        newEntry.scale.z = currentObj.scale.z;

        newEntry.name = entity.name;
        newEntry.assetId = entity.assetId;
        newEntry.defaultAnimation = entity.defaultAnimation;
        newEntry.classification = entity.classification;
        newEntry.displayText = entity.displayText;

        if (entity.type === "object") {
          newEntry.sceneId = sceneId;
          newEntry.hasGravity = true;
          // create quaternion
          // invert

          // -z

          let objQ = new THREE.Quaternion(currentObj.quaternion.x, currentObj.quaternion.y, currentObj.quaternion.z, currentObj.quaternion.w);
          //objQ.invert();

          newEntry.position.x = currentObj.position.x;
          newEntry.position.y = currentObj.position.y;
          newEntry.position.z = -currentObj.position.z;

          newEntry.rotation.x = -objQ.x;
          newEntry.rotation.y = -objQ.y;
          newEntry.rotation.z = objQ.z;
          newEntry.rotation.w = objQ.w;
        } else if (entity.type === "mesh") {
          newEntry.environmentId = environmentId;
          newEntry.hasGravity = false;

          // create quaternion
          // invert

          // -x
          let meshQ = new THREE.Quaternion(currentObj.parent.quaternion.x, currentObj.parent.quaternion.y, currentObj.parent.quaternion.z, currentObj.parent.quaternion.w);
          meshQ.invert();

          newEntry.position.x = -currentObj.position.x;
          newEntry.position.y = currentObj.position.y;
          newEntry.position.z = currentObj.position.z;

          newEntry.rotation.x = meshQ.x;
          newEntry.rotation.y = meshQ.y;
          newEntry.rotation.z = meshQ.z;
          newEntry.rotation.w = meshQ.w;
        }

        if (entity.class === "trigger") {
          newEntry.type = 1;
        } else if (entity.class === "waypoint") {
          newEntry.type = 2;
        } else if (entity.class === "sign") {
          newEntry.type = 5;
        } else {
          newEntry.type = 0;
        }

        newEntry.size.x = 1;
        newEntry.size.y = 1;
        newEntry.size.z = 1;

        entityCreate = await filesClient.createEntity(newEntry);
      }

      // POST /api/scripts/create
      // tag, data (BASE64)
      let scriptsData = {};
      scriptsData.tag = 0;
      scriptsData.data = Buffer.from(this.state.theme).toString('base64');
      let scriptsCreate = await filesClient.createScript(scriptsData);
      if (scriptsCreate.isSuccess === true && scriptsCreate.script) {

        let themeData = {};
        themeData.scriptId = scriptsCreate.script.id;
        let themeCreate = await filesClient.createTheme(themeData);
        if (themeCreate.isSuccess === true && themeCreate.theme) {
          let themeId = themeCreate.theme.id;

          let scenarioData = {};
          scenarioData.themeId = themeId;
          if (saveAs) {
            scenarioData.title = this.state.scenarioSaveAs;
          } else {
            scenarioData.title = this.state.scenarioName;
          }

          scenarioData.sceneId = sceneId;
          scenarioData.environmentId = environmentId;

          let scenarioCreate = await filesClient.createScenario(scenarioData);
          if (scenarioCreate && scenarioCreate.isSuccess === true && scenarioCreate.scenario) {
            this.setState({ openSaveConfirm: true });

            if (saveAs === false) {
              this.setState({ scenarioId: scenarioCreate.scenario.id });
            }

            this.updateScenarioList();

            // Update URL
          } else {
            // display error
            this.setState({ error: ["Failed to create scenario"] });
            this.setState({ openError: true });
          }



        }
      }

    } else {
      // Error
      this.setState({ error: ["Failed to create scene"] });
      this.setState({ openError: true });

    }
  }

  async saveScenario(saveAs = false) {

    // /backend/src/master/csharp/dotnet/README.md
    let errorMsg = [];

    if (this.state.scenarioName === "") {
      errorMsg.push("Missing Scenario Name");
    } else {
      if (saveAs === false) {
        this.setState({ scenarioSavedName: this.state.scenarioName });
      } else {
        this.setState({ scenarioSavedName: this.state.scenarioSaveAs });
      }

    }

    if (this.state.theme === "") {
      errorMsg.push("Missing theme data");
    }

    if (this.state.entities.length === 0) {
      errorMsg.push("Must contain atleast a mesh");
    }

    // Check for required fields theme, scenarioName, entities
    if (errorMsg.length > 0) {
      // show error modal
      this.setState({ error: errorMsg });
      this.setState({ openError: true });

    } else {
      // Update
      if (this.state.scenarioId && saveAs === false) {
        this.updateScenario();
        //this.newScenario();
      } else { // new scenario
        this.newScenario(saveAs);
      }
    }

  }
  
  handleKeydown(event) {

    // check where typing, ignore if input or textarea
    if (event.target.nodeName !== "INPUT" && event.target.nodeName !== "TEXTAREA") {

      if (Date.now() - lastKeyPress > 250) {
        ctrlKey = false;
      }

      if (event.ctrlKey) {
        ctrlKey = true;
      }

      if (ctrlKey === true) {
        switch (event.keyCode) {
          case 67: // c
            this.copyObj();
            break;
          case 86: // v
            this.pasteObj();
            break;
          case 88: // x
            this.cutObj();
            break;
          case 90: // z
            this.processHistoryQueue();
            break;
        }

      } else {
        switch (event.keyCode) {

          case 16: // Shift
            control.setTranslationSnap(100);
            control.setRotationSnap(THREE.MathUtils.degToRad(15));
            control.setScaleSnap(0.25);
            break;

          case 46: // del
            this.deleteObj();
            break;

          case 87: // W
            control.setMode('translate');
            break;

          case 69: // E
            control.setMode('rotate');
            break;

          case 82: // R
            if (this.state.resizeable === true) {
              control.setMode('scale');
            }

            break;
          case 187:
          case 107: // +, =, num+
            control.setSize(control.size + 0.1);
            break;

          case 189:
          case 109: // -, _, num-
            control.setSize(Math.max(control.size - 0.1, 0.1));
            break;

          case 88: // X
            //control.showX = ! control.showX;
            break;

          case 89: // Y
            //control.showY = ! control.showY;
            break;

          case 90: // Z
            //control.showZ = ! control.showZ;
            break;

          case 32: // Spacebar
            //control.enabled = ! control.enabled;
            break;

          case 27: // Esc

            //
            //selectedObj.scale.set(1, 1, 1);
            selectedObj.rotation.set(0, 0, 0);


            break;

        }

      }

      lastKeyPress = Date.now();

    }

  }

  handleKeyup(event) {
    switch (event.keyCode) {

      case 16: // Shift
        control.setTranslationSnap(null);
        control.setRotationSnap(null);
        control.setScaleSnap(null);
        break;

    }
  }

  toggleControls(event) {

  }

  cameraOverhead() {
    orbit.reset();

    currentCamera.position.set(0, 20, 0);
    currentCamera.updateProjectionMatrix();
    currentCamera.lookAt(new THREE.Vector3(0, 0, 0));

    this.setState({ overhead: true });
  }

  cameraDefault() {
    orbit.reset();

    currentCamera.position.set(0, 5, 20);
    currentCamera.updateProjectionMatrix();

    currentCamera.lookAt(new THREE.Vector3(0, 0, 0));

    this.setState({ overhead: false });
  }

  // Three js
  async startScene() {
    if (WEBGL.isWebGLAvailable()) {

      try {


        container = document.getElementById('webxr');

        container.addEventListener('mousedown', this.onWindowResize);

        renderer = new THREE.WebGLRenderer({ antialias: true });

        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.localClippingEnabled = true;

        container.appendChild(renderer.domElement);
        const aspect = window.innerWidth / window.innerHeight;
        cameraPersp = new THREE.PerspectiveCamera(50, aspect, 0.01, 30000);
        currentCamera = cameraPersp;

        //currentCamera.position.set(0, 5, 20);

        scene = new THREE.Scene();
        scene.add(new THREE.GridHelper(40, 40, 0x888888, 0x444444));

        /*
        // Lighting
        const light = new THREE.PointLight();
        light.position.set(20, 10, 20);
        scene.add(light);

        const light2 = new THREE.PointLight();
        light2.position.set(-20, 10, -20);
        scene.add(light2);

        const light3 = new THREE.PointLight();
        light3.position.set(20, 10, -20);
        scene.add(light3);

        const light4 = new THREE.PointLight();
        light4.position.set(-20, 10, 20);
        scene.add(light4);
        */

        // constant light to everything
        const light = new THREE.AmbientLight(0xffffff, 1);
        scene.add(light);

        const directionalLight = new THREE.DirectionalLight();
        directionalLight.castShadow = true;
        scene.add(directionalLight);

        // directional
        /*
        const directionalLight = new THREE.DirectionalLight();
        directionalLight.castShadow = true;
        scene.add(directionalLight);
        */

        // Label
        /*
        https://threejs.org/examples/#css2d_label
        https://github.com/mrdoob/three.js/blob/master/examples/css2d_label.html

        labelRenderer = new CSS2DRenderer();
        labelRenderer.setSize(window.innerWidth, window.innerHeight);
        labelRenderer.domElement.style.position = 'absolute';
        labelRenderer.domElement.style.top = '0px';
        labelRenderer.domElement.style.zIndex = '4';
        document.body.appendChild(labelRenderer.domElement);
        */

        orbit = new OrbitControls(currentCamera, renderer.domElement);
        orbit.update();
        orbit.addEventListener('change', this.renderScene);

        this.cameraDefault();

        control = new TransformControls(currentCamera, renderer.domElement);
        control.addEventListener('change', this.renderScene);
        control.addEventListener('dragging-changed', function (e) {
          orbit.enabled = !e.value
        });

        control.addEventListener('objectChange', (e) => {
          lastEntityUpdate = Date.now();

          selectedObj = e.target.object;
        });

        control.addEventListener('mouseUp', (e) => {
          this.updateEntityData(e.target.object);
        });

        window.addEventListener('resize', this.onWindowResize);
        window.addEventListener('keydown', this.handleKeydown);
        window.addEventListener('keyup', this.handleKeyup);

        // raycast
        window.addEventListener("click", this.onMouseDown);

        // Start Animation
        this.animate();

        // set as loaded
        this.setState({ isLoaded: true })

        if (this.props.toloadId) {
          this.loadScenario(this.props.toloadId);
        }

      } catch (error) {
        this.setState({ openThreeErr: true });
        this.setState({ threeErr: error });
      }

    } else {
      const warning = WEBGL.getWebGLErrorMessage();
      this.setState({ openThreeErr: true });
      this.setState({ threeErr: warning });
    }

  }

  onMouseDown(event) {
    // if not loading
    if (this.state.openLoading === false && (Date.now() - lastEntityUpdate) > lastEntityUpdateOffset) {

      // Update mouse location
      let offsetRec = document.getElementById('webxr').getBoundingClientRect();
      let offset = offsetRec.top;

      pointer.x = (event.clientX / window.innerWidth) * 2 - 1;
      pointer.y = -((event.clientY - offset) / window.innerHeight) * 2 + 1;

      raycaster.setFromCamera(pointer, currentCamera);

      // Check for intersect
      let allIntersects = [];
      if (scene.getObjectByName(process.env.REACT_APP_TAGNAME)) {
        if (this.state.entities && this.state.entities.length > 0) {

          for (let i = 0; i < this.state.entities.length; i++) {
            let curEntity = this.state.entities[i];
            let returnIntersect = {};

            if (curEntity.type != "mesh") {
              let intersects = raycaster.intersectObject(scene.getObjectByName(curEntity.id), true);
              if (intersects.length > 0) {
                returnIntersect.id = curEntity.id;
                returnIntersect.distance = intersects[0].distance;

                allIntersects.push(returnIntersect);
              }
            }
          }
        }

        allIntersects.sort((a, b) => (a.distance > b.distance) ? 1 : -1)

        if (allIntersects.length > 0) {
          this.selectObject(allIntersects[0].id);
        } else {
          //this.deSelectObject();
        }


        /*
        const intersects = raycaster.intersectObjects(scene.getObjectByName(process.env.REACT_APP_TAGNAME).children);
        for (let i = 0; i < intersects.length; i++) {
          //intersects[ i ].object.material.color.set( 0xff0000 );
          console.log(intersects);
          if (intersects[i].object && intersects[i].object.count > 0) {
            this.findObjectByParent(intersects[i].object[0], process.env.REACT_APP_TAGNAME);
          }
        }
        */

      }

    }

  }


  findObjectByParent(object, parent) {
    for (let parent of object) {

    }
  }

  filteredLibraryObjects(library){
    return this.state.assets.filter(assetCheck => assetCheck.fileType === 1 && assetCheck.fileClassification === 1 && objectsCheck.includes(assetCheck.classification) && assetCheck.libraryId === library.id)
  }

  onWindowResize() {

    const aspect = window.innerWidth / window.innerHeight;

    cameraPersp.aspect = aspect;
    cameraPersp.updateProjectionMatrix();

    renderer.setSize(window.innerWidth, window.innerHeight);

    // Label
    //labelRenderer.setSize(window.innerWidth, window.innerHeight);

    this.renderScene();

  }

  animate() {

    requestAnimationFrame(this.animate);

    this.renderScene();
    stats.update();
  }

  renderScene() {



    let cap = 0;
    cap = Math.round((renderer.info.render.triangles / process.env.REACT_APP_CAPACITY) * 100);

    if (cap > 100) {
      cap = 100;
    }

    // update capacity
    this.setState({ capacity: cap });

    renderer.render(scene, currentCamera);

    // Label
    //labelRenderer.render(scene, currentCamera);
  }

  async componentDidMount() {

    document.title = process.env.REACT_APP_TITLE_PREFIX + "Scenario Builder";
    document.body.className = 'home_landing';

    // list of Libraries
    let libraryList = await filesClient.getLibrariesListFormatted();

    if (libraryList[0].isSuccess === true && libraryList[0].libraries) {
      this.setState({ libraries: libraryList[0].libraries });
    } else {
      //this.displayPopup("Error", "Can not load API");
      // display error
      this.setState({ error: ["Can not load API"] });
      this.setState({ openError: true });
    }

    if (libraryList[1].isSuccess === true && libraryList[1].assets) {
      this.setState({ assets: libraryList[1].assets });
    }

    let assetList = await filesClient.getAssetsList();

    if (assetList.isSuccess === true && assetList.assets) {
      //this.setState({assets: assetList.assets});
    } else {
      // display error connectiong to files API
    }

    this.updateScenarioList();

    // start scene
    this.startScene();


    authCheck.checkPerms(["Administrator", "Editor"]);
  }


  componentWillUnmount() {
    container.removeEventListener('mousedown', this.onWindowResize);
    orbit.removeEventListener('change', this.renderScene);
    control.removeEventListener('change', this.renderScene);
    control.removeEventListener('dragging-changed');
    window.removeEventListener('resize', this.onWindowResize);
    control.removeEventListener('objectChange');
    window.removeEventListener('keydown', this.handleKeydown);
    window.removeEventListener('keyup', this.handleKeyup);
    window.removeEventListener("click", this.onMouseDown);


  }

  render() {
    const objectSelect = this.state.selected;
    let modeBtns;
    if (objectSelect !== "") {
      modeBtns = <div class="builder_object_left1">
        <a onClick={this.setMovement} title="Move Tool"><img alt="Move Tool" src="/images/builder_objecticon1.png" /></a>
        <a onClick={this.setRotation} title="Rotate Tool"><img alt="Rotate Tool" src="/images/builder_objecticon2.png" /></a>
        {(this.state.resizeable === true ? <a onClick={this.setScale} title="Scale Tool"><img alt="Scale Tool" src="/images/builder_objecticon3.png" /></a> : "")}
      </div>;
    } else {
      modeBtns = "";
    }

    return (
      <div class="flex direction-column full-height">
        <Header
          dataCallBack={this.dataUpdate} 
          prefCallBack={this.modalPrefToggle} 
          loadCallBack={this.modalScenarioToggle} 
          revertCallBack={this.revertToLastSaved} 
          saveAsCallBack={this.saveAsScenario} 
          saveCallBack={this.saveScenario} 
          meshCallback={this.setMesh} 
          cutCallback={this.cutObj} 
          undoCallback={this.processHistoryQueue}
          delCallBack={this.deleteObj} 
          copyCallback={this.copyObj} 
          pasteCallback={this.pasteObj} 
          meshStatus={this.state.mesh}
          section="Scenario Builder"
          isBuilder={true}
        />

        <section class="flex-grow full-height">
          <div class="full-width full-height">
            <div class="scene-ui-container">
              <div class="main-area">
                <div class="spectator_content_right">
                  <div class="pwa27spectator_iconmenu">
                    <ul class="pwa08_iconmenumargin">
                      <li className={`${this.state.overhead === false ? "active" : ""}`}><a onClick={this.cameraDefault} title="Perspective View"><span><font><img src="/images/pwa_27icon1.png" alt="Perspective View" /></font></span></a></li>
                      <li className={`${this.state.overhead === true ? "active" : ""}`}><a onClick={this.cameraOverhead} title="Topdown View"><span><font><img src="/images/pwa_27icon2.png" alt="Topdown View" /></font></span></a></li>
                    </ul>
                  </div>
                  {modeBtns}
                  <div id="webxr"></div>
                </div>
              </div>
              <div class="sideMenu" style={{ display: this.state.openFiles === true ? 'block' : 'none' }}>
                <div class="left_sidebar_border left_sidebar_builderobject">
                  <div class="sidebar_panel_titel">
                    <TabHeader render={this.state.openFiles} text="OBJECT LIBRARY" />
                    <a className="sideMenuClose" onClick={this.closeFiles}></a>
                  </div>

                  <div class="sidebar_pwa08run_padding">
                    <Accordion allowMultipleExpanded allowZeroExpanded>
                      {this.state.libraries.map((library,l) => (
                        <AccordionItem key={l}>
                          <AccordionItemHeading>
                            <AccordionItemButton>
                              {library.name}
                            </AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            {this.filteredLibraryObjects(library).map((asset,i) => (
                              <div key={i} onClick={() => this.addObj(asset)} class="pointer">
                                <img src="/images/object_icon.png" alt="" />
                                {asset.name}
                              </div>
                            ))}
                            {this.filteredLibraryObjects(library).length == 0 && (
                              <div class="pointer">
                                No Assets Found.
                              </div>
                            )}
                          </AccordionItemPanel>
                        </AccordionItem>
                      ))}
                    </Accordion>
                  </div>
                </div>
              </div>
              <div class="sideMenu" style={{ display: this.state.logic === true ? 'block' : 'none' }}>
                <div class="left_sidebar_border left_sidebar_builderobject">
                  <div class="sidebar_panel_titel">
                    <TabHeader render={this.state.logic} text="LOGIC LIBRARY" />
                    <a className="sideMenuClose" onClick={this.closeLogic}></a>
                  </div>
                  <div class="sidebar_pwa08run_padding">
                    {this.state.assets.filter(assetCheck => assetCheck.fileType === 1 && logicCheck.includes(assetCheck.classification)).map((asset,i) => (
                      <div key={i} onClick={() => this.addObj(asset)} class="pointer">
                        <img src="/images/object_icon.png" alt="" />
                        {asset.name}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <ObjectPanel miniMenu={true} dataCallBack={this.dataUpdate} selectObject={this.selectObject} toggleHide={this.toggleHide} addMesh={this.addMesh} handleSpatialMapToggle={this.handleSpatialMapToggle} updateScenarioDesc={this.updateScenarioDesc} updateScenarioName={this.updateScenarioName} scenarioName={this.state.scenarioName} isSpatialMapActive={this.state.isSpatialMapActive} spatial={this.state.spatial} selectedEntity={this.state.selectedEntity} entities={this.state.entities} assets={this.state.assets} capacity={this.state.capacity} selected={this.state.selected} modalFilesToggle={this.modalFilesToggle} logicToggle={this.logicToggle} modalThemeToggle={this.modalThemeToggle} updateEntity={this.updateEntity} scenarioDesc={this.state.scenarioDesc} />
            </div>
          </div>
        </section>

        <Popup open={this.state.openPref} modal>

          <div id="builder05leftPref" class="fancybox_popup">
            <h3>builder PREFERENCES</h3>
            <a className="popup_close" onClick={this.closeModalPref}></a>
            <div class="builder30left_popup">

              <h4>vIEW CONTROL</h4>


              <div class="sidebar_pane6_checkbox sidebar_builder30left_checkbox">
                <h3>Invert Y-axis</h3>

                <div class="toggle-button-cover">
                  <div class="button-cover">
                    <div class="button_checkbox r" id="button-1">
                      <input type="checkbox" class="checkbox" />
                      <div class="knobs"></div>
                      <div class="layer"></div>
                    </div>
                  </div>
                </div>


              </div>

              <h4>Position cONTROLs</h4>
              <div class="sidebar_pane6_checkbox">
                <h3>Snap Move</h3>

                <div class="toggle-button-cover">
                  <div class="button-cover">
                    <div class="button_checkbox r" id="button-1">
                      <input type="checkbox" class="checkbox" />
                      <div class="knobs"></div>
                      <div class="layer"></div>
                    </div>
                  </div>
                </div>


              </div>
              <div class="sidebar_pane6_checkbox">
                <h3>Snap Length (meters)</h3>

                <input placeholder=".25" class="input_text1" />


              </div>
              <div class="sidebar_pane6_checkbox">
                <h3>Snap Rotate</h3>

                <div class="toggle-button-cover">
                  <div class="button-cover">
                    <div class="button_checkbox r" id="button-1">
                      <input type="checkbox" class="checkbox" />
                      <div class="knobs"></div>
                      <div class="layer"></div>
                    </div>
                  </div>
                </div>


              </div>
              <div class="sidebar_pane6_checkbox">
                <h3>Snap Degrees</h3>

                <input placeholder="45" class="input_text1" />


              </div>
              <ul class="fancybox_endsession1">
                <li><a href="#" class="button_blue"><span>SAVE</span></a></li>
                <li><a href="#" class="button_gray"><span>Cancel</span></a></li>
              </ul>
            </div>
          </div>
        </Popup>


        <Popup open={this.state.openScenarioList} modal>
          <div class="fancybox_popup builderWideModal">
            <h3>Load Scenario</h3>
            <a className="popup_close" onClick={this.closeModalScenarioList}></a>
            <div class="fancybox_popup_width scrollable-div">
              <table>
                <tr>
                  <td><p>NAME</p></td>
                  <td><p>LAST MODIFIED</p></td>
                </tr>
                {this.state.scenarios.map((scenario,i) => (
                  <tr key={i}>
                    <td><a href={'/builder/' + scenario.id}>{scenario.title}</a></td>
                    <td>{scenario.updatedAt}</td>
                  </tr>
                ))}

              </table>

            </div>
          </div>
        </Popup>

        <Popup open={this.state.openSaveConfirm} modal>
          <div class="fancybox_popup builderWideModal">
            <h3>Save Scenario</h3>
            <a className="popup_close" onClick={this.closeSaveConfirm}></a>
            <div class="builder30left_popup">
              {this.state.scenarioSavedName}<br />
              HAS BEEN SAVED.

              <ul class="fancybox_endsession1">
                <li><a href="#" onClick={this.closeSaveConfirm} class="button_blue"><span>OK</span></a></li>
              </ul>
            </div>
          </div>
        </Popup>


        <Popup open={this.state.openTheme} modal>
          <div class="fancybox_popup builderWideModal">
            <h3>Theme Data</h3>
            <a className="popup_close" onClick={this.closeTheme}></a>
            <div class="builder30left_popup">
              <textarea class="pwa03_buildertextarea" onChange={evt => this.updateThemeTemp(evt)}>{this.state.theme}</textarea>
              <ul class="fancybox_endsession1">
                <li><a href="#" onClick={this.saveTheme} class="button_blue"><span>SAVE</span></a></li>
              </ul>
            </div>
          </div>
        </Popup>


        <Popup open={this.state.openError} modal>
          <div class="fancybox_popup builderWideModal">
            <h3>Error Saving</h3>
            <a className="popup_close" onClick={this.closeError}></a>
            <div class="builder30left_popup">
              {this.state.error.map((error,i) => (
                <p key={i}>{error}</p>
              ))}
              <ul class="fancybox_endsession1">
                <li><a href="#" onClick={this.closeError} class="button_blue"><span>OK</span></a></li>
              </ul>
            </div>
          </div>
        </Popup>

        <Popup open={this.state.openLoading} modal closeOnDocumentClick={false} lockScroll={true} closeOnEscape={false}>
          <div class="fancybox_popup builderWideModal">
            <h3>Loading</h3>
            <div class="builder30left_popup">
              {this.state.loadingMsg}
            </div>
          </div>
        </Popup>

        <Popup open={this.state.openThreeErr} modal closeOnDocumentClick={false} lockScroll={true} closeOnEscape={false}>
          <div class="fancybox_popup builderWideModal">
            <h3>Error</h3>
            <div class="builder30left_popup">
              {this.state.threeErr}
            </div>
          </div>
        </Popup>

        <Popup open={this.state.openSaveAs} modal closeOnDocumentClick={false} lockScroll={true} closeOnEscape={false}>
          <div id="settings-edit-scenario" class="fancybox_popup">
            <h3>Save As</h3>
            <a className="popup_close" onClick={this.closeSaveAs}></a>

            <div class="builder05left_popup">
              <div class="row m-0">
                <div class="col-md-12 p-0">
                  <div class="builder05contnet_popup">
                    <h4>Scenario Name</h4>
                    <input type="text" value={this.state.scenarioSaveAs} onChange={this.handleSaveAsUpdate} />

                    {!this.state.scenarioSaveAsValid && <div className="error">{this.state.saveAsError}</div>}
                    <div>
                      <a href="#" onClick={this.saveAs} className={this.state.scenarioSaveAsValid === true ? 'button_blue' : 'button_gray disabled'}><span>Save</span></a>
                      <a href="#" onClick={this.closeSaveAs} className="button_gray"><span>Cancel</span></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Popup>



        <footer> </footer>

      </div>
    );
  }

}

export default Builder_Component;
