import React, { Component, useState } from "react";
import Header from "../../helpers/Header";
import ThreeDScene from '../../webxr/Scene';
import Auth from "../../utility/auth";

const authCheck = new Auth();
class SessionReview extends Component {

    constructor(props) {
      super(props);

      this.scene = React.createRef();
      this.state = this.getInitialState();

    }

    getInitialState = () => ({
        frameTime: 0
    });

    dataUpdate(data) {

        
        if (data.reset) {
            console.log("reset now");
        }

    }

    async componentDidMount() {
        
        document.title = process.env.REACT_APP_TITLE_PREFIX + "Run Session";

        // process props
        if (this.props.sessionId) {
            this.setState({sessionId: this.props.sessionId});
            this.scene.current.loadSession(this.props.sessionId);
        }

        // Check Perms
        authCheck.checkPerms(["Administrator", "Trainer", "Editor"]);
    }
	
      render() {

  return (
    <div>

<Header section="RUN SESSION" liteMode={false} />

<section>

 <div class="left_sidebar">
 	  <div class="container-fluid p-0">
       <div id="scene">
            <ThreeDScene dataCallBack={this.dataUpdate} ref={this.scene} mode="WEB" />
        </div>
      </div>
 </div>
 
</section>
<footer> </footer>

    </div>
  );
}
}

export default SessionReview;
