import React, { Component, useEffect } from "react";
import { Link } from "react-router-dom";
import Popup from 'reactjs-popup';
import Auth from "../utility/auth";

const authCheck = new Auth();

let ipAddr = "192.168.1.34";
let team = "Team Bravo";

// Auth Refresh
let authRefreshTimer = null;
let authRefreshUpdateTimer = 300000;
class Header extends Component {

    constructor(props) {
        super(props);
        this.state = {
            header: false,
            openAbout: false,
            liteMode: false,
            section: "",
            meshStatus: "",
            fullName: "Wally West"
        };

        this.aboutPopup = this.aboutPopup.bind(this);
        this.tokenRefresh = this.tokenRefresh.bind(this);
    }

    aboutPopup() {
        this.setState({ openAbout: !this.state.openAbout });
    }

    handleMesh(opt) {
        this.props.meshCallback(opt);
    }

    handleHeaderToggle = () => {
        this.setState({ header: !this.state.header });
    };

    handleCut() {
        this.props.cutCallback();
    }

    handleCopy() {
        this.props.copyCallback();
    }

    handlePaste() {
        this.props.pasteCallback();
    }

    handleDelete() {
        this.props.delCallBack();
    }

    tokenRefresh() {
        authCheck.tokenExpireCheck();

        authRefreshTimer = setTimeout(this.tokenRefresh, authRefreshUpdateTimer);
    }
    
    componentDidMount() {
        const userDataString = localStorage.getItem('userData');

        if (userDataString !== null) {
            const userData = JSON.parse(userDataString);
            this.setState({ fullName : userData.firstName + " " + userData.lastName });
        }

        authRefreshTimer = setTimeout(this.tokenRefresh, authRefreshUpdateTimer);
        
    }

    componentWillUnmount() {
        clearTimeout(authRefreshTimer);
    }

    handleSave() {
        this.props.saveCallBack();
    }

    handleSaveAs() {
        this.props.saveAsCallBack();
    }

    handleLoad() {
        this.props.loadCallBack();
    }

    handleRevert() {
        this.props.revertCallBack();
    }

    handlePref() {
        this.props.prefCallBack();
    }

    static getDerivedStateFromProps(props, state) {
        if (props.section && props.section !== state.section) {
            return {
                section: props.section
            };
        }

        if (props.liteMode !== state.liteMode) {
            return {
                liteMode: props.liteMode
            };
        }

        if (props.meshStatus && props.meshStatus !== state.meshStatus) {
            return {
                meshStatus: props.meshStatus
            };
        }
        return {}
    }

    render() {
        return (

            <header>
                <div class="header">
                    <div class="header_left_menu">
                        <div class="header_logo_left">
                            <Link class="header_logo" to="/home/"><img src="/images/logo.png" alt="" /></Link>
                            {this.props.section && (<h3>{this.props.section}</h3>)}
                            <a href="#" onClick={this.handleHeaderToggle} class="header_arrow_down"><img src="/images/header_arrow_down.png" alt="" /> </a>

                            <ul className={`header_dropdownmap ${this.state.header === false ? "" : "highdropdown"}`}>
        {authCheck.checkPermLevel(["Administrator", "Trainer", "Editor"]) ? <li><Link to="/session">RUN SESSION</Link></li> : ""}
        {authCheck.checkPermLevel(["Administrator", "Editor"]) ? <li><Link to="/builder">BUILD SCENARIO</Link></li> : ""}
        {authCheck.checkPermLevel(["Administrator", "Trainer"]) ? <li><Link to="/settings/sessions">PAST SESSIONS</Link></li> : ""}
        {authCheck.checkPermLevel(["Administrator"]) ? <li><Link to="/settings/users">MANAGE USERS</Link></li> : ""}
        {authCheck.checkPermLevel(["Administrator", "Trainer", "Editor"]) ? <li><Link to="/settings">SETTINGS</Link></li>  : ""}
                            </ul>
                        </div>
                        {this.props.isBuilder && (
                            <ul class="header_menu_min">
                                <li><a href="#">FILE</a>
                                    <ul class="header_submenu">
                                        <li><a href="/builder/">New Scenario</a></li>
                                        <li><a href="#" onClick={() => this.handleSave()}>Save Scenario</a></li>
                                        <li><a href="#" onClick={() => this.handleSaveAs()}>Copy Scenario</a></li>
                                        <li><a href="#" onClick={() => this.handleLoad()}>Load Scenario </a></li>
                                        <li><a href="#" onClick={() => this.handleRevert()}>Revert to Last Saved</a></li>
                                    </ul>
                                </li>
                            </ul>
                        )}
                        {(this.props.isBuilder || this.state.liteMode === true) && (
                            <ul class="header_menu_min">
                                <li><a href="#">EDIT</a>
                                    <ul class="header_submenu">
                                        <li><a href="#" onClick={() => this.handleCopy()}>Copy <span>Ctrl-C</span></a></li>
                                        <li><a href="#" onClick={() => this.handleCut()}>Cut <span>Ctrl-X</span></a></li>
                                        <li><a href="#" onClick={() => this.handlePaste()}>Paste <span>Ctrl-V</span></a></li>
                                        <li><a href="#" onClick={() => this.handleDelete()}>Delete <span>DEL</span></a></li>
                                        {this.props.isBuilder && (<li class="header_submenu_edit"><a href="#" onClick={() => this.handlePref()}>Preferences</a></li>)}
                                    </ul></li>
                                <li><a href="#">VIEW</a>
                                    <ul class="header_submenu header_submenuview" >
                                        <li><h3>SPATIAL MESH</h3></li>
                                        <li><label class="manageusers15_pagecheckbox pageradio" for="radio1">Turn off mesh<input id="radio1" name="radio1" type="radio" checked={this.props.meshStatus === "off" ? "checked" : ""} onChange={() => this.handleMesh("off")} /><span class="checkmark"></span></label></li>
                                        <li><label class="manageusers15_pagecheckbox pageradio" for="radio3">Color<input id="radio3" name="radio1" type="radio" checked={this.props.meshStatus === "color" ? "checked" : ""} onChange={() => this.handleMesh("color")} /><span class="checkmark"></span></label></li>
                                    </ul>
                                </li>
                                <li><a href="#">HELP</a>  <ul class="header_submenu">
                                    <li><a href="/files/VITA_Manual_v1_FPO.pdf" target="_blank">Help</a></li>
                                    <li><a href="#" onClick={() => this.aboutPopup()}>About Vita</a></li>
                                </ul></li>
                            </ul>
                        )}
                    </div>
                    <div>
                        <div class="header_right">
                            <div class="header_right_menu">
                                <ul>
    	<li><a href="#">{team} <span>{ipAddr}</span></a></li>
        <li><a href="#">{this.state.fullName}</a></li>
                                </ul>
                            </div>
    <div class="header_logout"><a href="/logout" class="button_gray"><span>Logout</span></a></div>
                        </div>
                    </div>
                </div>

                <Popup open={this.state.openAbout} modal closeOnDocumentClick={false} lockScroll={true} closeOnEscape={false}>
                    <div class="fancybox_popup builderWideModal">
                        <h3>ABOUT</h3>
                        <a href="#" className="popup_close" onClick={this.aboutPopup}></a>
                        <div class="builder05contnet_popup ">
                            <div><img src="/images/logo-about.png" width="274" alt="" /></div>
                            <div>verison {process.env.REACT_APP_RELEASE}</div>
                            <div>&nbsp;</div>
                            <div>www.vitaurlTBD.com</div>
                            <div>Copyright &copy; {(new Date().getFullYear())} ML Horizons</div>
                            <div>VITA is an extensible platform that provides unparalleled Augmented Reality training. The VITA system enables end-users and organizations to create and execute immersive, realistic, low-overhead, high-repetition training experiences - anywhere, anytime.</div>
                        </div>
                    </div>
                </Popup>

            </header>

        );
    }
}

export default Header;
