import React, { Component, useState } from "react";

import * as THREE from 'three';
import { OrbitControls} from 'three/examples/jsm/controls/OrbitControls';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import Stats from 'three/examples/jsm/libs/stats.module';
import { WEBGL } from 'three/examples/jsm/WebGL';


import Loader from '../../webxr/Loader';
import filesAPI from '../../utility/files';

const filesClient = new filesAPI();
const assetLoader = new Loader();

var container, renderer, cameraPersp, currentCamera, scene, orbit, control;

const stats = Stats();
const clock = new THREE.Clock()

let mixer, session;

let gl, referenceSpace, canvas, baselayer;
const { XRWebGLLayer } = window;

class AnimationsAR extends Component {

  constructor(props) {
    super(props);
    this.state = this.getInitialState();

    this.startScene = this.startScene.bind(this);
    this.renderScene = this.renderScene.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);
    this.animate = this.animate.bind(this);

    
    this.FBXAnimLoadFinish = this.FBXAnimLoadFinish.bind(this);
    this.FBXLoadFinishByStand = this.FBXLoadFinishByStand.bind(this);

    this.startWebXrScene = this.startWebXrScene.bind(this);
  }

  getInitialState = () => ({
    isLoaded: false
  });

  async startWebXrScene() {
    console.log("startWebXrScene");
    if (WEBGL.isWebGLAvailable()) {
        try {
            // canvas
            canvas = document.createElement("canvas");
            document.getElementById("webxr").appendChild(canvas);
            gl = canvas.getContext("webgl", {xrCompatible: true});

            scene = new THREE.Scene();

            renderer = new THREE.WebGLRenderer({
                alpha: true,
                preserveDrawingBuffer: true,
                canvas: canvas,
                context: gl
            });
            renderer.autoClear = false;

            currentCamera = new THREE.PerspectiveCamera();
            currentCamera.matrixAutoUpdate = false;

            // constant light to everything
            const light = new THREE.AmbientLight();
            scene.add(light);
            
            session = await navigator.xr.requestSession('immersive-ar');
            /*
            session = await navigator.xr.requestSession('immersive-ar', {
                optionalFeatures: ['dom-overlay', 'dom-overlay-for-handheld-ar'],
                domOverlay: {root: document.body}
            });
            */

            // three render
            baselayer = new XRWebGLLayer(session, gl);
            session.updateRenderState({
                baseLayer: baselayer
            });

            referenceSpace = await session.requestReferenceSpace('local');
            
            // Start Animation
            session.requestAnimationFrame(this.animate);
            //this.animate();

            // Start demo solider load
            let newFBX = {position: {}, scale: {}, rotation: {}};

            // new files
            await assetLoader.loadFBXLocalAsync("/temp/bystander@IdleBreatheHandsUp.fbx", this.FBXLoadFinishByStand, newFBX);


        } catch (error) {
            //this.displayPopup("Error", error);
          console.log("StartXR: " + error);
        }

    } else {
      console.log("WEBGL: " + WEBGL.getWebGLErrorMessage());
        //const warning = WEBGL.getWebGLErrorMessage();
        //this.displayPopup("Error", warning);
    }
}

  async startScene() {
    
    container = document.getElementById('webxr');

    container.addEventListener('mousedown', this.onWindowResize);

    renderer = new THREE.WebGLRenderer({antialias: true});
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);

    container.appendChild( renderer.domElement );
    const aspect = window.innerWidth / window.innerHeight;
    cameraPersp = new THREE.PerspectiveCamera(50, aspect, 0.01, 30000);
    currentCamera = cameraPersp;

    currentCamera.position.set(0, 1, 10);

    scene = new THREE.Scene();
    scene.add(new THREE.GridHelper(40, 40, 0x888888, 0x444444));

    // constant light to everything
    const light = new THREE.AmbientLight();
    scene.add(light);


    orbit = new OrbitControls(currentCamera, renderer.domElement);
    orbit.update();
    orbit.addEventListener('change', this.renderScene);

    control = new TransformControls(currentCamera, renderer.domElement);
    control.addEventListener('change', this.renderScene);

    control.addEventListener('dragging-changed', function (event) {
      orbit.enabled = ! event.value
    } );

    window.addEventListener('resize', this.onWindowResize);

    
    window.addEventListener('keydown', this.handleKeydown);
    window.addEventListener('keyup', this.handleKeyup);

    // Start Animation
    this.animate();

    // Start demo solider load
    let newFBX = {position: {}, scale: {}, rotation: {}};

    // new files
    await assetLoader.loadFBXLocalAsync("/temp/bystander@IdleBreatheHandsUp.fbx", this.FBXLoadFinishByStand, newFBX);

    stats.setMode(0);
    stats.domElement.style.position = 'absolute';
    stats.domElement.style.left = '150px';
    stats.domElement.style.top = '0';
    stats.domElement.id = "webXrStats";

    document.body.appendChild(stats.domElement);

  }

  onWindowResize() {
    /*
    const aspect = window.innerWidth / window.innerHeight;

    cameraPersp.aspect = aspect;
    cameraPersp.updateProjectionMatrix();

    renderer.setSize(window.innerWidth, window.innerHeight);

    this.renderScene();
    */
  }

  animate(time, frame) {
    session.requestAnimationFrame(this.animate);

    // Bind the graphics framebuffer to the baseLayer's framebuffer
    let glLayer = session.renderState.baseLayer;
    gl.bindFramebuffer(gl.FRAMEBUFFER, glLayer.framebuffer);
    
    // Retrieve the pose of the device.
    // XRFrame.getViewerPose can return null while the session attempts to establish tracking.
    const pose = frame.getViewerPose(referenceSpace);
    let poseRef;

    if (pose) {

      // In mobile AR, we only have one view.
      const view = pose.views[0];

      const viewport = glLayer.getViewport(view);
      renderer.setSize(viewport.width, viewport.height)

      // Use the view's transform matrix and projection matrix to configure the THREE.camera.
      currentCamera.matrix.fromArray(view.transform.matrix);
      currentCamera.projectionMatrix.fromArray(view.projectionMatrix);
      currentCamera.updateMatrixWorld(true);
    }

    if (this.state.isLoaded) {
      var delta = clock.getDelta();
      
      if (mixer) {
        mixer.update(delta);

        if (scene.getObjectByName("combinedByStand").position.z < 25) {
          scene.getObjectByName("combinedByStand").position.z = scene.getObjectByName("combinedByStand").position.z + 0.01;
        }
      }

    } 

    stats.update();

    this.renderScene();
  }

  renderScene() {
    renderer.render(scene, currentCamera);
  }
  
  async FBXLoadFinishByStand(object, assetObj) {
    object.name = "combinedByStand";
    object.scale.set(0.01, 0.01, 0.01);

    object.position.set(0, -1.524, -5);
    object.animations = "";

    //object.children[0].name = "van123";

    object.traverse(function (child) {
        if (child.isMesh) {
            child.castShadow = true;
            child.receiveShadow = true;
        }
    });

    // add to scene  
    scene.add(object);
    mixer = new THREE.AnimationMixer(object.children[0]);

    await assetLoader.loadFBXAnimAsyncLOCAL("/temp/Humanoid@WalkForwardScared.fbx", this.FBXAnimLoadFinish, object);
    // mark as loaded
    this.setState({isLoaded: true});


  }

  async FBXAnimLoadFinish(objectAnim, object) {
    const action = mixer.clipAction(objectAnim.animations[0]);
    action.play();
  }

  

  async componentDidMount() {
    //this.startScene();
    this.startWebXrScene();
    
  }

  render() {

  return (
      <div>
        <div id="webXrStats"></div>
        <div id="webxr"></div>
        <button onClick={this.startWebXrScene}>START AR</button>
      </div>
      
  );
}

}

export default AnimationsAR;