import React, {useState} from "react";
import {Link} from "react-router-dom";

function Debug_Header() {

    return (

        <header class="debugHeader">
            <div>DEBUG MENU</div>
            <div>
                <ul>
                    <li><Link to="/debug/match">Debug Match</Link></li>
                    <li><Link to="/debug/sessions">Debug Sessions</Link></li>
                    <li><Link to="/debug/perms">Debug Perms</Link></li>
                    <li><Link to="/home">&larr; Back to website</Link></li>
                </ul>
            </div>

        </header>
    );

}

export default Debug_Header;
