import React, { Component } from "react";

import Header from "../../helpers/Header";
import ThreeDScene from '../../webxr/Scene';
import TabHeader from "../../components/TabHeader";
import Auth from "../../utility/auth";

const authCheck = new Auth();

const setStateAsync = (obj, state) => {
    return new Promise((resolve) =>
        obj.setState(state, resolve)
    )
}

class Spectate_View extends Component {

    constructor(props) {
        super(props);

        this.scene = React.createRef();
    }

    dataUpdate(data) {

        
        if (data.reset) {
            console.log("reset now");
        }

    }


    async componentDidMount() {

        document.title = process.env.REACT_APP_TITLE_PREFIX + "Spectate Session";

        // Check Perms
        authCheck.checkPerms(["Administrator", "Trainer", "Editor", "Participant"]);

    }

    componentWillUnmount() {
    }

    render() {
        document.body.className = 'home_landing';

        return (
            <div>
            <Header 
            section="Spectate" 
            active_session={true} 
            />
            <section>
                <div class="container-fluid p-0">
                    <ThreeDScene sessionId={this.props.toloadId} dataCallBack={this.dataUpdate} />
                </div> 
            </section>

            </div>
        );
    }

}

export default Spectate_View;
