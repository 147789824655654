import React, {useState} from "react";
import {Link} from "react-router-dom";
import Auth from "../../utility/auth";

const authCheck = new Auth();

function SettingsNav({active_session}) {

    const linkClass = (n) => {
        const u = window.location.href;
        const nr = new RegExp(n+'$');

        if (nr.test(u)) {
            return 'active';
        }
    };

    return (

        <div className="col-md-3 p-0">
            <div className="leftsidebar_settings">
                <ul>
                    {authCheck.checkPermLevel(["Administrator", "Trainer"]) ? <li className={linkClass('settings')}><Link to="/settings">Server settings</Link></li> : ""}
                    {authCheck.checkPermLevel(["Administrator", "Editor"]) ? <li className={linkClass('scenarios')}><Link to="/settings/scenarios">Scenarios</Link></li> : ""}
                    {authCheck.checkPermLevel(["Administrator", "Editor"]) ? <li className={linkClass('assets')}><Link to="/settings/assets">Manage Assets</Link></li> : ""}
                </ul>
            </div>
        </div>
    );

}

export default SettingsNav;
