import React, { Component, useState } from "react";
import {Link} from "react-router-dom";

import Debug_Header from "./debug_header";
import filesAPI from '../../utility/files';
const filesClient = new filesAPI();

class Perms extends Component {

  constructor(props) {
    super(props);
    this.state = {
      perms: "",
      token: "",
      refreshToken: "",
      tokenExpire: 0,
    }

    
  }

  async setPerms(level) {

    console.log(level);

    localStorage.setItem('permLevel', level);
    this.setState({perms: level});
  }

  componentDidMount() {
    this.setState({perms: localStorage.getItem('permLevel')});

    this.setState({token: localStorage.getItem('token')});
    this.setState({refreshToken: localStorage.getItem('refreshToken')});
    this.setState({tokenExpire: localStorage.getItem('tokenExpire')});
  }

  render() {
  return (
      <div>
          <Debug_Header />

          <div>
            <div>Perms</div>
            <div>Current: <code>{this.state.perms}</code></div>
            <div>token: <code>{this.state.token}</code></div>
            <div>refreshToken: <code>{this.state.refreshToken}</code></div>
            <div>tokenExpire: <code>{this.state.tokenExpire}</code></div>
            <div>
                <ul>
                  <li><button onClick={() => this.setPerms("Administrator")}>Administrator</button></li>
                  <li><button onClick={() => this.setPerms("Trainer")}>Trainer</button></li>
                  <li><button onClick={() => this.setPerms("Editor")}>Editor</button></li>
                  <li><button onClick={() => this.setPerms("Participant")}>Participant</button></li>
                </ul>
            </div>

        </div>

      </div>
      
  );
}

}

export default Perms;