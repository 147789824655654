import React, {useState} from "react";
import Header from "../../helpers/Header";
import Settings_Nav from "./Settings_Nav";
import Auth from "../../utility/auth";

const authCheck = new Auth();

function Settings() {

    const [isCheckedNetwork, setIsCheckedNetwork] = useState(true);
    const [isCheckedMore, setIsCheckedMore] = useState(true);

    //
    document.body.className = 'pwa18_settings_server';
    document.title = process.env.REACT_APP_TITLE_PREFIX + "Server Settings";


    // Check Perms
    authCheck.checkPerms(["Administrator", "Trainer"]);

    return (
        <div>

            <Header section="SETTINGS"/>
            <section>

                <div class="left_sidebar">
                    <div class="container-fluid p-0">
                        <div class="row m-0">
                            <Settings_Nav/>
                            <div class="col-md-9 p-0">

                                <div className="settings18_pagecontent">
                                    <div className="settings18_pagebox1">
                                        <h3>server Name</h3>
                                        <input className="settings18_pageboxinput" placeholder="Enter Name.."
                                               type="text"/>
                                    </div>
                                    <div className="settings18_pagebox1 flex-row settings18_pageboxcheckbox">
                                        <h3>Visible on network</h3>
                                        <div className="toggle-button-cover">
                                            <div className="button-cover">
                                                <div className="button_checkbox r" id="button-1">
                                                    <input type="checkbox" className="checkbox"
                                                           onChange={(event) => setIsCheckedNetwork(event.currentTarget.checked)}
                                                           checked={isCheckedNetwork}/>
                                                    <div className="knobs"></div>
                                                    <div className="layer"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="settings18_pagebox1">
                                        <h3>Other Settings</h3>
                                        <input className="settings18_pageboxinput"
                                               placeholder="" type="text"/>
                                    </div>
                                    <div className="settings18_pagebox1 flex-row settings18_pageboxcheckbox">
                                        <h3>More settings</h3>
                                        <div className="toggle-button-cover">
                                            <div className="button-cover">
                                                <div className="button_checkbox r" id="button-1">
                                                    <input type="checkbox" className="checkbox"
                                                           onChange={(event) => setIsCheckedMore(event.currentTarget.checked)}
                                                           checked={isCheckedMore}/>
                                                    <div className="knobs"></div>
                                                    <div className="layer"></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="settings18_pagebox1">
                                        <h3>Audio rooms</h3>
                                        <div className="settings18_pageaudio">
                                            <span>00</span>
                                            <p>All Comms (Default)</p>
                                        </div>
                                        <div className="settings18_pageaudio">
                                            <span>01</span>
                                            <input className="settings18_pageboxinput"
                                                   placeholder="Main Training Room" type="text"/>
                                        </div>
                                        <div className="settings18_pageaudio">
                                            <span>02</span>
                                            <input className="settings18_pageboxinput"
                                                   placeholder="Aux Training Room A" type="text"/>
                                        </div>
                                        <div className="settings18_pageaudio">
                                            <span>03</span>
                                            <input className="settings18_pageboxinput"
                                                   placeholder="Aux Training Room B" type="text"/>
                                        </div>
                                        <div className="settings18_pageaudio">
                                            <span>04</span>
                                            <input className="settings18_pageboxinput"
                                                   placeholder="Aux Training Room C" type="text"/>
                                        </div>
                                        <div className="settings18_pageaudio">
                                            <span>05</span>
                                            <input className="settings18_pageboxinput"
                                                   placeholder="Aux Training Room D" type="text"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>

            <footer></footer>
        </div>
    );
}

export default Settings;
