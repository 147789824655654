import React, { Component, useState } from "react";

import * as THREE from 'three';
import { OrbitControls} from 'three/examples/jsm/controls/OrbitControls';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls';
import Stats from 'three/examples/jsm/libs/stats.module';

import Loader from '../../webxr/Loader';
import filesAPI from '../../utility/files';

const filesClient = new filesAPI();
const assetLoader = new Loader();

var container, renderer, cameraPersp, currentCamera, scene, orbit, control;

const stats = Stats();
const clock = new THREE.Clock()

let mixer, mixer2;
let mixers;
// let thru mixers in update
// 

//let animationActions = THREE.AnimationAction[] = [];
//let activeAction = THREE.AnimationAction;
//let lastAction = THREE.AnimationAction;


//import { GUI } from 'three/src/extras/DataUtils';
//import { GUI } from 'three/examples/jsm/libs/';
//import { GUI } from './jsm/libs/lil-gui.module.min.js';

class Animations extends Component {

  constructor(props) {
    super(props);
    this.state = this.getInitialState();

    this.startScene = this.startScene.bind(this);
    this.renderScene = this.renderScene.bind(this);
    this.onWindowResize = this.onWindowResize.bind(this);
    this.animate = this.animate.bind(this);

    
    this.FBXLoadFinish = this.FBXLoadFinish.bind(this);
    this.FBXAnimLoadFinish = this.FBXAnimLoadFinish.bind(this);

    this.FBXLoadFinishByStand = this.FBXLoadFinishByStand.bind(this);

    //this.FBXLoadFinishTest = this.FBXLoadFinishTest.bind(this);

    this.FBXLoadFinishByStandBETA = this.FBXLoadFinishByStandBETA.bind(this);
    
    
    
    

  }

  getInitialState = () => ({
    isLoaded: false
  })

  async startScene() {
    
    container = document.getElementById('webxr');

    container.addEventListener('mousedown', this.onWindowResize);

    renderer = new THREE.WebGLRenderer({antialias: true});
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);

    container.appendChild( renderer.domElement );
    const aspect = window.innerWidth / window.innerHeight;
    cameraPersp = new THREE.PerspectiveCamera(50, aspect, 0.01, 30000);
    currentCamera = cameraPersp;

    currentCamera.position.set(0, 3, 10);

    scene = new THREE.Scene();
    scene.add(new THREE.GridHelper(40, 40, 0x888888, 0x444444));

    // constant light to everything
    const light = new THREE.AmbientLight();
    scene.add(light);


    orbit = new OrbitControls(currentCamera, renderer.domElement);
    orbit.update();
    orbit.addEventListener('change', this.renderScene);

    control = new TransformControls(currentCamera, renderer.domElement);
    control.addEventListener('change', this.renderScene);

    control.addEventListener('dragging-changed', function (event) {
      orbit.enabled = ! event.value
    } );

    window.addEventListener('resize', this.onWindowResize);

    
    window.addEventListener('keydown', this.handleKeydown);
    window.addEventListener('keyup', this.handleKeyup);

    // Start Animation
    this.animate();

    // Start demo solider load
    let newFBX = {position: {}, scale: {}, rotation: {}};
    
    // use later for testing
    //await assetLoader.loadFBXLocalAsync("/temp/bystander(1).fbx", this.FBXLoadFinishByStandBETA, newFBX);

    //await assetLoader.loadFBXLocalAsync("/temp/vanguard_t_choonyung.fbx", this.FBXLoadFinishTest, newFBX);


    await assetLoader.loadFBXLocalAsync("/temp/Soldier_noanims.fbx", this.FBXLoadFinish, newFBX);

    // new files
    //await assetLoader.loadFBXLocalAsync("/temp/bystander_noanims.fbx", this.FBXLoadFinishByStand, newFBX);
    //await assetLoader.loadFBXLocalAsync("/temp/bystander@ScaredHostage.fbx", this.FBXLoadFinishByStand, newFBX);
    //await assetLoader.loadFBXLocalAsync("/temp/bystander(1).fbx", this.FBXLoadFinishByStand, newFBX);

    
    

    stats.setMode(0);
    stats.domElement.style.position = 'absolute';
    stats.domElement.style.left = '150px';
    stats.domElement.style.top = '0';
    stats.domElement.id = "webXrStats";

    document.body.appendChild(stats.domElement);

  }

  onWindowResize() {
    
    const aspect = window.innerWidth / window.innerHeight;

    cameraPersp.aspect = aspect;
    cameraPersp.updateProjectionMatrix();

    renderer.setSize(window.innerWidth, window.innerHeight);

    this.renderScene();

  }

  animate() {
    
    
    requestAnimationFrame(this.animate);

    if (this.state.isLoaded) {
      var delta = clock.getDelta();
      //console.log("animate");
      //console.log(scene);
      // update movement

      if (scene.getObjectByName("combinedByStand").position.z < 25) {
        scene.getObjectByName("combinedByStand").position.z = scene.getObjectByName("combinedByStand").position.z + 0.01;
      }
      
      if (mixer) {
        mixer.update(delta);
      }
      
      if (mixer2) {
        mixer2.update(delta);
      }

    } 

    stats.update();

    this.renderScene();
  }

  renderScene() {
    /*
    if (scene.getObjectByName("bystander")) {
      scene.getObjectByName("bystander").scale.set(0.01, 0.01, 0.01);
      scene.getObjectByName("bystander").rotation.set(0, 0, 0);
      scene.getObjectByName("bystander").position.set(5, 0, 0);
    }

    if (scene.getObjectByName("solider")) {
      scene.getObjectByName("solider").scale.set(0.01, 0.01, 0.01);
      scene.getObjectByName("solider").rotation.set(0, 0, 0);
      scene.getObjectByName("solider").position.set(0, 0, 0);
    }
    */

    renderer.render(scene, currentCamera);
  }

  async FBXLoadFinishByStandBETA(object, assetObj) {
    object.name = "sepByStand";
    object.scale.set(0.01, 0.01, 0.01);

    object.position.set(0, 0, -5);
    object.animations = "";

    //object.children[0].name = "bonesnow";

    object.traverse(function (child) {
        if (child.isMesh) {
            child.castShadow = true;
            child.receiveShadow = true;
        }
    });

    // add to scene  
    scene.add(object);
    console.log("anim | object");
    console.log(object);
    mixer2 = new THREE.AnimationMixer(object.children[0]);


    // Load animations
    //await assetLoader.loadFBXAnimAsync("/temp/bystander@ScaredHostage.fbx", this.FBXAnimLoadFinish, object);

    await assetLoader.loadFBXAnimAsyncLOCAL("/temp/Humanoid@ScaredHostage.fbx", this.FBXAnimLoadFinish, object);

    // mark as loaded
    this.setState({isLoaded: true});


  }

  async FBXLoadFinishByStand(object, assetObj) {
    object.name = "combinedByStand";
    object.scale.set(0.01, 0.01, 0.01);

    object.position.set(0, 0, -5);
    object.animations = "";

    //object.children[0].name = "van123";

    object.traverse(function (child) {
        if (child.isMesh) {
            child.castShadow = true;
            child.receiveShadow = true;
        }
    });

    // add to scene  
    scene.add(object);
    console.log("obj + anim");
    console.log(object);
    mixer = new THREE.AnimationMixer(object.children[0]);

    //const action = mixer.clipAction(object.animations[0]);
    //action.play();

    //await assetLoader.loadFBXAnimAsync("/temp/Humanoid@WalkForwardScared.fbx", this.FBXAnimLoadFinish, object);

    // Load animations
    //await assetLoader.loadFBXAnimAsync("/temp/bystander@ScaredHostage.fbx", this.FBXAnimLoadFinish, object);

    //await assetLoader.loadFBXAnimAsync("/temp/Humanoid@CalmDown1Hostage.fbx", this.FBXAnimLoadFinish, object);

    // mark as loaded
    this.setState({isLoaded: true});


  }


  async FBXLoadFinish(object, assetObj) {
    object.name = "combinedByStand";
    object.scale.set(0.01, 0.01, 0.01);

    object.position.set(0, 0, -5);

    object.traverse(function (child) {
        if (child.isMesh) {
            child.castShadow = true;
            child.receiveShadow = true;
        }
    });

    // add to scene  
    scene.add(object);

    mixer = new THREE.AnimationMixer(object);

    // Load animations
    await assetLoader.loadFBXAnimAsyncLOCAL("/temp/Humanoid@Taunt3.fbx", this.FBXAnimLoadFinish, object);

    //await assetLoader.loadFBXAnimAsync("/temp/Humanoid@WalkForwardRightCombat.fbx", this.FBXAnimLoadFinish, object);

    // mark as loaded
    this.setState({isLoaded: true});


  }

  /*
  async FBXLoadFinishTest(object, assetObj) {
    object.name = "van";
    object.scale.set(0.01, 0.01, 0.01);

    object.position.set(5, 0, 5);

    object.traverse(function (child) {
        if (child.isMesh) {
            child.castShadow = true;
            child.receiveShadow = true;
        }
    });

    // add to scene  
    scene.add(object);

    mixer = new THREE.AnimationMixer(object);

    // Load animations
    await assetLoader.loadFBXAnimAsync("/temp/vanguard@samba.fbx", this.FBXAnimLoadFinish, object);

    // mark as loaded
    this.setState({isLoaded: true});


  }
*/
  async FBXAnimLoadFinish(objectAnim, object) {

    /*
    objectAnim.scale.multiplyScalar(0.01);

    objectAnim.traverse(function (child) {
      child.scale.set(0.01, 0.01, 0.01);

      child.rotateX(0);
      child.rotateY(0);
      child.rotateZ(0);
    });
    */
    
    
    //objectAnim.animations[0].tracks.shift();
    //objectAnim.animations[0].tracks.shift();
    //objectAnim.animations[0].tracks.shift();
    //objectAnim.animations[0].tracks.shift();
    //objectAnim.animations[0].tracks.shift();
    //objectAnim.animations[0].tracks.shift();
    

    //console.log(objectAnim.animations[0].toJSON());
    //console.log("objectobject");
    //console.log(object);
    //console.log(object.children[0]);

    console.log("objectAnim");
    console.log(objectAnim);
    //console.log(objectAnim, object.children[3]);
    //console.log(objectAnim.animations[0].tracks);
    for(var i=0; i<objectAnim.animations[0].tracks.length; i++) {
      //console.log(objectAnim.animations[0].tracks[i]);
      //
      var cur = objectAnim.animations[0].tracks[i];
      // root.quaternion
      // Humanoid_.quaternion
      if (/\.scale/i.test(cur.name) || /\.position/i.test(cur.name) || /root\.quaternion/i.test(cur.name) || /Humanoid_\.quaternion/i.test(cur.name)) {
        //objectAnim.animations[0].tracks.splice(i, 1);
        //i--;
      }

      if (/root\.quaternion/i.test(cur.name) || /\.quaternion/i.test(cur.name)) {
        //objectAnim.animations[0].tracks.splice(i, 1);
        //i--;
      }
      
      
    }

    //console.log(objectAnim.animations[0].tracks);
    //console.log(object);
    console.log("animat");
    console.log(objectAnim.animations[0]);


    objectAnim.animations[0].name = "BrianClip";
    //const action = mixer.clipAction(objectAnim.animations[0], object.children[0]);
    if (object.name == "sepByStand") {
      const action = mixer2.clipAction(objectAnim.animations[0]);
      action.play();
    } else {
      //mixer.clipAction(objectAnim.animations[0]);
      //const clip = object.find((clip) => clip.name === 'BrianClip');

      //let test = mixer.findByName("BrianClip");
      mixer.clipAction(objectAnim.animations[0]);

      let action = mixer.existingAction('BrianClip');
      console.log("action");
      console.log(action);
      action.play();
    }
    

    //object.scale.multiplyScalar(0.01);

    //console.log(action);
  }

  

  async componentDidMount() {
    this.startScene();
    
  }

  render() {

  return (
      <div>
        <div id="webXrStats"></div>
        <div id="webxr"></div>

      </div>
      
  );
}

}

export default Animations;