import React, { Component } from "react";
import { Link } from "react-router-dom";
import Popup from 'reactjs-popup';
import ThreeDScene from '../webxr/Scene';

import Header from "../helpers/Header";
import filesAPI from '../utility/files';
import Auth from "../utility/auth";

const authCheck = new Auth();

const filesClient = new filesAPI();

class Home extends Component {

  constructor(props) {
    super(props);
    
    this.state = this.getInitialState();
    
    // 3D Scene
    this.scene = React.createRef();

    this.modalSessionToggle = this.modalSessionToggle.bind(this);
    this.closeModalSessionList = this.closeModalSessionList.bind(this);
    this.updateSessionList = this.updateSessionList.bind(this);
  }

  getInitialState = () => ({
    openSessionList: false,
    sessions: []
  });

  modalSessionToggle() {
    this.setState({openSessionList: !this.state.openScenarioList});
  }

  closeModalSessionList() {
    this.setState({openSessionList: false});
  }

  async updateSessionList() {
    let sessionList = await filesClient.getSessionsList();
    
    if (sessionList.isSuccess === true && sessionList.sessions) {
      this.setState({sessions: sessionList.sessions})
    } else {
      // display error connectiong to files API
    }
  }

  async componentDidMount() {
    document.body.className = 'home_landing';
    document.title = process.env.REACT_APP_TITLE_PREFIX + "Home";

    this.updateSessionList();

    // Check Perms
    authCheck.checkPerms(["Administrator", "Trainer", "Editor", "Participant"]);
  }

  render() {

    let sessionCount = 0;

    sessionCount = this.state.sessions.filter(sessionCheck => sessionCheck.matchId).length;

    return (
      <div class="menu">
      <Header />
        <section>
          <div class="home_landing_content">
            <div class="home_landing_menuleft">
              <ul>
                {authCheck.checkPermLevel(["Administrator", "Trainer", "Editor"]) ? <li class="landing_menusessionicon active"><Link to="/session"><span class="menu_span_icon">Run session</span></Link></li> : ""}
                {authCheck.checkPermLevel(["Administrator", "Trainer", "Editor", "Participant"]) ? <li class="landing_menusessionicon active"><a href="#" onClick={this.modalSessionToggle}><span class="menu_span_icon">{this.state.sessions.length > 0 ? "Session in progress" : "Spectate Session"}</span>{sessionCount > 0 ? <span class="menu_span_hover">spectate</span> : ""}</a></li> : ""}
                {authCheck.checkPermLevel(["Administrator", "Editor"]) ? <li class="landing_menubuildicon"><Link to="/builder/"><span class="menu_span_icon">Build scenario</span> </Link></li> : ""}
                {authCheck.checkPermLevel(["Administrator", "Trainer"]) ? <li class="landing_menupasticon"><Link to="/settings/sessions/"><span class="menu_span_icon">Past Sessions</span></Link></li> : ""}
                {authCheck.checkPermLevel(["Administrator"]) ? <li class="landing_menumanageicon"><Link to="/settings/users/"><span class="menu_span_icon">Manage users</span></Link></li> : ""}
                {authCheck.checkPermLevel(["Administrator", "Trainer", "Editor"]) ? <li class="landing_menusettingsicon"><Link to="/settings/"><span class="menu_span_icon">Settings</span></Link></li> : ""}
              </ul>
              </div>
          </div>
          {/* <div id="scene"><ThreeDScene dataCallBack={()=>{}} ref={this.scene} mode="WEB" builderMode="lite" /></div> */}
        </section>

        <footer> </footer>


        <Popup open={this.state.openSessionList} modal>
          <div class="fancybox_popup builderWideModal">
                  <h3>SELECT SESSION</h3>
                  <a className="popup_close" onClick={this.closeModalSessionList}></a>
                  <div class="fancybox_popup_width scrollable-div">
                      <table>
                        <tbody>
                          <tr>
                              <td><p>SESSION NAME</p></td>
                              <td><p>FACILITATOR</p></td>
                          </tr>
                          {this.state.sessions.filter(sessionCheck => sessionCheck.matchId && sessionCheck.replayable === false).map(session => (
                            <tr>
                              <td><Link to={'/spectate/view/' + session.id}>{session.name}</Link></td>
                              <td></td>
                            </tr>
                          ))}
                          {this.state.sessions.filter(sessionCheck => sessionCheck.matchId && sessionCheck.replayable === true).map(session => (
                            <tr>
                              <td><Link to={'/session/review/' + session.id}>{session.name} (REPLAY)</Link></td>
                              <td></td>
                            </tr>
                          ))}
                        </tbody>
                      </table>        

                  </div>
              </div>
      </Popup>

    </div>
    );
  }


}

export default Home;
