import ConsoleEvents from './ConsoleEvents';

const axios = require('axios');

const fileFormats = {
    default:2,
    fbx:1,
    assetbundle:2,
    unity3d:2,
    obj:3,
    UNITY_HEADLESS_BUNDLE:4
}

export default class filesAPI {
    // Need to add errors if REST service is unreachable

    constructor() {
        this.apiURL = process.env.REACT_APP_FILES_API;
        this.analyticsURL = process.env.REACT_APP_ANALYTICS_API;
	}

    dateFormat(dt) {
        let d = new Date(dt),
            zPad = (s) => {
                return String(s).padStart(2,'0');
            };

        return (zPad(d.getMonth()+1))
                +'-'+zPad(d.getDate())
                +'-'+d.getFullYear()
                +' '+zPad(d.getHours())
                +':'+zPad(d.getMinutes())
                +':'+zPad(d.getUTCSeconds());
    }

    formatDateFields(obj) {
        Object.keys(obj).map(k => {
            if (typeof obj[k] === 'object' && obj[k]) {
                obj[k] = this.formatDateFields(obj[k]);
            }
            else if (/edat$/i.test(k)) {
                obj[k] = this.dateFormat(obj[k]);
            }
        });

        return obj;
    }

    humanFileSize(bytes, si=false, dp=1) {
        const thresh = si ? 1000 : 1024;

        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }

        const units = si
            ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
        let u = -1;
        const r = 10**dp;

        do {
            bytes /= thresh;
            ++u;
        } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


        return bytes.toFixed(dp) + ' ' + units[u];
    }

    // Simple API Wrappers
    async apiGet(url) {

        // Header / Config
        const token = localStorage.getItem('token');

        let config = {};
        if (token) {
            config = {
                headers: { Authorization: `Bearer ${token}` }
            };
        }

        let res = {};
        let error = false;

        try {
            res = await axios.get(url, config);
        } catch (errorRes) {
            res.data = [];
            res.data.errorMessage = errorRes;
            res.data.isSuccess = false;
            
            error = true;
        }

        if (error === false && res.data && res.data.isSuccess === true) {
            res.data = this.formatDateFields(res.data);
            return res.data;
        } else {
            // error
            return res.data.errorMessage
        }

        
    }

    async apiPut(url, data) {
        
        // Header / Config
        const token = localStorage.getItem('token');
        
        let config = {};
        if (token) {
            config = {
                headers: { Authorization: `Bearer ${token}` }
            };
        }

        let res = {};

        let error = false;

        try {
            res = await axios.put(url, data, config);
        } catch (errorRes) {
            res.data = [];
            res.data.errorMessage = errorRes;
            res.data.isSuccess = false;

            error = true;
        }

        if (error === false && res.data && res.data.isSuccess === true) {
            return res.data;
        } else {
            // error
            return res.data;
        }
    }

    async apiDelete(url) {

        // Header / Config
        const token = localStorage.getItem('token');
        
        let config = {};
        if (token) {
            config = {
                headers: { Authorization: `Bearer ${token}` }
            };
        }

        let res = {};

        let error = false;

        try {
            res = await axios.delete(url, config);
        } catch (errorRes) {
            res.data = [];
            res.data.errorMessage = errorRes;
            res.data.isSuccess = false;

            error = true;
        }

        if (error === false && res.data && res.data.isSuccess === true) {
            return res.data;
        } else {
            // error
            return res.data
        }

    }

    async apiPost(url, data) {

        // Header / Config
        const token = localStorage.getItem('token');
        
        let config = {};
        if (token) {
            config = {
                headers: { Authorization: `Bearer ${token}` }
            };
        }
        let res = {};

        let error = false;

        try {
            res = await axios.post(url, data, config);
        } catch (errorRes) {
            res.data = [];
            res.data.errorMessage = errorRes;
            res.data.isSuccess = false;

            error = true;
        }

        if (error === false && res.data && res.data.isSuccess === true) {
            return res.data;
        } else {
            // error
            return res.data
        }

    }

    async apiPostFile(url, file) {

        // Header / Config
        const token = localStorage.getItem('token');

        let config = {};
        if (token) {
            config = {
                headers: { Authorization: `Bearer ${token}`, 'Content-Type': 'multipart/form-data' }
            };
        } else {
            config = {
                headers: {'Content-Type': 'multipart/form-data' }
            };
        }

        let res = {};

        let error = false;

        try {
            const formData = new FormData();
            formData.append("file", file);

            res = await axios.post(url, formData, config);
        } catch (errorRes) {
            res.data = [];
            res.data.errorMessage = errorRes;
            res.data.isSuccess = false;

            error = true;
        }

        if (error === false) {

            if ( res.data && res.data.isSuccess === true) {
                return res.data;
            }

            res.data = [];
            res.data.isSuccess = true;
            return res.data;
        }  else {
            // error
            return res.data
        }

    }

    // Assets
    async createAsset(asset) {
        // const { name, classification, status, pwaScaleMultiplier, hmdScaleMultiplier } = asset;
        // let d = { name, classification, status, pwaScaleMultiplier, hmdScaleMultiplier};
        //     // const sn = saveName.split('.'),
        //     n = saveName.replace(new RegExp('.'+sn[sn.length-1]+'$'),'');

        return await this.apiPost(this.apiURL + '/api/assets/create', asset);

        // if (ares.isSuccess === true && ares.asset) {
        //     data = {
        //         "schema": 1,
        //         "format": 2,
        //         "path": "",
        //         "md5sum": md5,
        //         "assetId": ares.asset.id,
        //         "title": n
        //     }
        //
        //     const fres = await this.apiPost(this.apiURL + '/api/files/create', data);;
        //
        //     if (fres.isSuccess === true && fres.file) {
        //         const ffres = await this.apiPostFile(this.apiURL + '/api/files/' + fres.file.id + '/upload', file);;
        //
        //         if (ffres.isSuccess === true) {
        //             return ffres;
        //         }
        //     }
        // }
    }

    async updateAsset(asset) {
        let {id, name, classification, status, pwaScaleMultiplier, hmdScaleMultiplier } = asset,
            d = { name, classification, status, pwaScaleMultiplier, hmdScaleMultiplier},
            res = {}, cres = false;

        if (!id) {
            cres = await this.createAsset(d)
        }

        if (asset.newLibraryId && (!cres && id || cres.isSuccess)) {
            if (cres.isSuccess) {
                id = cres.asset.id;
            }
            if (asset.originalLibraryId && asset.originalLibraryId != asset.newLibraryId) {
                res = await this.removeAssetFromLibrary(asset.originalLibraryId, id);
            }
            if (!asset.originalLibraryId || res.isSuccess) {
                res = await this.addAssetToLibrary(asset.newLibraryId, id);
                if (res.isSuccess && !cres) {
                    return await this.apiPut(this.apiURL + '/api/assets/' + id, d);
                }
                if (cres) {
                    return cres;
                }
            }
            return await this.apiPut(this.apiURL + '/api/assets/' + id, d);
        }

        else if (id) {
            return await this.apiPut(this.apiURL + '/api/assets/' + id, d);
        }

        return res;
    }

    async getAssetsList() {
        let res = await this.apiGet(this.apiURL + '/api/assets/list');

        let validAssets = {assets: []};
        validAssets.errorMessage = res.errorMessage;
        validAssets.isSuccess = res.isSuccess;

        if (res.isSuccess === true && res.assets) {
            for (let i = 0; i < res.assets.length; i++) {

                if (res.assets[i].classification === 0) {
                    res.assets[i].class = "unknown";
                } else if (res.assets[i].classification === 1) {
                    res.assets[i].class = "trigger";
                } else if (res.assets[i].classification === 2) {
                    res.assets[i].class = "waypoint";
                } else if (res.assets[i].classification === 3) {
                    res.assets[i].class = "sign";
                } else if (res.assets[i].classification === 4) {
                    res.assets[i].class = "mesh";
                } else if (res.assets[i].classification === 5) {
                    res.assets[i].class = "object";
                }

                if (res.assets[i].files) {

                    for (let f = 0; f < res.assets[i].files.length; f++) {

                        let newEntry = JSON.parse(JSON.stringify(res.assets[i]));
                        delete newEntry.files;

                        newEntry.fileId = res.assets[i].files[f].id;
                        newEntry.filePath = res.assets[i].files[f].path;
                        newEntry.fileType = res.assets[i].files[f].format;
                        newEntry.fileClassification = res.assets[i].files[f].classification;
                        newEntry.fileName = res.assets[i].files[f].title;
                        
                        if (res.assets[i].name !== null) {
                            validAssets.assets.push(newEntry);
                        }

                    }
                }
                
            }
        }
        validAssets.assets.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
   
        return validAssets;
    }

    async getAssetById(assetId) {
        let res = await this.apiGet(this.apiURL + '/api/assets/' + assetId);
        return res;
    }

    async createAssetEntity(aid, eid, lid) {
        let data = {};

        let res = await this.apiPost(this.apiURL + '/api/assets/' + aid + '/addEntity/' + eid, data);

        if (res.isSuccess) {
            res = await this.addAssetToLibrary(lid, aid);
        }

        return res;
    }

    async deleteAsset(aid, lid) {
        let res = await this.removeAssetFromLibrary(lid, aid);

        if (res.isSuccess) {
            res = await this.apiDelete(this.apiURL + '/api/assets/'+aid);
        }

        return res;
    }


    // Files
    async createFile(file, md5, saveName, assetId) {
        let sn = saveName.split('.'),
            ext = sn[sn.length-1],
            n = saveName.replace(new RegExp('.'+ext+'$'),'');

        ext = ext.toLowerCase();

        let data = {
            "schema": 1,
            "format": fileFormats[ext] ? fileFormats[ext] : fileFormats['default'],
            "path": "",
            "md5sum": md5,
            "assetId": assetId,
            "title": n,
            "classification": 0
        }

        const fres = await this.apiPost(this.apiURL + '/api/files/create', data);

        if (fres.isSuccess === true && fres.file) {
            const ffres = await this.apiPostFile(this.apiURL + '/api/files/' + fres.file.id + '/upload', file);

            if (ffres.isSuccess === true) {
                fres.file.path = '/api/files/' + fres.file.id + '/get/' + fres.file.id + '-' + fres.file.title + '.' + ext;
                fres.file.md5sum = md5;

                return fres;
            }
        }
    }

    async associateBundle(file, md5, saveName, assetId) {
        const sn = saveName.split('.'),
            n = saveName.replace(new RegExp('.'+sn[sn.length-1]+'$'),'');

        let data = {
            "schema": 1,
            "format": 2,
            "path": "",
            "md5sum": md5,
            "assetId": assetId,
            "title": n
        }

        const fres = await this.apiPost(this.apiURL + '/api/files/create', data);

        if (fres.isSuccess === true && fres.file) {
            const ffres = await this.apiPostFile(this.apiURL + '/api/files/' + fres.file.id + '/upload', file);

            if (ffres.isSuccess === true) {
                return ffres;
            }
        }
    }

    async getFilesList() {
        let res = await this.apiGet(this.apiURL + '/api/files/list');
        if (res.isSuccess === true && res.files) {
            return res;
        }

    }

    async updateFile(file) {
        const {id, schema, format, path, md5sum, title, classification, assetId } = file;
        let data = {schema, format, path, md5sum, title, classification, assetId};

        return await this.apiPut(this.apiURL + '/api/files/' + id, data);
    }

    async deleteFile(id) {
        return await this.apiDelete(this.apiURL + '/api/files/'+id);
    }

    async getFileById(fileId) {
        if (fileId) {
            let res = await this.apiGet(this.apiURL + '/api/files/' + fileId);

            if (res.isSuccess === true && res.file) {
                return res;
            }
        } else {
            // error if missing fileId
        }
    }


    // Entities
    async createEntity(data) {
        let res = await this.apiPost(this.apiURL + '/api/entities/create', data);
        return res;
    }

    async updateEntity(id, data) {
        let res = await this.apiPut(this.apiURL + '/api/entities/' + id, data);

        if (res.isSuccess === true && res.entity) {
            return res;
        }
    }

    async deleteEntity(entityId) {
        let res = await this.apiDelete(this.apiURL + '/api/entities/' + entityId);
        return res;

    }

    async getEntity(entityId) {
        let res = await this.apiGet(this.apiURL + '/api/entities/' + entityId);
        return res;
    }

    // Environments
    async createEnvironment(data) {
        let res = await this.apiPost(this.apiURL + '/api/environments/create', data);
        if (res.isSuccess === true && res.environment) {
            return res;
        }
    }


    // Libraries
    async createLibrary(data) {
        let res = await this.apiPost(this.apiURL + '/api/libraries/create', data);
        if (res.isSuccess === true && res.library) {
            return res;
        }
    }

    async getLibrariesList() {
        let res = await this.apiGet(this.apiURL + '/api/libraries/list');

        let libraries = {libraries: []};
        libraries.errorMessage = res.errorMessage;
        libraries.isSuccess = res.isSuccess;

        if (res.isSuccess === true) {
            libraries.libraries = res.libraries.map(l => {
                l.name = l.name ? l.name : '';
                l.assets = l.assets ? l.assets : [];
                return l});
            res.libraries && libraries.libraries.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        }

        return libraries;
    }

    async deleteLibrary(id) {
        return await this.apiDelete(this.apiURL + '/api/libraries/'+id);
    }

    async updateLibrary(library) {
        let {id, name } = library,
            d = { name };

        return await this.apiPut(this.apiURL + '/api/libraries/' + id, d);
    }

    async removeAssetFromLibrary(lid, aid) {
        return await this.apiPost(this.apiURL + '/api/libraries/' + lid + '/removeAsset/' + aid);
    }

    async addAssetToLibrary(lid, aid) {
        return await this.apiPost(this.apiURL + '/api/libraries/' + lid + '/addAsset/' + aid);
    }

    async getLibrariesListFormatted() {
        let res = await this.apiGet(this.apiURL + '/api/libraries/list');

        let libraries = {libraries: []};
        libraries.errorMessage = res.errorMessage;
        libraries.isSuccess = res.isSuccess;
		
		let validAssets = {assets: []};
        validAssets.errorMessage = res.errorMessage;
        validAssets.isSuccess = res.isSuccess;

        if (res.isSuccess === true) {
            libraries.libraries = res.libraries.map(l => {
                l.name = l.name ? l.name : '';
                l.assets = l.assets ? l.assets : [];
                return l});
            res.libraries && libraries.libraries.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
			
			for (let i = 0; i < libraries.libraries.length; i++) {
				for (let a = 0; a < libraries.libraries[i].assets.length; a++) {

					if (libraries.libraries[i].assets[a].classification === 0) {
						libraries.libraries[i].assets[a].class = "unknown";
					} else if (libraries.libraries[i].assets[a].classification === 1) {
						libraries.libraries[i].assets[a].class = "trigger";
					} else if (libraries.libraries[i].assets[a].classification === 2) {
						libraries.libraries[i].assets[a].class = "waypoint";
					} else if (libraries.libraries[i].assets[a].classification === 3) {
						libraries.libraries[i].assets[a].class = "sign";
					} else if (libraries.libraries[i].assets[a].classification === 4) {
						libraries.libraries[i].assets[a].class = "mesh";
					} else if (libraries.libraries[i].assets[a].classification === 5) {
						libraries.libraries[i].assets[a].class = "object";
					}
					
					if (libraries.libraries[i].assets[a].files) {

						for (let f = 0; f < libraries.libraries[i].assets[a].files.length; f++) {

							let newEntry = JSON.parse(JSON.stringify(libraries.libraries[i].assets[a]));
							delete newEntry.files;

							newEntry.fileId = libraries.libraries[i].assets[a].files[f].id;
							newEntry.filePath = libraries.libraries[i].assets[a].files[f].path;
							newEntry.fileType = libraries.libraries[i].assets[a].files[f].format;
							newEntry.fileClassification = libraries.libraries[i].assets[a].files[f].classification;
							newEntry.fileName = libraries.libraries[i].assets[a].files[f].title;
                            newEntry.libraryId = libraries.libraries[i].id;

							validAssets.assets.push(newEntry);

						}
					}
					
				}
			}
			
        }
		
		validAssets.assets.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
        let results = [];
        results.push(libraries);
        results.push(validAssets);

        return results;
    }

    // Scenarios
    async getScenariosList() {
        let res = await this.apiGet(this.apiURL + '/api/scenarios/list');
        return res;
    }

    async getScenario(id) {
        let res = await this.apiGet(this.apiURL + '/api/scenarios/'+id);
        return res;
        
    }

    async deleteScenario(id) {
        return await this.apiDelete(this.apiURL + '/api/scenarios/'+id);
    }

    async createScenario(data) {
        let res = await this.apiPost(this.apiURL + '/api/scenarios/create', data);
        return res;
    }

    async updateScenario(id, data) {
        return await this.apiPut(this.apiURL + '/api/scenarios/' + id, data);
    }

    // Scenes
    async createScene(data) {
        let res = await this.apiPost(this.apiURL + '/api/scenes/create', data);
        return res;
    }

    // Scripts
    async createScript(data) {        
        let res = await this.apiPost(this.apiURL + '/api/scripts/create', data);
        return res;
    }

    async updateScript(id, data) {
        let res = await this.apiPut(this.apiURL + '/api/scripts/' + id, data);
        return res;
    }

    // Themes
    async createTheme(data) {
        let res = await this.apiPost(this.apiURL + '/api/themes/create', data);
        return res;
    }

    async updateTheme(id, data) {
        let res = await this.apiPut(this.apiURL + '/api/themes/' + id, data);
        return res;
    }


    // Sessions
    async getSessionsList() {
        let res = await this.apiGet(this.apiURL + '/api/sessions/list');
        return res;
    }

    async createSession(data) {
        let res = await this.apiPost(this.apiURL + '/api/sessions/create', data);
        return res;
    }

    async deleteSession(sessionId) {
        let res = await this.apiDelete(this.apiURL + '/api/sessions/' + sessionId);
        return res;
    }

    async getSessionsById(sessionId) {
        let res = await this.apiGet(this.apiURL + '/api/sessions/' + sessionId);
        return res;
    }

    async resetSession(sessionId) {
        let data = {};
        let res = await this.apiPost(this.apiURL + '/reset/' + sessionId, data);
        return res;
    }

    async replaySession(sessionId) {
        let data = {};
        let res = await this.apiPost(this.apiURL + '/replay/' + sessionId, data);
        return res;
    }

    async deleteReplaySession(sessionId) {
        let res = await this.apiDelete(this.apiURL + '/replay/' + sessionId);
        return res;
    }

    async getState(id) {
        let res = await this.apiGet(this.apiURL + '/state/'+id);
        return res;
    }

    async showSession(sessionId) {
        let data = {};
        let res = await this.apiPost(this.apiURL + '/show/' + sessionId, data);
        return res;
    }
    async hideSession(sessionId) {
        let data = {};
        let res = await this.apiPost(this.apiURL + '/hide/' + sessionId, data);
        return res;
    }
    async playSession(sessionId) {
        let data = {};
        let res = await this.apiPost(this.apiURL + '/play/' + sessionId, data);
        return res;
    }
    async pauseSession(sessionId) {
        let data = {};
        let res = await this.apiPost(this.apiURL + '/pause/' + sessionId, data);
        return res;
    }

    async seekSession(sessionId, data) {
        let res = await this.apiPost(this.apiURL + '/seek/' + sessionId, data);
        return res;
    }

    async updateSpectate(sessionId, data) {
        let res = await this.apiPost(this.apiURL + '/spectate/' + sessionId, data);
        return res;
    }
    

    // Users
    async createUser(data) {
        const res = await this.apiPost(this.apiURL + '/api/users/create', data);
        return res;
    }

    async getUsersList() {
        const res = await this.apiGet(this.apiURL + '/api/users/list');
        return res;
    }

    async getUserById(id) {
        const res = await this.apiGet(this.apiURL + '/api/users/' + id);
        return res;
    }

    async updateUser(id, data) {
        return await this.apiPut(this.apiURL + '/api/users/' + id, data);
    }

    async deleteUser(id) {
        let res = await this.apiDelete(this.apiURL + '/api/users/' + id);
        return res;
    }

    async addRole(userId, roleId) {
        let data = {};
        data.roleIds = [];
        data.roleIds[0] = roleId;
        let res = await this.apiPost(this.apiURL + '/api/users/' + userId + '/addRoles', data);
        return res;
    }

    async removeRole(userId, roleId) {
        let data = {};
        data.roleIds = [];
        data.roleIds[0] = roleId;
        let res = await this.apiPost(this.apiURL + '/api/users/' + userId + '/removeRoles', data);
        return res;
    }

    async getToken(token) {
        let data = {};
        data.token = token;
        let res = await this.apiPost(this.apiURL + '/api/users/token/augment', data);
        return res;
    }

    // Roles
    async getRolesList() {
        const res = await this.apiGet(this.apiURL + '/api/roles/list');
        return res;
    }

    // Metric / Stats
    async getSessionMetrics(matchId) {
        const res = await this.apiGetMetrics(this.analyticsURL + '/session/' + matchId + '/metrics');
        return res;
    }
   
    async getUserStats(userId) {
        const res = await this.apiGetMetrics(this.analyticsURL + '/user/' + userId + '/stats');
        return res;
    }

    // Simple API Wrappers
    async apiGetMetrics(url) {

        // Header / Config
        const token = localStorage.getItem('token');

        let config = {};
        if (token) {
            config = {
                headers: { Authorization: `Bearer ${token}` }
            };
        }

        let res = {};
        let error = false;

        try {
            res = await axios.get(url, config);
        } catch (errorRes) {
            res.data = [];
            res.data.errorMessage = errorRes;
            res.data.isSuccess = false;
            
            error = true;
        }

        if (error === false && res.data) {
            return res.data;
        } else {
            // error
            return res.data.errorMessage
        }

        
    }

}
