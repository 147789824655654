import "./App.css";
import React from "react";
import {BrowserRouter, Route, Routes, useParams} from "react-router-dom";

import Login from "./routes/Login";

import Home from "./routes/Home";

// ML Redirect
import MLRedirect from "./routes/MLRedirect"

// Builder
import Builder from "./routes/builder/Builder";

// sessions
import SessionRun from "./routes/session/Session_Run";
import SessionReview from "./routes/session/Session_Review";
// Spectate
import SpectateView from "./routes/spectate/Spectate_View";

// Settings
import Settings from "./routes/settings/Settings";
import SettingsScenarios from "./routes/settings/Settings_Scenarios";
import SettingsAssets from "./routes/settings/Settings_Assets";

// Settings sessions
import SettingsSessions from "./routes/settings/Settings_Sessions";

// Settings users
import SettingsUsers from "./routes/settings/Settings_Users";

// Debug
import DebugMatch from "./routes/debug/match";
import AssetUpload from "./routes/debug/upload";
import Sessions from "./routes/debug/session";
import Perms from "./routes/debug/perms"
import Animations from "./routes/debug/animations";
import AnimationsAr from "./routes/debug/animationsAR";
import AR from "./routes/debug/ar";
import WEB from "./routes/debug/web";

function GetTrainerSession() {

    const { id } = useParams();

    return (
        <SessionRun sessionId={id} />
    );
}

function GetBuilderLoadId() {

    const { id } = useParams();

    return (
        <div>
            <Builder toloadId={id} />
        </div>
    );
}

function GetWebXrLoadId() {

    const { id } = useParams();

    return (
        <div>
            <AR toloadId={id} />
        </div>
    );
}

function GetWebLoadId() {

    const { id } = useParams();

    return (
        <div>
            <WEB toloadId={id} />
        </div>
    );
}

function GetSpectateSessionId() {
    const { id } = useParams();

    return (
        <SpectateView toloadId={id} mode="WEB" />
    )
}

function GetSpectateSessionIdMobile() {
    const { id } = useParams();

    return (
        <SpectateView toloadId={id} mode="AR" />
    )
}

function SessionReviewRoute() {
    const { id } = useParams();

    return (
        <SessionReview sessionId={id} mode="WEB" />
    )
}

function Session_Review() {
    const { id } = useParams();

    return (
        <SessionReview sessionId={id} mode="WEB" />
    )
}

function Logout() {
    localStorage.clear();

    window.location = '/';
    
}


function App() {

    return (
        <BrowserRouter>
            <Routes>

                <Route exact path="/ml-oauth-redirect" element={<MLRedirect/>} />

                <Route exact path="/login" element={<Login/>}/>
                <Route exact path="/logout" element={<Logout/>}/>

                <Route exact path="/home" element={<Home/>}/>

                <Route exact path="/spectate/view" element={<SpectateView/>}/>
                <Route exact path="/spectate/view/:id" element={<GetSpectateSessionId/>}/>
                <Route exact path="/spectate/view/:id" element={<GetSpectateSessionId/>}/>
                <Route exact path="/spectate/view/mobile/:id" element={<GetSpectateSessionIdMobile/>}/>

                <Route exact path="/builder" element={<Builder/>}/>
                <Route exact path="/builder/:id" element={<GetBuilderLoadId/>}/>


                <Route exact path="/session" element={<SessionRun/>}/>
                <Route exact path="/session/run/:id" element={<GetTrainerSession/>}/>
                <Route exact path="/session/review" element={<SessionReviewRoute/>}/>
                <Route exact path="/session/review/:id" element={<Session_Review/>}/>

                <Route exact path="/settings" element={<Settings/>}/>

                <Route exact path="/settings/scenarios" element={<SettingsScenarios/>}/>
                <Route exact path="/settings/assets" element={<SettingsAssets/>}/>
                <Route exact path="/settings/sessions" element={<SettingsSessions/>}/>
                <Route exact path="/settings/users" element={<SettingsUsers/>}/>

                <Route exact path="/debug/match" element={<DebugMatch/>}/>
                <Route exact path="/debug/upload" element={<AssetUpload/>}/>
                <Route exact path="/debug/sessions" element={<Sessions/>}/>
                <Route exact path="/debug/perms" element={<Perms/>}/>
                

                <Route exact path="/debug/ar/:id" element={<GetWebXrLoadId/>} />
                <Route exact path="/debug/web/:id" element={<GetWebLoadId/>} />

                <Route exact path="/debug/" element={<Sessions/>}/>

                <Route exact path="/debug/animations" element={<Animations/>}/>
                <Route exact path="/debug/animationsar" element={<AnimationsAr/>}/>

                <Route exact path="/" element={<Login/>}/>
                <Route exact path="/" element={<Login/>}/>

                

            </Routes>
        </BrowserRouter>
    );

}

export default App;
