import React, { Component, useEffect } from "react";

class TabHeader extends Component {  

  constructor(props) {
    super(props)
    this.state = {
        textElHeight: 0,
        textElWidth: 0,
        tiltDistance: 0
    };
    this.textRef = React.createRef();  
    this.onReflow.bind(this.onReflow) 
  }

  componentDidMount(){
    const vm = this
    if((this.props.render && this.props.render === true) || this.props.render === undefined){
        this.onReflow()
        /**
         * Fix bug where sometimes the getBoundingClientRect is not correct, we render once to 
         * at least avoid a jitter in the UI
         */
        setTimeout(()=>{
            vm.onReflow()
        },20)
    }
    if(this.props.onReflow) this.props.onRelow(this.onReflow)
  };

  componentWillUpdate(nextProps, nextState){
    /**
     * Make sure we call for headers that show later (depending on the render prop)
     */
    if (nextProps.render === true && !this.props.render) {
        const vm = this
      this.setState({ rendered: true })
      vm.onReflow()
      setTimeout(()=>{
        vm.onReflow()
      },20)
    }
  }

  onReflow(){
    let vm = this
    if (vm.textRef.current) {
      const textRef = vm.textRef.current,
        bounds = textRef.getBoundingClientRect(),
        textElHeight = parseInt(bounds.height) || 0,
        textElWidth = parseInt(bounds.width) || 0
      vm.setState({ textElHeight })
      vm.setState({ textElWidth })
      vm.setState({ tiltDistance: 20 })
    }

    
  }

  
  

  render() {
    return (
        <div class="tab-header">
            <div class="inner-container">
                <div class="text" ref={this.textRef}>{this.props.text}</div>
                <svg version="1.1" viewBox={`0 0 ${this.state.textElWidth} ${this.state.textElHeight}`} class="trapezium">         
                    {/* <polygon points="0,0, 30,0, 24,20, 6,20" fill="url(#grad)"/> */}
                    {/* <polygon points={`0,20, 30,20, 24,0, 6,0`} fill="#333"/> */}
                    <polygon points={`0,${this.state.textElHeight}, ${this.state.textElWidth},${this.state.textElHeight}, ${this.state.textElWidth-this.state.tiltDistance},0, ${this.state.tiltDistance},0`} fill="#333"/>
                    <line strokeWidth="2" x1="1" y1={this.state.textElHeight} x2={this.state.tiltDistance} y2="0" stroke="#636363" />
                    <line strokeWidth="2" x1={this.state.tiltDistance} y1="1" x2={(this.state.textElWidth - (this.state.tiltDistance))} y2="1" stroke="#636363" />
                    <line strokeWidth="2" x1={(this.state.textElWidth - (this.state.tiltDistance))} y1="0" x2={this.state.textElWidth} y2={this.state.textElHeight+1} stroke="#636363" />
                    {/* <g fill="none" stroke="#fff" strokeWidth="4">
                        <path strokeDasharray="5,5" d="M5 0 l100 0" />
                    </g> */}
                </svg>
                {/* <svg version="1.1" viewBox="0 0 30 20" class="trapezium">         
                    <polygon points="0,20, 30,20, 24,0, 6,0" fill="#333"/>
                    <g fill="none" stroke="#fff" strokeWidth="4">
                        <path strokeDasharray="5,5" d="M5 0 l100 0" />
                    </g>
                </svg> */}
            </div>
        </div>
    );
  }


}

export default TabHeader;
