import React, {Component} from "react";
import Header from "../../helpers/Header";
import Popup from 'reactjs-popup';
import { Autosave, useAutosave } from 'react-autosave';
import Settings_Nav from "./Settings_Nav";
import './Settings.scss';

import BMF from 'browser-md5-file';
import filesAPI from '../../utility/files';
import ConsoleEvents from '../../utility/ConsoleEvents';
import Auth from "../../utility/auth";

const authCheck = new Auth();
const bmf = new BMF();
const filesClient = new filesAPI();
const consoleClient = new ConsoleEvents();

const assetClassifications = {
    Unknown:0,
    Trigger:1,
    Waypoint:2,
    Sign:3,
    Mesh:4,
    Object:5
};

const fileClassifications = {
    Unknown:0,
    Mesh:1,
    Animation:2,
    Image:3,
    Spatial_Map:4
}

class Settings_Assets extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            openAssetDelete: false,
            openLibraryDelete: false,
            saveEdit: true,
            selectedName: null,
            isLoaded: false,
            assets: [],
            libraries: [],
            entities: [],
            selectedAsset: false,
            originalAsset: false,
            selectedFile: false,
            originalFile: false,
            selectedLibrary: false,
            originalLibrary: false,
            uploading: false,
            prevSelected: ""
        };

        this.fileInput = React.createRef();

        document.body.className = 'pwa18_settings_server';
    }

    async componentDidMount() {

        document.title = process.env.REACT_APP_TITLE_PREFIX + "Asset Settings";

        this.getLibraries();
        //this.getAssets();

        // Check Perms
        authCheck.checkPerms(["Administrator", "Editor"]);
    }

    exists = (v) => {
        return this.state.assets.find(a => a.name === v && a.id != this.state.selectedAsset.id);
    }

    // GETTING

    async getAssets() {
        let assetList = await filesClient.getAssetsList();

        if (assetList.isSuccess && assetList.assets) {
            const a = [];
            assetList.assets.filter(f => (f.classification === 3 && f.status === 4))
                .map(m => !a.find(aa => aa.id === m.id) && a.push(m));
            this.setState({assets: a});
        } else {
            // display error connectiong to files API
            consoleClient.sendLogMsg("Asset error lookup");
        }

    }

    async getLibraries() {
        let libraryList = await filesClient.getLibrariesList();

        if (libraryList.isSuccess && libraryList.libraries) {
            let {selectedLibrary} = this.state,
                l = libraryList.libraries;

            if (l.length && !selectedLibrary) {
                this.handleSelectLibrary(l[0]);
            }
            this.setState({libraries: l});
        } else {
            // display error connectiong to files API
            consoleClient.sendLogMsg("Library error lookup");
        }

    }

    // SELECTING

    handleSelectAsset = async (a, noEdit = false) => {
        let { selectedAsset, originalAsset, selectedLibrary } = this.state;

        if (!a || a.id !== selectedAsset.id) {
            if (selectedAsset.id
                && originalAsset.id === selectedAsset.id
                && !noEdit) {
                await this.handleAssetEdit('selectAsset');
            }
            if (a.id && !a.originalLibraryId) {
                a.originalLibraryId = selectedLibrary.id;
            }
        }

        this.setState({ selectedAsset: a, originalAsset: {...a}});
    }

    handleSelectFile = async (f, noEdit = false) => {
        let { selectedFile, originalFile } = this.state;

        if (!f || f.id !== selectedFile.id) {
            if (selectedFile.id
                && originalFile.id === selectedFile.id
                && !noEdit) {
                await this.handleFileEdit('selectFile');
            }
        }

        this.setState({ selectedFile: f, originalFile: {...f}});
    }

    handleSelectLibrary = async (l, noEdit = false) => {
        let { selectedLibrary, originalLibrary } = this.state;

        if (!l || l.id !== selectedLibrary.id) {
            if (selectedLibrary.id
                && originalLibrary.id === selectedLibrary.id
                && !noEdit) {
                await this.handleLibraryEdit();
            }
            await this.handleSelectAsset(false, noEdit);
        }

        this.setState({ selectedLibrary: l, originalLibrary: {...l}});
    }

    // CREATING/CHANGING

    handleSubmit = async (e) => {
        e.preventDefault();
        const {selectedFile, selectedName, selectedAsset, libraries} = this.state;

        bmf.md5(selectedFile, (err, md5) => {
            this.setState({uploading: true})
            filesClient.createFile(selectedFile, md5, selectedName, selectedAsset.id).then(res => {
                if (res.isSuccess) {
                    selectedAsset.files.push(res.file);
                    libraries.map(l => l.id == selectedAsset.originalLibraryId ? l.assets.map(a => a.id == selectedAsset.id ? selectedAsset : a) && l : l)

                    this.closeModal();
                } else {
                    this.closeModal();
                }
                this.setState({uploading: false})
            });

        })
    }

    handleChange = (e, name, inc) => {
        const file = this.fileInput.current.files[0];
        let full = file.name.split('.'),
            ext = full[full.length - 1],
            f = full.join('.').replace(new RegExp('.' + ext + '$'), '');

        if (this.exists(name || f)) {
            let i = 1;
            if (name) {
                let re = new RegExp('_' + inc + '$');

                i = inc + 1;
                f = name.replace(re, '_' + i);
            } else {
                f = f + '_1';
            }
            return this.handleChange(e, f, i);
        }

        this.setState({selectedFile: this.fileInput.current.files[0], selectedName: (name || f) + '.' + ext});
    }

    handleTitleChange = (e) => {
        this.setState({saveEdit: true, value: e.target.value});
    }

    handleAssetChange = (e) => {
        let {name, value} = e.target,
            {selectedAsset} = this.state;
        let a = [];

        if (name === 'newLibraryId') {
            if (value === selectedAsset.originalLibraryId) {
                value = '';
            }
        }
        if (!isNaN(parseInt(value))) {
            value = parseInt(value);
        }

        a[name] = value;

        this.setState({ selectedAsset: {...selectedAsset, ...a}});
    }

    handleFileChange = (e) => {
        e.stopPropagation();

        let {selectedAsset, selectedFile} = this.state,
            {name, value} = e.target;

        if (!isNaN(parseInt(value))) {
            value = parseInt(value);
        }

        selectedFile[name] = value;
        selectedAsset.files.map(f => f.id == selectedFile.id && selectedFile || f);

        this.setState({selectedAsset, selectedFile }, this.handleFileEdit);
    }

    handleLibraryChange = (e) => {
        let {name, value} = e.target,
            l = [];

        if (!isNaN(parseInt(value))) {
            value = parseInt(value);
        }

        l[name] = value;

        this.setState({ selectedLibrary: {...this.state.selectedLibrary, ...l}});
    }

    handleAssetEdit = async (e) => {
        let { selectedAsset, originalAsset, selectedLibrary } = this.state,
            l = selectedLibrary,
            a = selectedAsset,
            o = originalAsset,
            changed,
            res;

        changed = JSON.stringify(a) !== JSON.stringify(o);

        if (!changed) {
            return;
        }

        Object.keys(a).map(k => o[k] !== a[k] && console.log(k + ' is different selected:' + a[k] + ' and original:' + o[k]));

        res = await filesClient.updateAsset(a);

        if (res.isSuccess) {
            a.newLibraryId = o.newLibraryId = false;
            if (res.asset.id === o.id) {
                res.asset.files = a.files;
                this.handleSelectAsset(res.asset, true);
            }
            this.getLibraries();
        } else {
            // an error, do something
        }
    }

    handleFileEdit = async (e) => {
        let { selectedFile, originalFile, selectedAsset, libraries } = this.state,
            a = selectedAsset,
            f = selectedFile,
            o = originalFile,
            changed,
            res;

        changed = JSON.stringify(f) !== JSON.stringify(o);

        if (!changed) {
            return;
        }

        Object.keys(f).map(k => o[k] !== f[k] && console.log(k + ' is different selected:' + f[k] + ' and original:' + o[k]));

        res = await filesClient.updateFile(f);

        if (res.isSuccess) {
            if (res.file.id === o.id) {
                this.handleSelectFile(res.file, true);
            }

        } else {
            // an error, do something
        }
    }

    handleLibraryEdit = async () => {
        let {libraries, selectedLibrary, originalLibrary} = this.state,
            l = selectedLibrary,
            o = originalLibrary,
            changed,
            res;

        changed = JSON.stringify(l) !== JSON.stringify(o);

        if (!changed) {
            return;
        }

        Object.keys(l).map(k => l[k] != o[k] && console.log(k + ' is different selected:' + l[k] + ' and original:' + o[k]));

        if (!l.id) {
            res = await filesClient.createLibrary(l);
        }
        else {
            res = await filesClient.updateLibrary(l);
        }

        if (res.isSuccess) {
            let st = this.state;

            if (!l.id) {
                l = res.library;
            }

            st.libraries = libraries.filter(ll => ll.id !== l.id);

            st.libraries.push(l);
            st.libraries.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);

            this.setState(st);
            this.handleSelectLibrary(l);
        } else {
            // There was an error, do something
        }

    }

    handleDelete = async (e) => {
        e.preventDefault();
        const { libraries, openAssetDelete, openFileDelete, openLibraryDelete, selectedAsset, selectedFile, selectedLibrary } = this.state;
        let res, lastAsset;

        if (openFileDelete) {
            res  = await filesClient.deleteFile(selectedFile.id, selectedAsset.id);
            if (res.isSuccess) {
                // Remove asset from selectedLibrary
                const as = {...selectedAsset, files: selectedAsset.files.filter(f => f.id !== selectedFile.id)},
                    li = {...selectedLibrary, assets: selectedLibrary.assets.map(a => a.id == as.id && as || a)},
                    libs = libraries.map(l => l.id == selectedLibrary.id && li || l);

                this.handleSelectFile(false, true);
                this.handleSelectAsset(as, true);

                this.setState({ libraries: libs });
            }
        }

        else if (openAssetDelete) {
            res  = await filesClient.deleteAsset(selectedAsset.id, selectedLibrary.id);
            if (res.isSuccess) {
                // Remove asset from selectedLibrary
                const li = {...selectedLibrary, assets: selectedLibrary.assets.filter(a => a.id !== selectedAsset.id)},
                    libs = libraries.map(l => l.id == selectedLibrary.id && li || l) ;

                this.handleSelectAsset(false, true);
                this.handleSelectLibrary(li);

                this.setState({ libraries: libs });
            }
        }
        else if (openLibraryDelete) {
            res  = await filesClient.deleteLibrary(this.state.selectedLibrary.id);
            if (res.isSuccess) {
                // Remove library from list
                this.handleSelectLibrary(libraries[0]);
                this.setState({libraries: this.state.libraries.filter(l => l.id != selectedLibrary.id)});
            }
            if (libraries.length && !selectedLibrary.id && !selectedLibrary.name) {
                this.handleSelectLibrary(libraries[0]);
            }
            else {
                this.handleSelectLibrary({id:'',name:'',assets:[]});
            }
        }


        this.closeDeleteModal();
    }

    handleKeyPress = (e) => {
        if (e.charCode === 13) {
            this.handleEdit(e);
        }
    }

    // MODAL RENDERS
    Modal() {
        const {current} = this.fileInput,
            {selectedFile, selectedName} = this.state;
        const n = selectedFile && selectedFile.name && selectedFile.name.split('.'),
            ss = selectedFile && selectedFile.name && selectedName;

        return (<Popup open={this.state.open} onClose={this.closeModal} modal>
            <div id="settings-add-asset" className="fancybox_popup builderWideModal">
                <h3>Upload File</h3>
                <a className="popup_close" onClick={this.closeModal}></a>

                <div className="builder05left_popup">
                    <div className="row m-0">
                        <div className="col-md-12 p-0">
                            <div className="builder05contnet_popup">
                                <h4>FILE</h4>
                                <form onSubmit={this.handleSubmit}>
                                    <input onChange={this.handleChange} ref={this.fileInput}
                                           id="file-upload"
                                           type="file"/>
                                </form>
                                {!current || !current.files[0] ? (
                                    <>
                                        <label className="button_blue"
                                               htmlFor="file-upload"><span>Select File</span></label>
                                    </>) : (
                                    <div className="upload">
                                        <h5>{this.state.uploading ? 'Uploading...' : ss.replace(new RegExp('.' + n[n.length - 1] + '$'), '')}</h5>
                                        <h4>Format: {n[n.length - 1]}</h4>
                                        <h4>Size: {filesClient.humanFileSize(selectedFile.size)}</h4>
                                        <a onClick={this.handleSubmit} href="#" className="button_blue"><span>UPLOAD</span></a>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Popup>);
    };

    openModal= (e) => {
        e.preventDefault();
        this.setState({open: true})
    }

    closeModal = () => {
        this.setState({open: false})
    }

    openAddLibrary = (e) => {
        e.preventDefault();
        this.handleSelectLibrary({id: '', name: '', assets: []});
    }

    openAddAsset = (e) => {
        e.preventDefault();
        this.handleSelectAsset({id: '',
                                name: '',
                                files: [],
                                status: 0,
                                classification: 0,
                                pwaScaleMultiplier: '1.0',
                                hmdScaleMultiplier: '1.0',
                                originalLibraryId: false,
                                newLibraryId: this.state.selectedLibrary.id
                        });
    }

    EditModal() {
        const {state, closeEditModal, handleEdit} = this,
            {openEdit} = state;

        return (<Popup open={openEdit} onClose={closeEditModal} modal>
            <div id="settings-edit-asset" className="fancybox_popup builderWideModal">
                <h6>Rename</h6>
                <a className="popup_close" onClick={closeEditModal}></a>

                <div className="builder05left_popup">
                    <div className="row m-0">
                        <div className="col-md-12 p-0">
                            <div className="builder05contnet_popup">
                                <h4>Edit Name</h4>
                                <input type="text" value={this.state.value} onChange={this.handleTitleChange}
                                       onKeyPress={this.handleKeyPress}/>

                                {!this.state.saveEdit &&
                                    <div className="error">Asset with that name already exists</div>}
                                <div>
                                    <a href="#" onClick={handleEdit}
                                       className={this.state.saveEdit ? 'button_blue' : 'button_gray disabled'}><span>Save</span></a>
                                    <a href="#" onClick={closeEditModal} className="button_gray"><span>Cancel</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Popup>);
    };

    openEditModal(e, map) {
        e.preventDefault();
        this.setState({openEdit: true, selectedAsset: map, value: map.name})
    }

    closeEditModal = (e) => {
        e && e.preventDefault();
        this.setState({openEdit: false})
    }

    DeleteModal() {
        const {state, closeDeleteModal, handleDelete} = this,
            {openAssetDelete, selectedAsset, openFileDelete, selectedFile, openLibraryDelete, selectedLibrary} = state,
            s = (openFileDelete ? selectedFile : (openAssetDelete ? selectedAsset : (openLibraryDelete ? selectedLibrary : {})));

        return (<Popup open={openAssetDelete || openFileDelete || openLibraryDelete} onClose={closeDeleteModal} modal>
            <div id="settings-delete-asset" className="fancybox_popup builderWideModal">
                <h3>Delete</h3>
                <a className="popup_close" onClick={closeDeleteModal}></a>

                <div className="builder05left_popup">
                    <div className="row m-0">
                        <div className="col-md-12 p-0">
                            <div className="builder05contnet_popup">
                                <h4>Are you sure that you want to delete this item?</h4>
                                <p>{s.name || s.title || s.id}</p>

                                <div>
                                    <a href="#" onClick={handleDelete} className="button_blue"><span>Yes</span></a>
                                    <a href="#" onClick={closeDeleteModal} className="button_gray"><span>No</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Popup>);
    };

    openAssetDeleteModal(e, a) {
        this.setState({openAssetDelete: true, selectedAsset: a})
    }

    openFileDeleteModal(e, f) {
        this.setState({openFileDelete: true, selectedFile: f})
    }

    openLibraryDeleteModal(e, l) {
        this.setState({openLibraryDelete: true, selectedLibrary: l})
    }

    closeDeleteModal = () => {
        this.setState({openAssetDelete: false, openFileDelete: false, openLibraryDelete: false})
    }

    // OTHER RENDERS

    renderSidebar() {
        const { selectedLibrary, selectedAsset, libraries } = this.state;
        if (selectedAsset) {
            const ac = assetClassifications;
            let a = selectedAsset,
                classifications = Object.keys(ac).map(k => <option key={k} value={ac[k]}>{k}</option>);

            return (
                <div className="asset-edit p-4 mt-2">
                    <Autosave data={a} onSave={this.handleAssetEdit} interval={10000}/>
                    <div className="settings18_pagebox1">
                        <h6>Asset Name</h6>
                        <input className="settings18_pageboxinput"
                               type="text" name="name"
                               onChange={this.handleAssetChange}
                               onBlur={this.handleAssetEdit}
                               value={a.name}/>
                    </div>
                    <div className="settings18_pagebox1">
                        <h6>Classification</h6>
                        <select className="settings18_pageboxinput"
                                name="classification"
                                onChange={this.handleAssetChange}
                                onBlur={this.handleAssetEdit}
                                value={a.classification}>
                            {classifications}
                        </select>
                    </div>
                    <div className="settings18_pagebox1">
                        <h6>Library</h6>
                        <select className="settings18_pageboxinput"
                                name="newLibraryId"
                                onChange={this.handleAssetChange}
                                onBlur={this.handleAssetEdit}
                                value={a.newLibraryId || a.originalLibraryId}>
                            {libraries.map(l => <option key={l.id} value={l.id}>{l.name}</option>)}
                        </select>
                    </div>
                    <div className="settings18_pagebox1">
                        <h6>Webapp Scaling</h6>
                        <input className="settings18_pageboxinput"
                               type="text" name="name"
                               onChange={this.handleAssetChange}
                               onBlur={this.handleAssetEdit}
                               value={a.pwaScaleMultiplier}/>
                    </div>
                    <div className="settings18_pagebox1">
                        <h6>Headset Scaling</h6>
                        <input className="settings18_pageboxinput"
                               type="text" name="name"
                               onChange={this.handleAssetChange}
                               onBlur={this.handleAssetEdit}
                               value={a.hmdScaleMultiplier}/>
                    </div>
                    <div className="settings18_pagebox1 settings19_pagetable file-list">
                        <h6>Files ({a.files.length})</h6>
                        <table>
                            <tbody>
                            {this.renderFileList(a.files)}
                            </tbody>
                        </table>
                    </div>
                    <div className="settings18_pagebox1">
                        <a href="#" className="button_blueborder"
                           onClick={(e) => this.openModal(e, a)}><span>Upload File</span></a>
                    </div>
                    <div className="settings18_pagebox1">
                        {a.id && <a href="#" className="button_blueborder"
                                    onClick={(e) => this.openAssetDeleteModal(e, a)}><span>Delete Asset</span></a>}
                    </div>
                </div>

            );
        }
        if (selectedLibrary) {
            let l = selectedLibrary;

            return (
                <div className="library-edit p-4 mt-2">
                    <div className="settings18_pagebox1">
                        <h6>Library Name</h6>
                        <input className="settings18_pageboxinput"
                               type="text" name="name" onChange={this.handleLibraryChange}
                                value={l.name}/>
                        <Autosave data={l} onSave={this.handleLibraryEdit} interval={10000}/>
                    </div>
                    <div className="settings18_pagebox1 settings19_pagetable asset-list">
                        <table>
                            <thead>
                            <tr>
                                <th>Assets ({l.assets.length})</th>
                                <th> </th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.renderAssetList(l.assets, 'small')}
                            </tbody>
                        </table>
                    </div>
                    <div className="settings18_pagebox1">
                        <a href="#" className="button_blueborder"
                           onClick={(e) => this.openLibraryDeleteModal(e, l)}><span>Delete Library</span></a>
                    </div>
                </div>

            );
        }
    }

    renderFileList(f) {
        const fc = fileClassifications;
        let classifications = Object.keys(fc).map(k => <option key={k} value={fc[k]}>{k.replace('_',' ')}</option>);

        return f && (
            f.length ? f.map((f) => {
                return (
                    <tr key={f.id} onClick={(e) => this.handleSelectFile(f)}>
                        <td>{f.title}</td>
                        <td>
                            <select className="settings18_pageboxinput"
                                    name="classification"
                                    onChange={this.handleFileChange}
                                    onBlur={this.handleFileEdit}
                                    value={f.classification}>
                                {classifications}
                            </select>
                        </td>
                        <td><a href="#" className="button_blueborder"
                               onClick={(e) => this.openFileDeleteModal(e, f)}><span>Delete</span></a></td>
                    </tr>
                );
            }) : null
        );
    }

    renderAssetList = (assets, size = 'full') => {
        return assets && (
            assets.length ? assets.map((a) => {
                return (
                    <tr key={a.id} onClick={(e) => this.handleSelectAsset(a)} className={'asset' + (a.id === this.state.selectedAsset.id ? ' selected' : '')}>
                        <td>{a.name}</td>
                        {size === 'full' ?
                            [<td key={1}>{a.files.length} files</td>,
                                <td key={2}>{a.updatedAt}</td>] :
                            <td><a href="#" className="button_blueborder"
                                   onClick={(e) => this.openAssetDeleteModal(e, a)}><span>Delete</span></a></td>
                        }
                    </tr>
                );
            }) : null
        );
    }

    renderList() {
        const { handleSelectLibrary, renderAssetList, state } = this,
                {libraries, selectedLibrary, selectedAsset} = state;

        return libraries.map((l, i) => {
            let cl = (l.id === selectedLibrary.id ? 'selected ' : '') + (selectedAsset ? 'asset-selected' : '');
            return ([
                    <tr key={l.id}
                        onClick={(e) => handleSelectLibrary(l)}
                        className={cl}>
                        <td>{l.name}</td>
                        <td>{l.assets.length}</td>
                        <td>{l.updatedAt}</td>
                    </tr>,
                l.id === selectedLibrary.id ?
                        renderAssetList(l.assets) : null
                ]);
        });
    }

    // RENDER

    render() {

        return (
            <div>

                <Header section="SETTINGS"/>

                <section>

                    <div className="left_sidebar">
                        <div className="container-fluid p-0">
                            <div className="row m-0">
                                <Settings_Nav/>
                                <div className="col-md-9 p-0 d-flex">

                                    <div className="settings19_pagecontent libraries">
                                        <div className="settings19_pagetitel">
                                            <h3>Manage Assets</h3>
                                            <div className="settings19_pagebuttonright">
                                                <a href="#" className="button_blueborder"
                                                   onClick={this.openAddLibrary}><span>Add Library</span></a>
                                            </div>
                                            {this.state.selectedLibrary && <div className="settings19_pagebuttonright">
                                                <a href="#" className="button_blueborder"
                                                   onClick={this.openAddAsset}><span>Add Asset</span></a>
                                            </div>}
                                        </div>
                                        <div className="settings19_pagetable">
                                            <table>
                                                <thead>
                                                <tr>
                                                    <th>library</th>
                                                    <th>assets</th>
                                                    <th>Last modified</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.renderList()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <div className="context-sidebar">
                                        {this.renderSidebar()}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

                {this.Modal()}
                {this.DeleteModal()}
                {this.EditModal()}
                <footer></footer>


            </div>
        );

    }
}

export default Settings_Assets;



