import React, { Component, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import { OidcClient} from "oidc-client-ts";
import filesAPI from './files';

const filesClient = new filesAPI();

// https://github.com/authts/react-oidc-context

export default class Auth extends React.Component {

    constructor(props) {
        super(props);

        this.state = { settings: {
                authority: process.env.REACT_APP_AUTH,
                client_id: "vita-app",
                response_type: "code",
                scope: "groups audience:server:client_id:vita-app openid profile email offline_access"
            },
            loginCheckTimeout: null,
            loginCheckLimit: 10,
            loginCheckInt: 500
        };

        this.createSignIn = this.createSignIn.bind(this);
        this.processSignin = this.processSignin.bind(this);
        this.refreshToken = this.refreshToken.bind(this);
        this.tokenExpireCheck = this.tokenExpireCheck.bind(this);
        this.checkPerms = this.checkPerms.bind(this);

        this.checkLogin = this.checkLogin.bind(this);
	}

    async checkLogin() {
        const permLevel = localStorage.getItem('permLevel');

        if (permLevel) {
            clearTimeout(this.state.loginCheckTimeout);
            window.location = '/home';
        } else {
            this.state.loginCheckTimeout = setTimeout(this.checkLogin, this.state.loginCheckInt);
        }
    }

    async createSignIn() {

        let settings = this.state.settings;

        let redirectURL = window.location.href;
        redirectURL = redirectURL.substring(0, redirectURL.length-1);

        settings.redirect_uri = redirectURL;
        
        var client = new OidcClient(settings);
        let stateChallenge = uuidv4();
        client.createSigninRequest({ state: {challenge: stateChallenge} }).then(function(req) {
            console.log(req.url);
            console.log(req);
            localStorage.setItem('stateChallenge', stateChallenge);
            window.location = req.url;
        }).catch(function(err) {
            console.error(err);
        });

    }

    async processSignin(oidcCode, oidcState) {
    
        let settings = this.state.settings;
        settings.grant_type = "authorization_code";
        settings.client_secret = process.env.REACT_APP_CLIENT_SECRET;
        settings.code = oidcCode;

        if (oidcCode && oidcState) {
            var client = new OidcClient(settings);
            let res;

            await client.processSigninResponse(window.location.href).then(function(response) {
                res = response;
            }).catch(function(err) {
                console.error(err);
            });

            if (res && res.access_token) {
                // Store data
                //localStorage.setItem('token', res.access_token);
                localStorage.setItem('refreshToken', res.refresh_token);
                localStorage.setItem('tokenExpire', res.expires_at);
                
                let tokenCheck = await filesClient.getToken(res.access_token);
                if (tokenCheck && tokenCheck.token) {
                    localStorage.setItem('token', tokenCheck.token);

                    let decode = JSON.parse(Buffer.from(tokenCheck.token.split('.')[1], 'base64').toString());

                    if (decode && decode.id) {
                        // get user info from id
                        let userInfo = await filesClient.getUserById(decode.id);

                        localStorage.setItem('permLevel', decode.roles);
                        
                        if (userInfo && userInfo.isSuccess === true && userInfo.user) {
                            let userData = {};
                            userData.firstName = userInfo.user.firstName;
                            userData.lastName = userInfo.user.lastName;
                            userData.email = userInfo.user.userName;
                            userData.id = userInfo.user.id;

                            localStorage.setItem('userData', JSON.stringify(userData));

                            this.state.loginCheckTimeout = setTimeout(this.checkLogin, this.state.loginCheckInt);

                        }
                        
                    }
                } else {
                    // redirect to logout
                    localStorage.clear();
                    window.location = '/';
                }
                
            }

        } else {
            // error

        }
    }

    async refreshToken() {
        const refreshToken = localStorage.getItem('refreshToken');

        let settings = this.state.settings;
        settings.grant_type = "refresh_token";
        settings.client_secret = process.env.REACT_APP_CLIENT_SECRET;
        settings.refresh_token = refreshToken;

        var client = new OidcClient(settings);
        let res;

        await client.useRefreshToken({ state: {refresh_token: refreshToken} }).then(function(response) {
            //console.log("useRefreshToken response", response);
            res = response;
        }).catch(function(err) {
            console.error(err);
        });

        if (res && res.access_token) {
            //localStorage.setItem('token', res.access_token);
            localStorage.setItem('refreshToken', res.refresh_token);
            localStorage.setItem('tokenExpire', res.expires_at);

            let tokenCheck = await filesClient.getToken(res.access_token);

            if (tokenCheck && tokenCheck.token) {
                localStorage.setItem('token', tokenCheck.token);
            } else {
                // redirect to logout
                localStorage.clear();
                window.location = '/';
            }
            
        } else {
            // redirect to logout
            localStorage.clear();
            window.location = '/';
        }

    }

	checkPerms(reqLevel) {
        const permLevel = localStorage.getItem('permLevel');

        if (reqLevel.includes(permLevel) === true) {

            // Check if token is expired
            this.tokenExpireCheck();
           
        } else if (permLevel !== null) {
            // redirect to perm error page
            localStorage.setItem('error', "Not Authorized");
            
            window.location = '/';
        } else {

            // redirect to perm error page
            localStorage.setItem('error', "Not Authorized");

            window.location = '/';
        }
        
    }

    checkPermLevel(reqLevel) {
        // check token

        const permLevel = localStorage.getItem('permLevel');

        let allowed = false;

        if (reqLevel.includes(permLevel) === true) {
            allowed = true;
        }

        return allowed;
    }

    tokenExpireCheck() {
        const tokenExpire = localStorage.getItem('tokenExpire');

        if (Date.now()/1000 > tokenExpire) {
            // expired log out and redirect
            this.refreshToken();

            //window.location = '/logout';
        }
    }
}