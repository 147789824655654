import React, { Component, useState } from "react";
import TabHeader from "../components/TabHeader";
import Popup from 'reactjs-popup';

const metricLimit = 3;
let metricOptions = [];
let metricItem = {};

metricItem = {};
metricItem.name = "Kills";
metricItem.jsonName = "kills";
metricOptions.push(metricItem);

metricItem = {};
metricItem.name = "Shots Fired";
metricItem.jsonName = "shots_fired";
metricOptions.push(metricItem);

metricItem = {};
metricItem.name = "Shots Missed";
metricItem.jsonName = "shots_missed";
metricOptions.push(metricItem);

metricItem = {};
metricItem.name = "Shots Hit";
metricItem.jsonName = "shots_hit";
metricOptions.push(metricItem);

metricItem = {};
metricItem.name = "Friendlies Flagged";
metricItem.jsonName = "friendlies_flagged";
metricOptions.push(metricItem);

class SceneLeftPanel extends Component {
    constructor(props) {
        super(props);

        // setup init state
        this.state = this.getInitialState();    
        this.togglePanel = this.togglePanel.bind(this);

        // Metric
        this.toggleMetric = this.toggleMetric.bind(this);
        this.updateMetricData = this.updateMetricData.bind(this);
        this.defaultMetric = this.defaultMetric.bind(this);

        this.modalMetricsToggle = this.modalMetricsToggle.bind(this);
        this.closeMetrics = this.closeMetrics.bind(this);
    }

    // Settings
    getInitialState = () => ({
        sessionUsers: [],
        metrics: [],
        metricDisplay: [],
        metricByUser: [],
        matchActive: false,
        panelActive: true,
        liteMode: false,
        isReplay: false,
        isAR: false,
        sessionTick: 0,
        sessionTime: "00:00:00",
        sessionId: "",
		sessionName: "",
        sessionLocation: "",
        scenarioName: "",
        scenarioDesc: "",
    });

    defaultMetric() {
        let currentItems = this.state.metricDisplay;

        for(let i = 0; i<metricOptions.length; i++) {
            if(i<metricLimit) {
                currentItems.push(metricOptions[i].jsonName);
            }
        }

        this.setState({metricDisplay: currentItems});
    }

    toggleMetric(e) {

        let currentItems = this.state.metricDisplay;

        if (e.target.checked === true && currentItems.length < metricLimit) {
            currentItems.push(e.target.id);
        } else if (e.target.checked === false) {
            let toDel = currentItems.findIndex(x => x === e.target.id);

            if (toDel > -1) {
                currentItems.splice(toDel, 1);
            }
        }

        this.setState({metricDisplay: currentItems});
    }
    
    updateMetricData(metricData) {

        let currentItems = this.state.metricDisplay;
        let sessionUsers = this.state.sessionUsers;

        let newMetrics = [];

        for(let u=0; u < sessionUsers.length; u++) {
            let metricKey = this.state.metrics.findIndex(x => x.user_id === sessionUsers[u].userId);
            if (metricKey > -1) {
                // User exist in metrics
                let userMetric = this.state.metrics[metricKey];
                newMetrics[sessionUsers[u].userId] = userMetric;
                for(let i=0; i < currentItems.length; i++) {
                    
                    if (i < metricLimit) {
                        newMetrics[sessionUsers[u].userId]["stat_"+i] = userMetric[currentItems[i]];
                    }                    
        
                }

            }
        }

        this.setState({metricByUser: newMetrics});

    }

    modalMetricsToggle() {
        this.setState({openMetrics: !this.state.openMetrics});
    }

    closeMetrics() {
        this.setState({openMetrics: false});
    }

    togglePanel(){
        this.setState({ panelActive: !this.state.panelActive })
    }

    async componentDidMount() {
        // default metric
        this.defaultMetric();
    }

    componentWillUnmount() {        
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.metrics !== prevProps.metrics) {
            this.updateMetricData();
        }

    }

    static getDerivedStateFromProps(props, state) {

        if (props.sessionUsers && props.sessionUsers !== state.sessionUsers) {
          return {
            sessionUsers: props.sessionUsers
          };
        }
    
        if (props.matchActive !== state.matchActive) {
          return {
            matchActive: props.matchActive
          };
        }

        if (props.liteMode !== state.liteMode) {
            return {
                liteMode: props.liteMode
            };
        }

        if (props.isReplay !== state.isReplay) {
            return {
                isReplay: props.isReplay
            };
        }

        if (props.metrics && props.metrics !== state.metrics) {
            return { 
                metrics: props.metrics
            };
            
        }
    
        if (props.sessionTick && props.sessionTick != state.sessionTick) {
          return { 
            sessionTick: props.sessionTick
          };
        }

        if (props.sessionTime && props.sessionTime != state.sessionTime) {
            return { 
              sessionTime: props.sessionTime
            };
          }
    
        if (props.sessionId && props.sessionId !== state.sessionId) {
          return {
            sessionId: props.sessionId
          };
        }
    
        if (props.sessionName && props.sessionName !== state.sessionName) {
          return {
            sessionName: props.sessionName
          };
        }
    
        if (props.sessionLocation && props.sessionLocation !== state.sessionLocation) {
          return {
            sessionLocation: props.sessionLocation
          };
        }

        if (props.scenarioName && props.scenarioName !== state.scenarioName) {
            return {
                scenarioName: props.scenarioName
            };
        }

        if (props.scenarioDesc && props.scenarioDesc !== state.scenarioDesc) {
            return {
                scenarioDesc: props.scenarioDesc
            };
        }

        if (props.isAR !== state.isAR) {
            return {
                isAR: props.isAR
            };
        }


        return {}
      }

    render() {
        
        return (
            <div className={`${this.state.panelActive === false || this.state.isAR === true ? "hiddenObjPanelLeft" : "scene-panel"}`} class="scene-panel">
                <div class="left_sidebar_border">
                    <div class="sidebar_panel_titel">
                        <TabHeader text="SESSION LOBBY" />
                    </div>
                    <div class="sidebar_pwa08run_padding">
                        <div class="sidebar_pwa08run">
                            <div class="sidebar_pwa08run1_left"><h2>scenario</h2>
                            <p>{this.state.scenarioName}</p></div>
                        </div>
                        <div class="sidebar_pwa08run_form">
                            <h2>Description</h2>
                            <p>{this.state.scenarioDesc}</p>
                        </div>
                        <div class="sidebar_pwa08run_form">
                            <h2>name</h2>
                            <p>{this.state.sessionName}</p>
                        </div>
                        <div class="sidebar_pwa08run_form">
                            <h2>Location</h2>
                            <p>{this.state.sessionLocation}</p>
                        </div>                    
                    </div>  
                    <div class="sidebar_pane4_content">
                        <h3>Participants</h3>	<div class="sidebar_pane4_button"><a href="#inline1" class="button_gray fancybox" onClick={this.modalMetricsToggle}><span>Metrics</span></a>
                        
                        </div> 
                    </div>
                    
                    <div class="sidebar_pane5_table flex-grow"><hr/>
                        <table>
                            <tr class="sidebar_pane5_tableheader">
                                <th>User</th>
                                <th>
                                    <div class="user_metric_main">
                                        {this.state.metricDisplay.map(metricData => (      
                                            <div class="user_metric"><img src="/images/sidebar_icon1.png" title={metricData} /></div>
                                        ))}
                                    </div>
                                </th>
                            </tr>
                    {this.state.sessionUsers.map((userData,userIndex) => (
                            <tr key={userIndex}>
                            <td>{userData.avatarName} <a onClick={() => this.toggleSpectate(userData.userId, !userData.spectating)}><img src={userData.spectating === false ? "/images/user-active.png" : "/images/user-inactive.png"} alt="" /></a></td>
                            <td>
                                <div class="user_metric_main">
                                    <div class="user_metric">{(this.state.metricByUser[userData.userId] !== undefined && this.state.metricByUser[userData.userId]["stat_0"] !== undefined ? this.state.metricByUser[userData.userId]["stat_0"] : "")}</div>
                                    <div class="user_metric">{(this.state.metricByUser[userData.userId] !== undefined && this.state.metricByUser[userData.userId]["stat_1"] !== undefined ? this.state.metricByUser[userData.userId]["stat_1"] : "")}</div>
                                    <div class="user_metric">{(this.state.metricByUser[userData.userId] !== undefined && this.state.metricByUser[userData.userId]["stat_2"] !== undefined ? this.state.metricByUser[userData.userId]["stat_2"] : "")}</div>

                                </div>
                            </td> 
                            </tr>
                    ))}

                        </table>
                    </div>
                    <div class="sidebar_pane6_spectator">
                        {this.state.sessionId && this.state.matchActive === true && this.state.liteMode === true ? (<>
                        {
                            <div class="sidebar_pwa08run_label">
                                <span class="session_recording">RECORDING</span> <span class="session_time">{this.state.sessionTime}</span>
                            </div>
                        }
                        </>) : (<></>)}

                        
                        <div  class="sidebar_pane6_spectatorblue" style={{padding: '0 40px 0 20px'}}>
                            <div class="btnArrow">
                                <a onClick={this.togglePanel}>
                                    <img src={this.state.panelActive === true ? "/images/toggle-menu-out.png" : "/images/toggle-menu-in.png"} alt="Toggle Menu" />
                                </a>
                            </div>  
                            {this.state.sessionId && this.state.matchActive === true && this.state.liteMode === true ? (<>
                            

                            {this.state.sessionId && (
                                <div><a href="#" class="button_blue " onClick={this.props.toggleSessionPopup}><span>End session</span></a></div>
                            )}
                        
                        </>) : (<></>)}

                        {this.state.sessionId && this.state.matchActive === true && this.state.isReplay === true ? (<>
                            
                            <div><a href="#" class="button_blue " onClick={this.props.endSession}><span>End session</span></a></div>
                        
                        </>) : (<></>)}
                        </div>
                        
                    </div>
                    
                </div>
                <div className={`leftPanelToggle ${this.state.panelActive === false ? "showleftPanelToggle" : ""}`}>
                    <div class="bottom-toggleLeft"><a onClick={this.togglePanel}><img src={this.state.panelActive === false ? "/images/toggle-menu-in.png" : "/images/toggle-menu-out.png"} alt="Toggle Menu" /></a></div>
                </div>


                <Popup open={this.state.openMetrics} modal>
                <div id="inline1" class="fancybox_popup">
                        <h3>performance metrics</h3>
                        <a className="popup_close" onClick={this.closeMetrics}></a>
                        <div class="fancybox_popup_width"><p>Select metrics to display during session		</p>

                        {metricOptions.map(opt => (
                            <label class="manageusers15_pagecheckbox past13_sessionslabel1" for={opt.jsonName}><img src="/images/sidebar_icon1.png" /> {opt.name}<input type="checkbox" id={opt.jsonName} onChange={this.toggleMetric} value={opt.jsonName} checked={(this.state.metricDisplay.findIndex(x => x === opt.jsonName) > -1 ? true : false)} /><span class="checkmark"></span></label>
                        ))}
                                                    
                        </div>
                    </div>
            </Popup>

            </div>

            
        );
    }
}

export default SceneLeftPanel;
