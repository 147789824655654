import React, { Component, useState } from "react";
import ThreeDScene from '../../webxr/Scene';

class AR extends Component {

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
  }

  render() {

    return (
        <div>
            <ThreeDScene sessionId={this.props.toloadId} mode="AR"/>
        </div>
        
    );
  }

}

export default AR;