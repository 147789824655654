import React, { Component, useState } from "react";
import TabHeader from "../components/TabHeader";


class LeftPanelSpectate extends Component {
    constructor(props) {
        super(props);

        // setup init state
        this.state = this.getInitialState();
        this.togglePanel = this.togglePanel.bind(this);

    }

    // Settings
    getInitialState = () => ({
        sessionId: "",
		sessionName: "",
        sessionLocation: "",
        scenarioName: "",
        scenarioDesc: "",
        panelActive: true,
        isAR: false
    });

    togglePanel(){
        this.setState({ panelActive: !this.state.panelActive })
    }

    static getDerivedStateFromProps(props, state) {

        if (props.sessionId && props.sessionId !== state.sessionId) {
            return {
            sessionId: props.sessionId
            };
        }

        if (props.sessionName && props.sessionName !== state.sessionName) {
            return {
            sessionName: props.sessionName
            };
        }

        if (props.sessionLocation && props.sessionLocation !== state.sessionLocation) {
            return {
            sessionLocation: props.sessionLocation
            };
        }

        if (props.scenarioName && props.scenarioName !== state.scenarioName) {
            return {
                scenarioName: props.scenarioName
            };
        }

        if (props.scenarioDesc && props.scenarioDesc !== state.scenarioDesc) {
            return {
                scenarioDesc: props.scenarioDesc
            };
        }

        if (props.isAR !== state.isAR) {
            return {
                isAR: props.isAR
            };
        }


        return {}
    }


    async componentDidMount() {
        
    }

    componentWillUnmount() {        
    }

    render() {
        
        return (
            <div className={`${this.state.panelActive === false || this.state.isAR === true ? "hiddenObjPanelLeft" : "scene-panel"}`} class="scene-panel">
                <div class="left_sidebar_border">
                    <div class="sidebar_panel_titel">
                        <TabHeader text="SESSION LOBBY" />
                    </div>
                    <div class="sidebar_pwa08run_padding">
                        <div class="sidebar_pwa08run">
                            <div class="sidebar_pwa08run1_left"><h2>scenario</h2>
                            <p>{this.state.scenarioName}</p></div>
                        </div>
                        <div class="sidebar_pwa08run_form">
                            <h2>Description</h2>
                            <p>{this.state.scenarioDesc}</p>
                        </div>
                        <div class="sidebar_pwa08run_form">
                            <h2>name</h2>
                            <p>{this.state.sessionName}</p>
                        </div>
                        <div class="sidebar_pwa08run_form">
                            <h2>Location</h2>
                            <p>{this.state.sessionLocation}</p>
                        </div>                    
                    </div> 
                    
                    
                    <div class="sidebar_pane6_spectator">
                        <h2>scenario ready</h2>
                        {this.state.sessionId && this.state.isAR === false ? (<>
                        {
                            <div  class="sidebar_pane6_spectatorblue">
                                <a href="#" onClick={() => this.props.loadSession(this.state.sessionId)} class="button_blue"><span>join session</span></a>
                            </div>
                        }
                        </>) : (<></>)}
                    </div>
                    <div class="sidebar_pane6_spectator">
                        <div  class="sidebar_pane6_spectatorblue" style={{padding: '0 40px 0 20px'}}>
                            <div class="btnArrow">
                                <a onClick={this.togglePanel}>
                                    <img src={this.state.panelActive === true ? "/images/toggle-menu-out.png" : "/images/toggle-menu-in.png"} alt="Toggle Menu" />
                                </a>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div className={`leftPanelToggle ${this.state.panelActive === false ? "showleftPanelToggle" : ""}`}>
                    <div class="bottom-toggleLeft"><a onClick={this.togglePanel}><img src={this.state.panelActive === false ? "/images/toggle-menu-in.png" : "/images/toggle-menu-out.png"} alt="Toggle Menu" /></a></div>
                </div>

                
            </div>
        );
    }
}

export default LeftPanelSpectate;
