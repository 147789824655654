import React, { Component, useState } from "react";
import Header from "../../helpers/Header";
import ConsoleEvents from "../../utility/ConsoleEvents";
import ThreeDScene from '../../webxr/Scene';
import Auth from "../../utility/auth";

const authCheck = new Auth();

const consoleClient = new ConsoleEvents();

class Session_Run extends Component {

    constructor(props) {
      super(props);

      this.scene = React.createRef();

      this.state = this.getInitialState();

      this.dataUpdate = this.dataUpdate.bind(this);
      //header callbacks
      this.setMesh = this.setMesh.bind(this);
      this.cutObj = this.cutObj.bind(this);
      this.deleteObj = this.deleteObj.bind(this);
      this.copyObj = this.copyObj.bind(this);
      this.pasteObj = this.pasteObj.bind(this);

    }

    getInitialState = () => ({
        mesh: "color"
    });

    dataUpdate(data) {

        
        if (data.reset) {
            console.log("reset now");
            this.setState(this.getInitialState());
        }

    }

    setMesh(opt) {
        this.setState({mesh: opt});

        this.scene.current.setMesh(opt);
    }

    cutObj() {
        this.scene.current.cutObj();
    }

    copyObj() {
        this.scene.current.copyObj();
    }

    pasteObj() {
        this.scene.current.pasteObj();
    }

    deleteObj() {
        this.scene.current.deleteObj();
    }


    async componentDidMount() {

       document.title = process.env.REACT_APP_TITLE_PREFIX + "Run Session";
        
        // process props
        if (this.props.sessionId) {
            this.setState({sessionId: this.props.sessionId});
            this.scene.current.loadSession(this.props.sessionId);
        }
        
        // Check Perms
        authCheck.checkPerms(["Administrator", "Trainer", "Editor"]);

    }
	
      render() {

  return (
    <div>

    <Header 
    section="RUN SESSION" 
    liteMode={(this.state.sessionId ? true : false)} 
    meshCallback={this.setMesh} 
    cutCallback={this.cutObj} 
    delCallBack={this.deleteObj} 
    copyCallback={this.copyObj} 
    pasteCallback={this.pasteObj} 
    meshStatus={this.state.mesh} />

    <section>
        <div class="container-fluid p-0">
            <ThreeDScene builderMode="lite" dataCallBack={this.dataUpdate} ref={this.scene} mode="WEB" />
        </div> 
    </section>

    </div>
  );
}
}

export default Session_Run;
