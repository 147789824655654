import React, { Component } from "react";
import Header from "../../helpers/Header";
import Auth from "../../utility/auth";
import filesAPI from '../../utility/files';

const filesClient = new filesAPI();
const authCheck = new Auth();

class Settings_Sessions extends Component {
  constructor(props) {
    super(props);
    
    this.state = this.getInitialState();

    this.updateSessionList = this.updateSessionList.bind(this);

    this.selectSession = this.selectSession.bind(this);
    this.startReplay = this.startReplay.bind(this);
    this.handleChangeCheckbox = this.handleChangeCheckbox.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.limitSessionList = this.limitSessionList.bind(this);

  }

  getInitialState = () => ({
    loadingSessions: true,
    sessions: [],
    searchedSessions: [],
    deleteSessions: [],
    selectedSession: {},
    selectedFrameCount: 0
  });

  async updateSessionList() {
    this.setState({ loadingSessions: true })
    let sessionList = await filesClient.getSessionsList();

    if (sessionList.isSuccess === true && sessionList.sessions) {
      
      for (let i=0; i < sessionList.sessions.length; i++) {
        let scenarioData = await filesClient.getScenario(sessionList.sessions[i].scenarioId);

        if (scenarioData.isSuccess === true && scenarioData.scenario) {
          sessionList.sessions[i].scenarioName = scenarioData.scenario.title; 
        }

      }
      this.setState({sessions: sessionList.sessions});
      this.setState({ loadingSessions: false });
      this.setState({searchedSessions: sessionList.sessions});
   } else {
      // display error connectiong to files API
    }
  }

  selectSession(session) {
    
    let data = {};
    data.sessionName = session.name;
    data.sessionLocation = session.location;
    data.scenarioName = session.scenarioName;
    data.sessionId = session.id;
    data.frameCount = session.frameCount;

    this.setState({selectedSession: data});
    this.setState({selectedFrameCount: session.frameCount});
  }

  async startReplay(sessionId) {
    let sessionReplay = await filesClient.replaySession(sessionId);
    
    if (sessionReplay.isSuccess === true && sessionReplay.session) {
      // ??
      window.location = "/session/review/" + sessionId;
    } else if (sessionReplay.isSuccess === false) {
      // error
    }
    

  }

  async handleChangeCheckbox(e) {
    let currentDel = this.state.deleteSessions;

    let isHere = currentDel.indexOf(e.target.value);
    if (isHere > -1) {
      currentDel.splice(isHere, 1);
    } else {
      currentDel.push(e.target.value);
    }

    this.setState({deleteSessions: currentDel});
  }

  async handleSelAll(e) {
    if (e.target.checked === true) {
      let allChecked = [];

      this.state.sessions.forEach((session) => {
        if (session.replayable === true) {
          allChecked.push(session.id);
        }
      });   

      this.setState({deleteSessions: allChecked});
    } else if (e.target.checked === false) {
      this.setState({deleteSessions: []});
    }
  }

  async handleDelete() {
    console.log("delete sessions");

    let totalDeleted = 0;

    for (let i=0; i < this.state.deleteSessions.length; i++) {
      console.log(this.state.deleteSessions[i]);
      let sessionDelete = await filesClient.deleteReplaySession(this.state.deleteSessions[i]);

      totalDeleted++;
    }

    this.updateSessionList();
    this.setState({deleteSessions: []});
  }

  limitSessionList(e) {
    let regex = new RegExp(e.target.value, 'gi')
    let results = [];

    this.state.sessions.forEach((entity) => {
      let found = entity.name.match(regex);
      if (found != null && found.length > 0) {
        results.push(entity);
      }
    });

    this.setState({searchedSessions: results});
  }

  async componentDidMount() {
    document.body.className = 'pwa18_settings_server';
    document.title = process.env.REACT_APP_TITLE_PREFIX + "Saved Sessions";

    this.updateSessionList();

    // Check Perms
    authCheck.checkPerms(["Administrator", "Trainer"]);
  }

  async startReplay(sessionId) {
    let sessionReplay = await filesClient.replaySession(sessionId);
    
    console.log(sessionReplay);

    if (sessionReplay.isSuccess === true && sessionReplay.session) {
      // ??
      window.location = "/session/review/" + sessionId;
    } else if (sessionReplay.isSuccess === false) {
      // error
    }
    

  }

  async componentDidMount() {
    document.body.className = 'pwa18_settings_server';
    document.title = process.env.REACT_APP_TITLE_PREFIX + "Saved Sessions";

    this.updateSessionList();

    // Check Perms
    authCheck.checkPerms(["Administrator", "Trainer"]);
  }


  render() {
      return (
        <div>
          <Header section="PAST SESSIONS" />

          <section>
            <div class="left_sidebar">
              <div class="container-fluid p-0">
                <div class="row m-0">
                  <div class="col-md-8 p-0"> 
                    <div class="manage15_pagecontent">
                      <div class="manage15_pagetitel">
                        <h3>saved sessions</h3>
                        <div class="manage15_pagesearch">
                          <input class="manage15_inputsearch" placeholder="SEARCH" type="text" onChange={e => this.limitSessionList(e)} />
                          <input class="manage15_buttonsearch" type="search"  />
                        </div>
                      </div>
                      <div class="manage15_pagebuttonright ">
                        <a class={this.state.deleteSessions.length > 0 ? "button_blueborder" : "button_blueborder btn_disable"} onClick={(e) => this.handleDelete(e)}><span>Delete</span></a>
                      </div>	
                      <div class="manage15_pagetable past13_sessionstable" >
                        <table>
                          <tr>
                            <th>
                              <label class="manageusers15_pagecheckbox past13_sessionslabel1" for="checkbox1"> <input type="checkbox" id="checkbox1" onClick={(e) => this.handleSelAll(e)} />
                              <span class="checkmark"></span></label>
                            </th>
                            <th>name</th>
                            <th>Location</th>
                            <th>Scenario</th>
                            <th>Trainer</th>
                            <th>Date</th>
                            <th></th>
                          </tr>
                          {this.state.searchedSessions.filter(sessionCheck => sessionCheck.replayable === true).map(session => (
                            
                            <tr className={`settings ${this.state.selectedSession.sessionId === session.id ? "active" : ""}`} onClick={() => this.selectSession(session)}>
                              <td>
                                <label class="manageusers15_pagecheckbox" for={session.id}> <input type="checkbox" name="sessionToDelete" id={session.id} value={session.id} checked={(this.state.deleteSessions.indexOf(session.id) > -1 ? true : false)} onChange={this.handleChangeCheckbox} />
                                <span class="checkmark"></span>
                                </label>

                                
                              </td>
                              <td><div class="past13_sessionstableicon1 "></div> {session.name}</td>
                              <td>{session.location}</td>
                              <td>{session.scenarioName}</td>
                              <td></td>
                              <td>{session.createdAt}</td>
                            </tr>
                          ))}
                    </table>
                    {(this.state.loadingSessions && (
                      <div class="gridLoader">Loading...</div>
                    ))}
                  </div>
                </div>
              </div>
              <div class="col-md-4 p-0" style={{display: Object.keys(this.state.selectedSession).length > 0 ? 'block' : 'none' }}>
                <div class="rightsidebar_manageusers">
              <div class="flex-grow">
                <div class="p-md">
                  <div class="row sidebar_13pastmarginb">
                    <div class="col-md-7 "> 
                      <div class="sidebar_13pastbox1">
                        <h3>Session Name</h3>
                          {this.state.selectedSession.sessionName}
                      </div>
                    </div>
                    <div class="col-md-5"> 
                      <div class="sidebar_13pastbox1">
                        <h3>Location</h3>
                          <p>{this.state.selectedSession.location}</p>
                      </div>
                    </div>
                  </div>
                  <div class="row sidebar_13pastmarginb">
                    <div class="col-md-7 "> 
                      <div class="sidebar_13pastbox1">
                        <h3>scenario</h3>
                          <p>{this.state.selectedSession.scenarioName}</p>
                        </div>
                      </div>
                      <div class="col-md-5"> 
                        <div class="sidebar_13pastbox1">
                          <h3>Duration</h3>
                          <p>{new Date(Math.round(this.state.selectedFrameCount/process.env.REACT_APP_FPS) * 1000).toISOString().substr(11, 8)}</p>
                        </div>
                      </div>
                    </div>
                    <div class="sidebar_13pastmarginb">
                      <div class="sidebar_13pastbox1">
                        <h3>Trainer</h3>
                        <p></p>
                      </div> 
                    </div>
                    <div class="sidebar_13pastselect1">
                      <div class="sidebar_13pastbox1">
                        <h3>Participants</h3>
                        <select>
                          <option>(5 Participants, 1 Spectator)</option>
                          <option>(5 Participants, 1 Spectator)</option>
                          <option>(5 Participants, 1 Spectator)</option>
                          <option>(5 Participants, 1 Spectator)</option>
                        </select>
                      </div> 
                    </div>
                    <div class="sidebar_13pastname1">
                      <p>User Name1 </p>
                      <p>User Name2 </p>
                      <p>User Name3 </p>
                      <p>User Name4 </p>
                      <p>User Name5 </p>
                      <p>User Name6 <img src="/images/sidebar_nemeicon1.png" /> </p>
                    </div> 
                </div>
              </div>
              <div class="settings-actions p-lg">
		            <a href="#" class="button_blue" onClick={() => this.startReplay(this.state.selectedSession.sessionId)}><span>Review Session</span></a>
              </div>
            </div>
          </div>
        </div>
            
            </div>
            </div>

          </section>
          <footer> </footer>

        </div>
      );
    }
}

export default Settings_Sessions;
