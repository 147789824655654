import React from "react";
import { useSearchParams } from "react-router-dom";
import Auth from "../utility/auth";

const authCheck = new Auth();

function Login() {
    const [searchParams] = useSearchParams();
    function handleSubmit(e) {
        e.preventDefault();
        console.log('You clicked submit');
        
        authCheck.createSignIn();
    }

    function LoginDisplay() {
        let oidcCode = searchParams.get("code");
        let oidcState = searchParams.get("state");

        if (oidcCode && oidcState) {
            authCheck.processSignin(oidcCode, oidcState);

            return (<div class="home-container">
                    <header></header>        
                    <section>
                        <div class="desktop_logo_wrapper">
                            <div class="desktop_logo">
                                <img src="/images/logo.png" alt="Logo" onClick={handleSubmit} />
                                    <h2 class="desktop_logo_titel">Ver: {process.env.REACT_APP_RELEASE}</h2>
                                    <h2>Team Bravo (192.168.1.34)</h2>
                            </div>
                            <div class="desktop_form">
                            <h1>LOGGING IN...</h1>
                            </div>
                        </div>
                    </section>
                    <footer> </footer>
                </div>);
        } else {
            return (
                <div class="home-container">
                    <header></header>        
                    <section>
                        <div class="desktop_logo_wrapper">
                            <div class="desktop_logo">
                                <img src="/images/logo.png" alt="Logo" onClick={handleSubmit} />
                                    <h2 class="desktop_logo_titel">Ver: {process.env.REACT_APP_RELEASE}</h2>
                                    <h2>Team Bravo (192.168.1.34)</h2>
                            </div>
                            <div class="desktop_form">
                                <form onSubmit={handleSubmit}>
                                <div class="desktop_form_submit">
                                    <input value="login" type="submit" class="type_submit" />
                                </div>
                                </form>
                            </div>
                        </div>
                    </section>
                    <footer> </footer>
                </div>
                
            );
        }
        
    }

    document.body.className = 'body_desktop';
    

    return (<LoginDisplay />);
}

export default Login;
