import React, { Component, useState } from "react";
import TabHeader from "../components/TabHeader";
import Popup from 'reactjs-popup';
import filesAPI from '../utility/files';

const filesClient = new filesAPI();

class LeftPanelRun extends Component {
    constructor(props) {
        super(props);

        // setup init state
        this.state = this.getInitialState();    
        this.togglePanel = this.togglePanel.bind(this);

        this.updateScenarioList = this.updateScenarioList.bind(this);

        this.modalScenarioToggle = this.modalScenarioToggle.bind(this);
        this.closeModalScenarioList = this.closeModalScenarioList.bind(this);
        this.updateScenarioList = this.updateScenarioList.bind(this);
        this.addscenario = this.addscenario.bind(this);
        this.startSession = this.startSession.bind(this);

        this.updateSessionName = this.updateSessionName.bind(this);
        this.updateSessionLocation = this.updateSessionLocation.bind(this);
    }

    // Settings
    getInitialState = () => ({
        scenarios: [],
        panelActive: true,
        openScenarioList: false,
        scenarioId: "",
        sessionName: "",
        sessionLocation: "",
        scenarioName: "--",
        scenarioDesc: "",
    });

    togglePanel(){
        this.setState({ panelActive: !this.state.panelActive })
    }

    modalScenarioToggle() {
        this.setState({openScenarioList: !this.state.openScenarioList});
    }

    closeModalScenarioList() {
        this.setState({openScenarioList: false});
    }

    updateSessionName(evt) {
        this.setState({sessionName: evt.target.value});
    }

    updateSessionLocation(evt) {
        this.setState({sessionLocation: evt.target.value});
    }

    async updateScenarioList() {
        let scenariosList = await filesClient.getScenariosList();
        
        if (scenariosList.isSuccess === true && scenariosList.scenarios) {
            this.setState({scenarios: scenariosList.scenarios});
        } else {
            // display error connectiong to files API
        }
    }

    addscenario(scenario) {
        if (scenario && scenario.id) {
            if (scenario.title) {
                this.setState({scenarioName: scenario.title});
            } else {
                this.setState({scenarioName: scenario.id});
            }

            this.setState({scenarioDesc: scenario.description});
            
            this.setState({scenarioId: scenario.id});

            this.setState({openScenarioList: false});
        }
    }

    async startSession() {

        this.setState({errorMsg: ""});

        if (this.state.scenarioId && this.state.sessionName) {
            let data = {};
            data.name = this.state.sessionName;
            data.scenarioId = this.state.scenarioId;
            data.location = this.state.sessionLocation;
            let sessionStart = await filesClient.createSession(data);

            if (sessionStart.isSuccess === true && sessionStart.session) {
                this.setState({sessionId: sessionStart.session.id});

                // start session
                //this.setState({isRender: true});
                this.props.loadSession(sessionStart.session.id);
            } else if (sessionStart.isSuccess === false) {
                this.setState({errorMsg: "Error creating session, session name required to be unique"});    
            }
            
        } else {
            this.setState({errorMsg: "Session name and scenario required"});
        }
        

    }

    async componentDidMount() {
        this.updateScenarioList();
    }

    componentWillUnmount() {        
    }

    render() {
        
        return (
            <div className={`${this.state.panelActive === false ? "hiddenObjPanelLeft" : "scene-panel"}`} class="scene-panel">
                <div class="left_sidebar_border">
                    <div class="sidebar_panel_titel">
                        <TabHeader text="SESSION LOBBY" />
                    </div>
                    <div class="sidebar_pwa08run_padding">
                        <div class="sidebar_pwa08run">
                            <div class="sidebar_pwa08run1_left"><h2>scenario</h2>
                            <p>{this.state.scenarioName}</p></div>
                            <div class="sidebar_pwa08run1_right">
                                <a href="#" class="button_gray" onClick={this.modalScenarioToggle}><span>change</span></a>
                            </div>
                        </div>
                        <div class="sidebar_pwa08run_form">
                            <h2>Description</h2>
                            <p>{this.state.scenarioDesc}</p>
                        </div>
                        {this.state.errorMsg ? (<>
                        <div class="sidebar_pwa08run_form alertError">
                            ERROR: {this.state.errorMsg}
                        </div>
                        </>) : (<></>)}
                        <div class="sidebar_pwa08run_form">
                            <h2>name</h2>
                            <input class="sidebar_pwa08run_forminput" placeholder="Enter Name.." value={this.state.sessionName} onChange={evt => this.updateSessionName(evt)} />
                        </div>
                        <div class="sidebar_pwa08run_form">
                            <h2>Location</h2>
                            <input class="sidebar_pwa08run_forminput" value={this.state.sessionLocation} onChange={evt => this.updateSessionLocation(evt)} placeholder="Enter Location.." />
                        </div>                    
                    </div>  
                    <div class="sidebar_pane4_content">
                        <h3>Participants</h3>	<div class="sidebar_pane4_button"><a href="#inline1" class="button_gray fancybox" onClick={this.modalMetricsToggle}><span>Metrics</span></a>
                        
                        </div> 
                    </div>
                    
                    <div class="sidebar_pane5_table flex-grow"><hr/>
                        <table>
                            <tr class="sidebar_pane5_tableheader">
                                <th>Status</th>
                                <th>User</th>
                                <th>room</th> 
                            </tr>
                    

                        </table>
                    </div>
                    <div class="sidebar_pane6_spectator">
                        <div  class="sidebar_pane6_spectatorblue" style={{padding: '0 40px 0 20px'}}>
                            <div class="btnArrow">
                                <a onClick={this.togglePanel}>
                                    <img src={this.state.panelActive === true ? "/images/toggle-menu-out.png" : "/images/toggle-menu-in.png"} alt="Toggle Menu" />
                                </a>
                            </div>
                            <div><a href="#" class="button_blue" onClick={this.startSession}><span>Start session</span></a></div>                       
                        </div>
                    </div>
                    
                </div>
                <div className={`leftPanelToggle ${this.state.panelActive === false ? "showleftPanelToggle" : ""}`}>
                    <div class="bottom-toggleLeft"><a onClick={this.togglePanel}><img src={this.state.panelActive === false ? "/images/toggle-menu-in.png" : "/images/toggle-menu-out.png"} alt="Toggle Menu" /></a></div>
                </div>

                <Popup open={this.state.openScenarioList} modal>
                    <div class="fancybox_popup">
                            <h3>Load Scenario</h3>
                            <a className="popup_close" onClick={this.closeModalScenarioList}></a>
                            <div class="fancybox_popup_width">
                                <table>
                                    <tr>
                                        <td><p>NAME</p></td>
                                        <td><p>LAST MODIFIED</p></td>
                                    </tr>
                                    {this.state.scenarios.map(scenario => (
                                    <tr>
                                        <td><a href="#" onClick={() => this.addscenario(scenario)}>{scenario.title ? scenario.title : scenario.id}</a></td>
                                        <td>{scenario.updatedAt}</td>
                                    </tr>
                                    ))}
                                    
                                </table>        

                            </div>
                        </div>
                </Popup>

            </div>
        );
    }
}

export default LeftPanelRun;
