import React, { Component, useState } from "react";
import Debug_Header from "./debug_header";

import nakamaConnection from '../../utility/nakama';
import filesAPI from '../../utility/files';
const filesClient = new filesAPI();


let nakamaAgent;

class Debug_Match extends Component {

  constructor(props) {
    super(props);
    this.state = {
      match_id: "",
      match_data: "",
      match_send: "",
      sessions: [],
      match_active: false
    };

    this.joinMatch = this.joinMatch.bind(this);
    this.updateMatchId = this.updateMatchId.bind(this);

    this.matchDataUpdate = this.matchDataUpdate.bind(this);

    this.sendData = this.sendData.bind(this);
    this.updateMatchToSend = this.updateMatchToSend.bind(this);
    this.updateMatchAndJoin = this.updateMatchAndJoin.bind(this);

  }

  updateMatchAndJoin(session) {
    this.setState({match_id: session.matchId}, () => { 
      this.joinMatch(); 
    });
    
  }

  updateMatchId(evt) {
    this.setState({match_id: evt.target.value});
  }

  updateMatchToSend(evt) {
    this.setState({match_send: evt.target.value});
  }

  sendData() {
    console.log("SendData");
    console.log(this.state.match_send);
    
    /*
    let jsonData = JSON.parse(this.state.match_send);
    console.log(jsonData);
    console.log(JSON.stringify(jsonData));
    */
    //let json = JSON.parse(payload);

  	//return JSON.stringify(json);


    nakamaAgent.sendData(this.state.match_id, 0, JSON.parse(this.state.match_send));

  }

  joinMatch() {
    nakamaAgent = new nakamaConnection(this.state.match_id);

    this.setState({match_data: "join match: " + this.state.match_id});
    
    // connect to nakama
    const nakamaConn = nakamaAgent.connect(this.matchDataUpdate);

    this.setState({match_active: true});

  }

  matchDataUpdate(matchData) {
    //this.setState({match_data: JSON.stringify(matchData, null, 1)});
    this.setState({match_data: JSON.stringify(matchData)});
  }

  async updateSessionList() {
    let sessionList = await filesClient.getSessionsList();
    
    if (sessionList.isSuccess == true && sessionList.sessions) {
      this.setState({sessions: sessionList.sessions})
    } else {
      // display error connectiong to files API
    }
  }

  async restartSession(session) {
    let sessionReset = await filesClient.resetSession(session.id);
  }

  async showSession(session) {
    let sessionShow = await filesClient.showSession(session.id);
  }

  async hideSession(session) {
    let sessionHide = await filesClient.hideSession(session.id);
  }

  async playSession(session) {
    let sessionPlay = await filesClient.playSession(session.id);

    console.log(sessionPlay);
  }

  async pauseSession(session) {
    let sessionPause = await filesClient.pauseSession(session.id);
  }




  async componentDidMount() {
    
    this.updateSessionList();
  }

  componentWillUnmount() {
    console.log("unmount match");

    if (this.state.match_active === true) {
      nakamaAgent.closeConnection();
    }
    
  }

  render() {

  return (
      <div class="debugScroll">
        <Debug_Header />
      
        <div>
        <div><h3>Sessions</h3></div>
        <div>
            <ul>
            {this.state.sessions.filter(sessionCheck => sessionCheck.matchId).map(session => (
                <li>{session.name} - {session.matchId} <button onClick={() => this.updateMatchAndJoin(session)}>JOIN</button> 
                - <button onClick={() => this.restartSession(session)}>RESET</button>
                - <button onClick={() => this.showSession(session)}>SHOW</button>
                - <button onClick={() => this.hideSession(session)}>HIDE</button>
                - <button onClick={() => this.playSession(session)}>PLAY</button>
                - <button onClick={() => this.pauseSession(session)}>PAUSE</button>
                </li>
            ))}
            </ul>
        </div>

          <div>MATCH ID</div>
          <div><input onChange={evt => this.updateMatchId(evt)} value={this.state.match_id} /></div>
          <div><button onClick={this.joinMatch}>Join Match</button></div>
          <div>Match Response</div>
          <div>
              <textarea cols="200" rows="25" value={this.state.match_data}></textarea>
          </div>
          <div><hr /></div>
          <div><button onClick={this.sendData}>Send Data</button></div>
          <div>SEND DATA</div>
          <div>
              <textarea cols="200" rows="25" onChange={evt => this.updateMatchToSend(evt)}></textarea>
          </div>

        </div>
      </div>
  );
}

}

export default Debug_Match;