

export default class ConsoleEvents {
	
    constructor() {
		
		if (process.env.REACT_APP_DEBUG === "true") {
			this.debug = true;
		} else {
			this.debug = false;
		}
	}

	sendLogMsg(msg) {
		if (this.debug === true) {
			console.log(msg);
		}
	}

	sendInfo(msg, obj) {
		if (this.debug === true) {
			console.info(msg, obj);
		}
	}

	errorMsg(msg) {
		if (this.debug === true) {
			console.error(msg);
		}
	}
}