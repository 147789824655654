import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';

export default class Loader {
    constructor() {
        this.fbxLoader = new FBXLoader();
        this.objLoader = new OBJLoader();

        this.apiURL = process.env.REACT_APP_FILES_API;
    }

    // Async Load
    async loadMeshAsync(assetObjUrl, callback, assetObj, tag) {
        await this.objLoader.loadAsync(this.apiURL+assetObjUrl).then(object => { 
            callback(object, assetObj, tag); });
    }

    async loadFBXAsync(assetObjUrl, callback, assetObj) {
        await this.fbxLoader.loadAsync(this.apiURL+assetObjUrl).then(object => { 
            callback(object, assetObj); });
    }

    async loadFBXAnimAsync(assetObjUrl, callback, assetObj, animateName, defaultAnimation = "") {
        await this.fbxLoader.loadAsync(this.apiURL+assetObjUrl).then(object => { 
            callback(object, assetObj, animateName, defaultAnimation); });
    }

    // Testing

    async loadFBXLocalAsync(assetObjUrl, callback, assetObj) {
        await this.fbxLoader.loadAsync(assetObjUrl).then(object => { 
            callback(object, assetObj); });
    }

    async loadFBXAnimAsyncLOCAL(assetObjUrl, callback, assetObj) {
        await this.fbxLoader.loadAsync(assetObjUrl).then(object => { 
            callback(object, assetObj); });
    }
    
}