import React, {Component} from "react";
import Header from "../../helpers/Header";
import {Link} from "react-router-dom";
import Settings_Nav from "./Settings_Nav";
import Popup from "reactjs-popup";
import './Settings.scss';

import filesAPI from '../../utility/files';
import Auth from "../../utility/auth";

const authCheck = new Auth();
const filesClient = new filesAPI();

class Settings_Scenarios extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openDelete: false,
            openEdit: false,
            saveEdit: true,
            selectedName: null,
            isLoaded: false,
            scenarios: [],
            selected: {},
            value:'',
        };

        document.body.className = 'pwa18_settings_server';
    }

    async componentDidMount() {
        this.getScenarios();

        document.title = process.env.REACT_APP_TITLE_PREFIX + "Scenario Settings";

        // Check Perms
        authCheck.checkPerms(["Administrator", "Editor"]);
    }

    async getScenarios() {
        let scenariosList = await filesClient.getScenariosList();

        if (scenariosList.isSuccess == true && scenariosList.scenarios) {
            this.setState({scenarios: scenariosList.scenarios})
        } else {
            // display error connectiong to files API
            //console.log("Scenario error lookup");
        }
    }

    openDeleteModal(e,s) {
        this.setState({openDelete: true,selected: s})
    }

    closeDeleteModal = () => {
        this.setState({openDelete: false})
    }

    DeleteModal() {
        const {state,closeDeleteModal,handleDelete} = this,
            {openDelete,selected} = state;

        return (<Popup open={openDelete} onClose={closeDeleteModal} modal>
            <div id="settings-delete-scenario" class="fancybox_popup">
                <h3>Delete</h3>
                <a className="popup_close" onClick={closeDeleteModal}></a>

                <div class="builder05left_popup">
                    <div class="row m-0">
                        <div class="col-md-12 p-0">
                            <div class="builder05contnet_popup">
                                <h4>Are you sure that you want to delete this item?</h4>
                                <p>{selected.name || selected.title || selected.id}</p>

                                <div>
                                    <a href="#" onClick={handleDelete} className="button_blue"><span>Yes</span></a>
                                    <a href="#" onClick={closeDeleteModal} className="button_gray"><span>No</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Popup>);
    };

    handleDelete = async (e) => {
        e.preventDefault();

        let res = await filesClient.deleteScenario(this.state.selected.id);
        if (res.isSuccess == true) {
            this.getScenarios();
        }
        this.closeDeleteModal();
    }

    openEditModal(e,s) {
        e.preventDefault();
        this.setState({openEdit: true,selected: s, value: s.title || ''})
    }

    closeEditModal = (e) => {
        e && e.preventDefault();
        this.setState({openEdit: false})
    }

    EditModal() {
        const {state,closeEditModal,handleEdit} = this,
            {openEdit,selected} = state;

        return (<Popup open={openEdit} onClose={closeEditModal} modal>
            <div id="settings-edit-scenario" class="fancybox_popup">
                <h3>Rename</h3>
                <a className="popup_close" onClick={closeEditModal}></a>

                <div class="builder05left_popup">
                    <div class="row m-0">
                        <div class="col-md-12 p-0">
                            <div class="builder05contnet_popup">
                                <h4>Edit Name</h4>
                                <input type="text" value={this.state.value} onChange={this.handleTitleChange} onKeyPress={this.handleKeyPress}/>

                                {!this.state.saveEdit && <div className="error">Asset with that name already exists</div>}
                                <div>
                                    <a href="#" onClick={handleEdit} className={this.state.saveEdit ? 'button_blue' : 'button_gray disabled'}><span>Save</span></a>
                                    <a href="#" onClick={closeEditModal} className="button_gray"><span>Cancel</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Popup>);
    };

    handleKeyPress = (e) => {
        if (e.charCode === 13) {
            this.handleEdit(e);
        }
    }

    handleTitleChange = (e) => {
        this.setState({saveEdit: true, value: e.target.value});
    }

    exists = (v) => {
        return this.state.scenarios.find(a => a.title === v && a.id != this.state.selected.id);
    }

    handleEdit = async (e) => {
        e.preventDefault();

        if (this.exists(this.state.value)) {
            return this.setState({saveEdit:false});
        }

        if (this.state.selected.title === this.state.value) {
            return this.closeEditModal();
        }

        const data = {"themeId":this.state.selected.themeId, "title": this.state.value};
        const res = await filesClient.updateScenario(this.state.selected.id, data);

        if (res.isSuccess == true) {
            this.getScenarios();
        }
        this.closeEditModal();
    }

    renderList() {
        return this.state.scenarios.map((s,i) => {
            return (
                <tr key={s.id}>
                    <td>{s.title || s.id}</td>
                    <td>{s.updatedAt}</td>
                    <td>
                        <div className="settings19_pagetable_button">
                            <a href="#" className="button_blueborder" onClick={(e) => this.openEditModal(e,s)}><span>Rename</span></a>
                            <Link to={"/builder/"+s.id} className="button_blueborder"><span>edit</span></Link>
                            <a href="#" className="button_blueborder" onClick={(e) => this.openDeleteModal(e,s)}><span>Delete</span></a>
                        </div>
                    </td>
                </tr>);
        })
    }

    render () {
        return (
            <div>
                <Header section="SETTINGS"/>
                <section>
                    <div class="left_sidebar">
                        <div class="container-fluid p-0">
                            <div class="row m-0">
                                <Settings_Nav/>
                                <div class="col-md-9 p-0">

                                    <div class="settings19_pagecontent">
                                        <div class="settings19_pagetitel">
                                            <h3>Scenarios</h3>
                                            <div class="settings19_pagebuttonright">
                                                <Link to="/builder" className="button_blueborder"><span>New Scenario</span></Link>
                                            </div>
                                        </div>
                                        <div class="settings19_pagetable">
                                            <table>
                                                <thead>
                                                <tr>
                                                    <th>name</th>
                                                    <th>Last modified</th>
                                                    <th></th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {this.renderList()}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </section>

                {this.DeleteModal()}
                {this.EditModal()}
                <footer></footer>


            </div>
        );
    }
}

export default Settings_Scenarios;
