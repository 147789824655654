import React, { Component } from "react";
import { useSearchParams } from "react-router-dom";

class MLRedirect extends Component {
    constructor(props) {
        super(props);
        
        this.state = this.getInitialState();
    }

    getInitialState = () => ({
        redirectURL: ""
    });

    redirectURL() {
        var searchParams = new URLSearchParams(window.location.search);

        let oidcCode = searchParams.get("code");
        let oidcState = searchParams.get("state");
        let oidcScope = searchParams.get("scope");

        var val = '';
        
        if (oidcState) {
            val = 'redirecturi://{"code" : "' + oidcCode + '", "scope" : "' +  oidcScope + '", "state" : "' +  oidcState + '"}'; 
        } else {
            val = 'redirecturi://{"code" : "' + oidcCode + '", "scope" : "' +  oidcScope + '"}';
        }
        console.log(val);
        //location.href = val;

        window.location = val;
    }

    componentDidMount() {
        document.body.className = 'body_desktop';
        document.title = process.env.REACT_APP_TITLE_PREFIX + "Redirect";
    }

    render() {
        return (
            <div>
                <header></header>        
                <section>
                    <div>
                        <center>
                            <button onClick={this.redirectURL}>Return to the application</button>
                        </center>
                    </div>
                    
                </section>
                <footer> </footer>
            </div>
            
        );
    }

}

export default MLRedirect;
