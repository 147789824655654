import React, {Component} from "react";
import Header from "../../helpers/Header";
import Popup from 'reactjs-popup';
import './Settings.scss';

import filesAPI from '../../utility/files';
import ConsoleEvents from '../../utility/ConsoleEvents';
import Auth from "../../utility/auth";

const authCheck = new Auth();
const filesClient = new filesAPI();
const consoleClient = new ConsoleEvents();

const defaultUserStats = {
    friendlies_flagged: 0,
    kills: 0,
    logins: 0,
    shots_fired: 0,
    shots_hit: 0,
    shots_missed: 0,
    logins: 0,
    date_of_last_session: 0,
    total_sessions: 0,
    time_in_sessions: 0
};

class Settings_Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabState: 0, openDelete: false, saveEdit: true, search: '', users: [], roles: [], selected: {}, selectedRole: "", userStats: defaultUserStats
        }

        document.body.className = 'pwa18_settings_server';
    }

    async componentDidMount() {

        document.title = process.env.REACT_APP_TITLE_PREFIX + "Manage Users";

        this.getUsers();
        this.getRoles();

        // Check Perms
        authCheck.checkPerms(["Administrator"]);
    }

    async getUsers() {
        let userList = await filesClient.getUsersList();
        if (userList.isSuccess == true && userList.users) {
            userList.users.sort((a,b) => a.firstName.toLowerCase() > b.firstName.toLowerCase() ? 1 : -1)
            if (userList.users.length) {
                this.getUserById(userList.users[0].id);
                this.state.tabState = 1;
            }
            this.setState({...this.state, users: userList.users});
        } else {
            // display error connectiong to files API
            consoleClient.sendLogMsg("Users list error lookup");
        }

    }

    async getRoles() {
        let roleList = await filesClient.getRolesList();
        
        if (roleList.isSuccess === true && roleList.roles) {

            this.setState({...this.state, roles: roleList.roles});
        }
    }


    async getUserById(id) {
        const i = id || this.state.selected.id;
        let user = await filesClient.getUserById(i);
        if (user.isSuccess == true && user.user) {
            this.setState({selected: user.user, users: this.state.users.map(u => {
                    if (u.id === i) {
                        u = user.user;
                    }
                    return u;
                })
            })
        } else {
            // display error connectiong to files API
            consoleClient.sendLogMsg("User error lookup");
        }

        // Get User Stats
        let stats = await filesClient.getUserStats(i);

        console.log("stats");
        console.log(stats);

        if (stats && stats.user_id === i) {
            this.setState({userStats: stats});
        } else {
            this.setState({userStats: defaultUserStats});
        }

    }

    exists = (v) => {
        return this.state.users.find(a => a.userName === v && a.id != this.state.selected.id);
    }

    handleTab = (e, tabIndex) => {
        e.preventDefault();

        if (tabIndex === 3) {
            this.state.selected = {};
        }
        this.setState({...this.state, tabState: tabIndex});
    };

    openDeleteModal(e, user) {
        e.preventDefault();
        this.setState({openDelete: true, selected: user})
    }

    closeDeleteModal = () => {
        this.setState({openDelete: false})
    }

    handleDelete = async (e) => {
        e.preventDefault();

        let res = await filesClient.deleteUser(this.state.selected.id);
        if (res.isSuccess == true) {
            this.getUsers();
        }
        this.closeDeleteModal();
    }

    handleSelect = (e, u) => {
        this.setState({selectedRole: ""});
        const t = this.state.tabState;
        u.password = "";

        this.setState({tabState: t !== 1 && t!== 2 ? 1 : t, selected: u}, () => this.getUserById());

    }

    handleChangeCheckbox = (e) => {

        const target = e.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            saveEdit: true,
            selectedRole: value
        });
    }

    handleChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            saveEdit: true,
            selected: {...this.state.selected, [name]: value}
        });
    }

    handleSearchChange = (e) => {
        const v = e.target.value;
        this.setState({search: v, users: this.state.users.map(u => {
                u.visible = true;
                if (v && !(new RegExp(v,'i')).test(u.firstName + ' ' + u.lastName) ) {
                    u.visible = false;
                }
                return u;
            })
        })
    }

    handleAdd = async (e) => {
        e.preventDefault();
        const {userName, firstName, lastName, password} = this.state.selected;

        let selectedRole = "";
        if (this.state.selectedRole !== "") {
            selectedRole = this.state.selectedRole;
        } 

        if (!userName || !firstName || !lastName || !selectedRole || this.exists(userName)) {
            return this.setState({saveEdit: false});
        }

        const data = {firstName, lastName, userName, password};

        const res = await filesClient.createUser(data);

        if (res.isSuccess == true) {
            if (res.user && res.user.id) {
                let addRole = filesClient.addRole(res.user.id, selectedRole);
            }

            this.getUsers();
        } else {

        }
    }

    handleEdit = async (e) => {
        e.preventDefault();
        const {firstName, lastName, userName, id, password, roles} = this.state.selected;

        let selectedRole = "";
        let oldRole = "";
        if (this.state.selectedRole !== "") {
            selectedRole = this.state.selectedRole;
        } 
        if (roles !== undefined && roles.length > 0) {

            if (selectedRole === "") {
                selectedRole = roles[0].id;
            }

            oldRole = roles[0].id;
            
        }

        if (!firstName || !lastName || !selectedRole) {
            return this.setState({saveEdit: false});
        }

        let data = {firstName, lastName, userName};
        if (password !== "") {
            data.password = password;
        }

        const res = await filesClient.updateUser(id, data);

        if (res.isSuccess == true) {
            this.getUsers();
        } else {

        }

        if (selectedRole !== "") {
            let addRole = filesClient.addRole(id, selectedRole);
        }
    }

    DeleteModal() {
        const {state, closeDeleteModal, handleDelete} = this, {openDelete, selected} = state;

        return (<Popup open={openDelete} onClose={closeDeleteModal} modal>
            <div id="settings-delete-map" className="fancybox_popup">
                <h3>Delete</h3>
                <a className="popup_close" onClick={closeDeleteModal}></a>

                <div className="builder05left_popup">
                    <div className="row m-0">
                        <div className="col-md-12 p-0">
                            <div className="builder05contnet_popup">
                                <h4>Are you sure that you want to delete this user?</h4>
                                <p>{selected.firstName + ' ' + selected.lastName}</p>

                                <div>
                                    <a href="#" onClick={handleDelete} className="button_blue"><span>Yes</span></a>
                                    <a href="#" onClick={closeDeleteModal} className="button_gray"><span>No</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Popup>);
    };

    renderRoleList() {
        const {handleChangeCheckbox, state} = this, {tabState, selected, saveEdit} = state, {firstName, lastName, userName, password, roles} = selected;

        let selectedRole = [];
        if (this.state.selectedRole !== "") {
            let roleData = {};
            roleData.id = this.state.selectedRole;
            selectedRole = [roleData];
        } else if (roles !== undefined && roles.length > 0) {
            selectedRole = roles;
        }
        
        return this.state.roles.map((r, i) => {

            let isChecked = false;
            if (selectedRole.length > 0) {
                isChecked = selectedRole.filter(roleData => {
                    return (roleData.id === r.id ? true : false);
                });
            }

            return (<label key={i} className="manageusers15_pagecheckbox settings-enabled"
            htmlFor={r.title}>{r.title}<input
            checked={(isChecked.length > 0 ? true : false)}
            name="roleLevel"
            value={r.id}
            onChange={this.handleChangeCheckbox}
            type="checkbox" id={r.title}/>
            <span className="checkmark"></span></label>);

        });
    }

    renderList() {
        return this.state.users.map((u, i) => {
            return u.visible !== false && (<tr key={u.id} onClick={(e) => this.handleSelect(e, u)}
                        className={u.id == this.state.selected.id ? "active" : ''}>
                <td>{u.firstName + ' ' + u.lastName}</td>
                <td>{u.loggedInAt}</td>
                <td>{u.Roles}</td>
                <td>
                    <div className="manage15_pagetable_button">
                        <a href="#" onClick={(e) => this.openDeleteModal(e, u)}
                           className="button_blueborder"><span>Delete</span></a>
                    </div>
                </td>
            </tr>);
        });
    }

    renderDetails() {
        const {handleChange, handleEdit, state} = this, {tabState, selected, saveEdit} = state, {
            firstName, lastName, userName, password, roles
        } = selected;

        return selected.id && (<div id="tabs-1" className={`ui-tabs-panel ui-corner-bottom ui-widget-content ${tabState === 1 ? "" : "tabs_container"}`}>
            <div className="tabs_container_padding">
                <div className="settings18_pagebox1">
                    <h3>First Name</h3>
                    <input
                        name="firstName"
                        value={firstName}
                        onChange={handleChange}
                        className="settings18_pageboxinput"
                        type="text"/>
                    {!saveEdit && !firstName && <div className="error">First name is required</div>}
                </div>
                <div className="settings18_pagebox1">
                    <h3>Last Name</h3>
                    <input
                        name="lastName"
                        value={lastName}
                        onChange={handleChange}
                        className="settings18_pageboxinput"
                        type="text"/>
                    {!saveEdit && !lastName && <div className="error">Last name is required</div>}
                </div>
                <div className="settings18_pagebox1">
                    <h3>Email</h3>
                    <input
                        name="userName"
                        value={userName}
                        onChange={handleChange}
                        className="settings18_pageboxinput"
                        type="text" disabled={true} />
                </div>
                <div className="settings18_pagebox1">
                    <h3>Password</h3>
                    <input
                        name="password"
                        value={password}
                        onChange={handleChange}
                        className="settings18_pageboxinput"
                        type="password"/>
                </div>
                <div className="settings18_pagebox1">
                    <h3>Roles</h3>
                    {this.renderRoleList()}

                </div>
                <div className="settings18_pagebox1">
                    <a href="#" style={{'marginLeft':'25px'}} className="button_blue" onClick={handleEdit}><span>save</span></a>
                </div>
            </div>
        </div>)
    }

    rederTimeStamp(timeStamp) {
        let newDate = new Date(timeStamp*1000);

        return newDate.toLocaleDateString();
    }

    renderStats() {
        const { tabState, selected } = this.state;
        let userData = this.state.userStats;
        
        return selected.id && (<div id="tabs-2" className={`ui-tabs-panel ui-corner-bottom ui-widget-content ${tabState === 2 ? "" : "tabs_container"}`}>
            <div className="tabs_container_padding">
                <div className="settings18_pagebox1 manage16_usersstats_pagebox1">
                    <h3>{selected.firstName + ' ' + selected.lastName}</h3>
                    <p>Total kills: {userData.kills}</p>
                    <p>Total shots fired: {userData.shots_fired}</p>
                    <p>Total shots missed: {userData.shots_missed}</p>
                    <p>Total shots hit: {userData.shots_hit}</p>

                    <p>Total sessions: {userData.total_sessions}</p>
                    <p>Total time in sessions: {userData.time_in_sessions}</p>
                    <p>Date of last session: {userData.date_of_last_session !== 0 ? this.rederTimeStamp(userData.date_of_last_session) : ""}</p>
                </div>
            </div>
        </div>)
    }

    renderAdd() {
        const {handleChange, handleAdd, state} = this, {tabState, selected, saveEdit, selectedRole} = state, {
            firstName, lastName, userName, password, roles
        } = selected;

        return tabState === 3 && (<div id="tabs-3"
                     className="ui-tabs-panel ui-corner-bottom ui-widget-content">
            <div className="tabs_container_padding">
                <div className="settings18_pagebox1">
                    <h3>First Name</h3>
                    <input
                        name="firstName"
                        value={firstName}
                        onChange={handleChange}
                        className="settings18_pageboxinput"
                        type="text"/>
                    {!saveEdit && !firstName && <div className="error">First name is required</div>}
                </div>
                <div className="settings18_pagebox1">
                    <h3>Last Name</h3>
                    <input
                        name="lastName"
                        value={lastName}
                        onChange={handleChange}
                        className="settings18_pageboxinput"
                        type="text"/>
                    {!saveEdit && !lastName && <div className="error">Last name is required</div>}
                </div>
                <div className="settings18_pagebox1">
                    <h3>Email</h3>
                    <input
                        name="userName"
                        value={userName}
                        onChange={handleChange}
                        className="settings18_pageboxinput"
                        type="text"/>
                    {!saveEdit && this.exists(userName) && <div className="error">User with this name already exists</div>}
                    {!saveEdit && !userName && <div className="error">User name is required</div>}
                </div>
                <div className="settings18_pagebox1">
                    <h3>Password</h3>
                    <input
                        name="password"
                        value={password}
                        onChange={handleChange}
                        className="settings18_pageboxinput"
                        type="password"/>
                    {!saveEdit && !password && <div className="error">Password is required</div>}
                </div>
                
                <div className="settings18_pagebox1">
                    <h3>Roles</h3>
                    {this.renderRoleList()}
                    {!saveEdit && !selectedRole && <div className="error">Role is required</div>}
                </div>
                <div className="settings18_pagebox1">
                    <a href="#" className="button_blue" onClick={handleAdd}><span>save</span></a>
                </div>
            </div>
        </div>)
    }

    render() {
        const {tabState, selected} = this.state, {handleTab} = this;

        return (<div>

            <Header section="MANAGE USERS"/>

            <section>

                <div className="left_sidebar">
                    <div className="container-fluid p-0">
                        <div className="row m-0 pwa15_manageusers_row">

                            <div className="col-md-9 p-0">

                                <div className="manage15_pagecontent">
                                    <div className="manage15_pagetitel">
                                        <h3>Users</h3>
                                        <div className="manage15_pagesearch">
                                            <input value={this.state.search} onChange={this.handleSearchChange} className="manage15_inputsearch" placeholder="SEARCH"
                                                   type="text"/>
                                            <input className="manage15_buttonsearch" type="search"/>
                                        </div>
                                    </div>
                                    <div className="manage15_pagebuttonright">
                                        <a onClick={(e) => handleTab(e, 3)} href="#"
                                           className="button_blueborder"><span>New User</span></a>
                                        <a href="#" className="button_blueborder"><span>import users</span></a>
                                    </div>
                                    <div className="manage15_pagetable">
                                        <table>
                                            <thead>
                                            <tr>
                                                <th>name</th>
                                                <th>Last login</th>
                                                <th>Role</th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.renderList()}
                                            </tbody>
                                        </table>
                                    </div>


                                </div>

                            </div>
                            <div className="col-md-3 p-0">
                                <div className="rightsidebar_manageusers">
                                    <div className="sidebar_manageusers_tabs">


                                        <div id="tabs"
                                             className="ui-tabs ui-corner-all ui-widget ui-widget-content">
                                            <ul className="tabs">

                                                {selected.id && tabState != 3 ? (<>
                                                    <li className={`ui-tabs-tab ui-corner-top ui-state-default ui-tab ${tabState === 1 ? "ui-tabs-active ui-state-active" : ""}`}>
                                                        <a href="#tabs-1" onClick={(e) => handleTab(e,1)}>Details</a>
                                                    </li>
                                                    <li className={`ui-tabs-tab ui-corner-top ui-state-default ui-tab ${tabState === 2 ? "ui-tabs-active ui-state-active" : ""}`}>
                                                        <a href="#tabs-2" onClick={(e) => handleTab(e,2)}>Stats</a></li>
                                                </>) : null}
                                                {tabState === 3 ? (
                                                    <li className="ui-tabs-tab ui-corner-top ui-state-default ui-tab ui-tabs-active ui-state-active">
                                                        <a href="#tabs-1" onClick={(e) => handleTab(e,3)}>New User</a>
                                                    </li>) : null}
                                            </ul>
                                            {this.renderDetails()}
                                            {this.renderStats()}
                                            {this.renderAdd()}
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {this.DeleteModal()}

            <footer></footer>


        </div>);
    }
}

export default Settings_Users;
