import React, { Component, useState } from "react";
import {Link} from "react-router-dom";

import Debug_Header from "./debug_header";
import filesAPI from '../../utility/files';
const filesClient = new filesAPI();
const crypto = require('crypto');



class Sessions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      scenarios: [],
      sessions: []
    };

  }
  
  async endSession(session) {
    let sessionDelete = await filesClient.deleteSession(session.id);

    this.updateScenarioList();
    this.updateSessionList();
  }

  async deleteReplay(session) {
    let sessionDelete = await filesClient.deleteReplaySession(session.id);

    this.updateScenarioList();
    this.updateSessionList();
  }

  

  async restartSession(session) {
    let sessionReset = await filesClient.resetSession(session.id);
  }

  async startSession(scenario) {
    if (scenario && scenario.title) {
        let data = {};
        data.name = scenario.title + " (" + this.simplehash(6) + ")";
        //data.name = scenario.title;
        data.scenarioId = scenario.id;
        let sessionStart = await filesClient.createSession(data);

        if (sessionStart && sessionStart.isSuccess === false) {
          // display error

        } else if (sessionStart.isSuccess === true) {

        }
    }

    this.updateScenarioList();
    this.updateSessionList();
  }

  async updateScenarioList() {
    let scenariosList = await filesClient.getScenariosList();

    if (scenariosList && scenariosList.isSuccess == true && scenariosList.scenarios) {
      this.setState({scenarios: scenariosList.scenarios})
    } else {
      // display error connectiong to files API
    }
  }

  async updateSessionList() {
    let sessionList = await filesClient.getSessionsList();
    
    console.log("sessionList");
    console.log(sessionList);

    if (sessionList && sessionList.isSuccess == true && sessionList.sessions) {
      this.setState({sessions: sessionList.sessions})
    } else {
      // display error connectiong to files API
    }
  }

  async replaySession(sessionId) {
    let sessionReplay = await filesClient.replaySession(sessionId);
    
    console.log(sessionReplay);

    if (sessionReplay.isSuccess === true && sessionReplay.session) {
      // ??
      
    } else if (sessionReplay.isSuccess === false) {
      // error
    }

    this.updateSessionList();
  }

  simplehash(len) {
    return crypto.randomBytes(len).toString('base64').slice(0, len);
  }

  async componentDidMount() {
    this.updateScenarioList();
    this.updateSessionList();
    
  }

  render() {

  return (
    <div class="debugScroll">
          <Debug_Header />

          <div>
            <div><h3>Sessions</h3></div>
            <div>
                <ul>
                {this.state.sessions.filter(sessionCheck => sessionCheck.replayable === false && sessionCheck.matchId).map(session => (
                    <li>{session.name} - {session.matchId} <button onClick={() => this.endSession(session)}>End</button> - <button onClick={() => this.restartSession(session)}>Restart</button> - <Link to={'/spectate/view/' + session.id}><button>Join Spectate</button></Link> - <Link to={'/spectate/view/mobile/' + session.id}><button>Join WebXR</button></Link>  - <Link to={'/session/run/' + session.id}><button>Trainer</button></Link></li>
                ))}
                </ul>
            </div>
            <div><h3>Replay Sessions</h3></div>
            <div>
                <ul>
                {this.state.sessions.filter(sessionCheck => sessionCheck.replayable === true && sessionCheck.matchId).map(session => (
                    <li>{session.name} - {session.matchId} <button onClick={() => this.endSession(session)}>End</button> - <button onClick={() => this.restartSession(session)}>Restart</button> - <Link to={'/session/review/' + session.id}><button>Join Review</button></Link></li>
                ))}
                </ul>
            </div>
            <div><h3>Scenarios</h3></div>
            <div>
                <ul>
                {this.state.scenarios.map(scenario => (
                    <li>{scenario.title} <button onClick={() => this.startSession(scenario)}>Start</button> - <Link to={'/builder/' + scenario.id}><button>Load</button></Link></li>
                ))}
                </ul>
            </div>
            <div><h3>Past Sessions</h3></div>
            <div>
                <ul>
                {this.state.sessions.filter(sessionCheck => sessionCheck.replayable === true && sessionCheck.matchId === null).map(session => (
                    <li>{session.name}<button onClick={() => this.replaySession(session.id)}>Replay</button> - <button onClick={() => this.deleteReplay(session)}>Delete</button></li>
                ))}
                </ul>
            </div>
        </div>

      </div>
      
  );
}

}

export default Sessions;