import React, { Component, useState } from "react";
import Debug_Header from "./debug_header";

class AssetUpload extends Component {

  constructor(props) {
    super(props);
    this.state = {
      match_id: "",
      match_data: ""
    };

  }

  async componentDidMount() {
    
    
  }

  render() {

  return (
    <div>
      <Debug_Header />

      <div>
        ...

      </div>

    </div>
      
  );
}

}

export default AssetUpload;