import {Client} from "@heroiclabs/nakama-js";
import ConsoleEvents from "./ConsoleEvents";

// Debug
let consoleClient = new ConsoleEvents();
let socket;

export default class nakamaConnection {
    
    constructor(matchId) {
        try {
            // Nakama JS

            this.client = new Client(process.env.REACT_APP_NAKAMA_KEY, process.env.REACT_APP_NAKAMA_URL, process.env.REACT_APP_NAKAMA_PORT, true);
            this.email = process.env.REACT_APP_NAKAMA_EMAIL;
            this.password = process.env.REACT_APP_NAKAMA_PWD;

            this.lastMatchData = "";

            this.assetData = "";

            this.matchId = matchId;
        } catch (error) {
            consoleClient.sendLogMsg("error nakama connection");
            consoleClient.errorMsg(error);
        }
    }

    async connect(callback) {
        try {
            const nakamaSession = await this.client.authenticateEmail(this.email, this.password);
    
            const useSSL = true;
            const verboseLogging = false;
            
            // Send presence events to subscribed users.
            const createStatus = false;
            socket = this.client.createSocket(useSSL, verboseLogging);
            
            var socketSession = "";
            socketSession = await socket.connect(nakamaSession, createStatus);
            
            socket.onmatchdata = (matchdata) => {
                //consoleClient.sendInfo("Received match data: %o", matchdata);
                if (matchdata.data) {
                    callback(matchdata.data);
                }
            };

            // socket listeners
            
            socket.ondisconnect = (event) => {
                consoleClient.sendInfo("Disconnected from the server. Event:", event);
            };
            socket.onnotification = (notification) => {
                consoleClient.sendInfo("Received notification:", notification);
            };
            socket.onchannelpresence = (presence) => {
                consoleClient.sendInfo("Received presence update:", presence);
            };
            socket.onchannelmessage = (message) => {
                consoleClient.sendInfo("Received new chat message:", message);
            };
            
            socket.onmatchpresence = (matchpresence) => {
                consoleClient.sendInfo("Received match presence update:", matchpresence);
            };
            socket.onmatchmakermatched = (matchmakerMatched) => {
                consoleClient.sendInfo("Received matchmaker update:", matchmakerMatched);
            };
            socket.onstatuspresence = (statusPresence) => {
                consoleClient.sendInfo("Received status presence update:", statusPresence);
            };
            socket.onstreampresence = (streamPresence) => {
                consoleClient.sendInfo("Received stream presence update:", streamPresence);
            };
            socket.onstreamdata = (streamdata) => {
                consoleClient.sendInfo("Received stream data:", streamdata);
            };

            /*
            socket.onerror = (errorData) => {
                consoleClient.sendInfo("Received error data:", errorData);
            };
            */
    
            // end socket listeners
    
            let match = await socket.joinMatch(this.matchId);
            
        } catch (error) {
            consoleClient.sendLogMsg("error");
            consoleClient.errorMsg(error);
        }
    }

    async sendData(matchId, optCode, dataObj) {       
        await socket.sendMatchState(matchId, optCode, dataObj);
    }


    updateAsset(data) {
        this.assetData = data;
    }

    getAssetData() {
        return this.assetData;
    }

    closeConnection() {
        console.log("close now");
        socket.disconnect();
    }
    

};
